import { Locale as CanadaLocalePb } from "@repo/protobuf/gen/kikoff_canada/protobuf/types/locale_pb";
import Locale from "@repo/utils/Locale";
import UObject from "@repo/utils/UObject";

export namespace LocaleCanada {
  const protoByNormalized = {
    en: CanadaLocalePb.en_CA,
    fr: CanadaLocalePb.fr_CA,
  } as Record<Locale, CanadaLocalePb>;
  const normalizedByProto = UObject.inverse(protoByNormalized);

  export const normalize = (proto: CanadaLocalePb) => normalizedByProto[proto];
  export const denormalize = (locale: Locale) => protoByNormalized[locale];
}
