// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/funds.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Error } from "./error_pb.js";
import { PaymentMethod } from "./payment_methods_pb.js";

/**
 * @generated from enum web.public.FundingType
 */
export enum FundingType {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: WALLET = 1;
   */
  WALLET = 1,

  /**
   * @generated from enum value: LOAN = 2;
   */
  LOAN = 2,

  /**
   * @generated from enum value: AMAZON_GIFT_CARD = 3;
   */
  AMAZON_GIFT_CARD = 3,

  /**
   * @generated from enum value: CREDIT_LINE = 4;
   */
  CREDIT_LINE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(FundingType)
proto3.util.setEnumType(FundingType, "web.public.FundingType", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "WALLET" },
  { no: 2, name: "LOAN" },
  { no: 3, name: "AMAZON_GIFT_CARD" },
  { no: 4, name: "CREDIT_LINE" },
]);

/**
 * @generated from message web.public.WithdrawFundsRequest
 */
export class WithdrawFundsRequest extends Message<WithdrawFundsRequest> {
  /**
   * @generated from field: web.public.FundingType destination_type = 1;
   */
  destinationType = FundingType.UNKNOWN;

  /**
   * @generated from field: sint32 amount_cents = 2;
   */
  amountCents = 0;

  /**
   * @generated from field: string stripe_payment_method_id = 3;
   */
  stripePaymentMethodId = "";

  constructor(data?: PartialMessage<WithdrawFundsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.WithdrawFundsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination_type", kind: "enum", T: proto3.getEnumType(FundingType) },
    { no: 2, name: "amount_cents", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
    { no: 3, name: "stripe_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WithdrawFundsRequest {
    return new WithdrawFundsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WithdrawFundsRequest {
    return new WithdrawFundsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WithdrawFundsRequest {
    return new WithdrawFundsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WithdrawFundsRequest | PlainMessage<WithdrawFundsRequest> | undefined, b: WithdrawFundsRequest | PlainMessage<WithdrawFundsRequest> | undefined): boolean {
    return proto3.util.equals(WithdrawFundsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.WithdrawFundsResponse
 */
export class WithdrawFundsResponse extends Message<WithdrawFundsResponse> {
  /**
   * @generated from field: web.public.WithdrawFundsResponse.Status status = 1;
   */
  status = WithdrawFundsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string body = 4;
   */
  body = "";

  /**
   * @generated from field: string bottom_text = 5;
   */
  bottomText = "";

  /**
   * @generated from field: web.public.FundingType funding_type = 6;
   */
  fundingType = FundingType.UNKNOWN;

  /**
   * @generated from oneof web.public.WithdrawFundsResponse.withdraw_details
   */
  withdrawDetails: {
    /**
     * @generated from field: web.public.AmazonGiftCardDetails amazon_gift_card = 7;
     */
    value: AmazonGiftCardDetails;
    case: "amazonGiftCard";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<WithdrawFundsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.WithdrawFundsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(WithdrawFundsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "bottom_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "funding_type", kind: "enum", T: proto3.getEnumType(FundingType) },
    { no: 7, name: "amazon_gift_card", kind: "message", T: AmazonGiftCardDetails, oneof: "withdraw_details" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WithdrawFundsResponse {
    return new WithdrawFundsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WithdrawFundsResponse {
    return new WithdrawFundsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WithdrawFundsResponse {
    return new WithdrawFundsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WithdrawFundsResponse | PlainMessage<WithdrawFundsResponse> | undefined, b: WithdrawFundsResponse | PlainMessage<WithdrawFundsResponse> | undefined): boolean {
    return proto3.util.equals(WithdrawFundsResponse, a, b);
  }

  static _enums: {
    Status: typeof WithdrawFundsResponse.Status,
  };
}

export namespace WithdrawFundsResponse {
  /**
   * @generated from enum web.public.WithdrawFundsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILURE = 2;
     */
    FAILURE = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(WithdrawFundsResponse.Status)
  proto3.util.setEnumType(WithdrawFundsResponse.Status, "web.public.WithdrawFundsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILURE" },
  ]);
}

/**
 * @generated from message web.public.Wallet
 */
export class Wallet extends Message<Wallet> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: web.public.FundingType type = 2;
   */
  type = FundingType.UNKNOWN;

  /**
   * @generated from field: sint32 balance_cents = 3;
   */
  balanceCents = 0;

  /**
   * @generated from field: web.public.ReceiveFundsInfo receive_funds_info = 4;
   */
  receiveFundsInfo?: ReceiveFundsInfo;

  /**
   * @generated from field: bool upfront_payment = 5;
   */
  upfrontPayment = false;

  constructor(data?: PartialMessage<Wallet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Wallet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(FundingType) },
    { no: 3, name: "balance_cents", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
    { no: 4, name: "receive_funds_info", kind: "message", T: ReceiveFundsInfo },
    { no: 5, name: "upfront_payment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Wallet {
    return new Wallet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Wallet {
    return new Wallet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Wallet {
    return new Wallet().fromJsonString(jsonString, options);
  }

  static equals(a: Wallet | PlainMessage<Wallet> | undefined, b: Wallet | PlainMessage<Wallet> | undefined): boolean {
    return proto3.util.equals(Wallet, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ReceiveFundsInfo
 */
export class ReceiveFundsInfo extends Message<ReceiveFundsInfo> {
  /**
   * @generated from field: repeated web.public.WithdrawOption withdraw_options = 1;
   */
  withdrawOptions: WithdrawOption[] = [];

  /**
   * @generated from field: web.public.PaymentMethodInfo payment_method_info = 2;
   */
  paymentMethodInfo?: PaymentMethodInfo;

  constructor(data?: PartialMessage<ReceiveFundsInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ReceiveFundsInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "withdraw_options", kind: "message", T: WithdrawOption, repeated: true },
    { no: 2, name: "payment_method_info", kind: "message", T: PaymentMethodInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReceiveFundsInfo {
    return new ReceiveFundsInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReceiveFundsInfo {
    return new ReceiveFundsInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReceiveFundsInfo {
    return new ReceiveFundsInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ReceiveFundsInfo | PlainMessage<ReceiveFundsInfo> | undefined, b: ReceiveFundsInfo | PlainMessage<ReceiveFundsInfo> | undefined): boolean {
    return proto3.util.equals(ReceiveFundsInfo, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PaymentMethodInfo
 */
export class PaymentMethodInfo extends Message<PaymentMethodInfo> {
  /**
   * @generated from field: bool force_add_payment_method = 1;
   */
  forceAddPaymentMethod = false;

  /**
   * @generated from field: repeated web.public.PaymentMethod payment_methods = 2;
   */
  paymentMethods: PaymentMethod[] = [];

  constructor(data?: PartialMessage<PaymentMethodInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PaymentMethodInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "force_add_payment_method", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "payment_methods", kind: "message", T: PaymentMethod, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodInfo {
    return new PaymentMethodInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodInfo {
    return new PaymentMethodInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodInfo {
    return new PaymentMethodInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodInfo | PlainMessage<PaymentMethodInfo> | undefined, b: PaymentMethodInfo | PlainMessage<PaymentMethodInfo> | undefined): boolean {
    return proto3.util.equals(PaymentMethodInfo, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.WithdrawOption
 */
export class WithdrawOption extends Message<WithdrawOption> {
  /**
   * @generated from field: web.public.FundingType destination = 1;
   */
  destination = FundingType.UNKNOWN;

  /**
   * @generated from field: string details = 2;
   */
  details = "";

  /**
   * @generated from field: string agreement_text = 3;
   */
  agreementText = "";

  /**
   * @generated from oneof web.public.WithdrawOption.withdraw_details
   */
  withdrawDetails: {
    /**
     * @generated from field: web.public.AmazonGiftCardDetails amazon_gift_card = 4;
     */
    value: AmazonGiftCardDetails;
    case: "amazonGiftCard";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string short_details = 5;
   */
  shortDetails = "";

  constructor(data?: PartialMessage<WithdrawOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.WithdrawOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination", kind: "enum", T: proto3.getEnumType(FundingType) },
    { no: 2, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "agreement_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amazon_gift_card", kind: "message", T: AmazonGiftCardDetails, oneof: "withdraw_details" },
    { no: 5, name: "short_details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WithdrawOption {
    return new WithdrawOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WithdrawOption {
    return new WithdrawOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WithdrawOption {
    return new WithdrawOption().fromJsonString(jsonString, options);
  }

  static equals(a: WithdrawOption | PlainMessage<WithdrawOption> | undefined, b: WithdrawOption | PlainMessage<WithdrawOption> | undefined): boolean {
    return proto3.util.equals(WithdrawOption, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AmazonGiftCardDetails
 */
export class AmazonGiftCardDetails extends Message<AmazonGiftCardDetails> {
  /**
   * @generated from field: sint32 amount_cents = 1;
   */
  amountCents = 0;

  /**
   * @generated from field: string claim_code = 2;
   */
  claimCode = "";

  constructor(data?: PartialMessage<AmazonGiftCardDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AmazonGiftCardDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount_cents", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
    { no: 2, name: "claim_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AmazonGiftCardDetails {
    return new AmazonGiftCardDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AmazonGiftCardDetails {
    return new AmazonGiftCardDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AmazonGiftCardDetails {
    return new AmazonGiftCardDetails().fromJsonString(jsonString, options);
  }

  static equals(a: AmazonGiftCardDetails | PlainMessage<AmazonGiftCardDetails> | undefined, b: AmazonGiftCardDetails | PlainMessage<AmazonGiftCardDetails> | undefined): boolean {
    return proto3.util.equals(AmazonGiftCardDetails, a, b);
  }

  static _enums: {
  };
}

