import { User as CanadaUserPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/user_pb";
import {
  PersonalInfo as UsaPersonalInfoPb,
  User as UsaUserPb,
} from "@repo/protobuf/gen/web/public/user_pb";
import { MessageFrom } from "@repo/protobuf/utils";
import Address from "@repo/utils/Address";
import Enum from "@repo/utils/Enum";
import FullName from "@repo/utils/FullName";
import Locale from "@repo/utils/Locale";
import UString from "@repo/utils/UString";

import { AddressUsa } from "./Address.js";
import { FullNameCanada, FullNameUsa } from "./FullName.js";
import { LocaleCanada } from "./Locale.js";

type User = {
  id: User.Id;
  status: User.Status;
  pii: User.Pii.Readable;
  active: boolean;
  locale: Locale;
};

namespace User {
  export type Id = string & {};
  export namespace Id {
    export const format = (id: Id) =>
      UString.splitAt(id, [6, 10, 14]).join("-");
  }
  export type Status = "ONBOARDING" | "ONBOARDED";
  export type Credentials = { email: string; password: string };
  export type Pii = {
    email: string;
    name: FullName;
    phone: string;
    address: Address;
    birthDate: string;
    nationalId?: string;
    emailConfirmed: boolean;
  };

  export namespace Pii {
    export type Readable = Omit<Pii, "birthDate" | "nationalId">;
  }
}
export default User;

export namespace UserUsa {
  export function normalize(user: MessageFrom<UsaUserPb>): User {
    const status = Enum.keyOf(UsaUserPb.Status, user.status);
    if (status !== "ONBOARDING" && status !== "ONBOARDED")
      throw new Error(`Unexpected ${status} status in transaction entity.`);

    return {
      id: user.token,
      status,
      pii: Pii.normalize(user.info!),
      // TODO: Need this from backend
      active: true,
      locale: "en",
    };
  }
  export namespace Pii {
    export const normalize = (
      info: MessageFrom<UsaPersonalInfoPb>,
    ): User.Pii.Readable => ({
      email: info.email,
      name: FullNameUsa.normalize(info),
      phone: info.phone,
      address: AddressUsa.normalize(info),
      emailConfirmed: info.emailConfirmed,
    });
  }
}

export namespace UserCanada {
  export function normalize(user: MessageFrom<CanadaUserPb>): User {
    const status = Enum.keyOf(
      CanadaUserPb.OnboardingStatus,
      user.onboardingStatus,
    );
    if (status === "UNKNOWN")
      throw new Error(
        "Unexpected UNKNOWN onboarding status in transaction entity.",
      );

    return {
      id: user.id,
      status,
      pii: Pii.normalize(user.pii!),
      active: user.active,
      locale: LocaleCanada.normalize(user.locale),
    };
  }
  export namespace Pii {
    export const normalize = (
      pii: MessageFrom<CanadaUserPb.Pii>,
    ): User.Pii.Readable => ({
      email: pii.email,
      name: FullNameCanada.normalize(pii.name!),
      phone: pii.phone,
      address: pii.address!,
      emailConfirmed: pii.emailConfirmed!,
    });
  }
}
