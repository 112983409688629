// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/auto_pay.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message web.public.AutoPay
 */
export class AutoPay extends Message<AutoPay> {
  /**
   * @generated from field: web.public.AutoPay.Settings settings = 1;
   */
  settings?: AutoPay.Settings;

  /**
   * @generated from field: web.public.AutoPay.Attempt last_attempt = 2;
   */
  lastAttempt?: AutoPay.Attempt;

  /**
   * @generated from field: web.public.AutoPay.Attempt next_attempt = 3;
   */
  nextAttempt?: AutoPay.Attempt;

  /**
   * @generated from field: string autopay_information = 4;
   */
  autopayInformation = "";

  /**
   * @generated from field: bool display_failed_message = 5;
   */
  displayFailedMessage = false;

  /**
   * @generated from field: string autopay_error_message = 6;
   */
  autopayErrorMessage = "";

  constructor(data?: PartialMessage<AutoPay>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AutoPay";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: AutoPay.Settings },
    { no: 2, name: "last_attempt", kind: "message", T: AutoPay.Attempt },
    { no: 3, name: "next_attempt", kind: "message", T: AutoPay.Attempt },
    { no: 4, name: "autopay_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "display_failed_message", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "autopay_error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoPay {
    return new AutoPay().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoPay {
    return new AutoPay().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoPay {
    return new AutoPay().fromJsonString(jsonString, options);
  }

  static equals(a: AutoPay | PlainMessage<AutoPay> | undefined, b: AutoPay | PlainMessage<AutoPay> | undefined): boolean {
    return proto3.util.equals(AutoPay, a, b);
  }

  static _enums: {
  };
}

export namespace AutoPay {
  /**
   * @generated from message web.public.AutoPay.Settings
   */
  export class Settings extends Message<AutoPay.Settings> {
    /**
     * @generated from field: web.public.AutoPay.Settings.State state = 1;
     */
    state = AutoPay.Settings.State.UNKNOWN;

    /**
     * @generated from field: string payment_method_token = 2;
     */
    paymentMethodToken = "";

    /**
     * @generated from field: uint32 day_of_month = 3;
     */
    dayOfMonth = 0;

    constructor(data?: PartialMessage<AutoPay.Settings>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.AutoPay.Settings";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(AutoPay.Settings.State) },
      { no: 2, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "day_of_month", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoPay.Settings {
      return new AutoPay.Settings().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoPay.Settings {
      return new AutoPay.Settings().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoPay.Settings {
      return new AutoPay.Settings().fromJsonString(jsonString, options);
    }

    static equals(a: AutoPay.Settings | PlainMessage<AutoPay.Settings> | undefined, b: AutoPay.Settings | PlainMessage<AutoPay.Settings> | undefined): boolean {
      return proto3.util.equals(AutoPay.Settings, a, b);
    }

    static _enums: {
      State: typeof AutoPay.Settings.State,
    };
  }
}

export namespace AutoPay.Settings {
  /**
   * @generated from enum web.public.AutoPay.Settings.State
   */
  export enum State {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: ENABLED = 1;
     */
    ENABLED = 1,

    /**
     * @generated from enum value: DISABLED = 2;
     */
    DISABLED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(AutoPay.Settings.State)
  proto3.util.setEnumType(AutoPay.Settings.State, "web.public.AutoPay.Settings.State", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ENABLED" },
    { no: 2, name: "DISABLED" },
  ]);
}

export namespace AutoPay {
  /**
   * @generated from message web.public.AutoPay.Attempt
   */
  export class Attempt extends Message<AutoPay.Attempt> {
    /**
     * @generated from field: web.public.AutoPay.Attempt.Status status = 1;
     */
    status = AutoPay.Attempt.Status.UNKNOWN;

    /**
     * @generated from field: google.protobuf.Timestamp at = 2;
     */
    at?: Timestamp;

    constructor(data?: PartialMessage<AutoPay.Attempt>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.AutoPay.Attempt";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(AutoPay.Attempt.Status) },
      { no: 2, name: "at", kind: "message", T: Timestamp },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoPay.Attempt {
      return new AutoPay.Attempt().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoPay.Attempt {
      return new AutoPay.Attempt().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoPay.Attempt {
      return new AutoPay.Attempt().fromJsonString(jsonString, options);
    }

    static equals(a: AutoPay.Attempt | PlainMessage<AutoPay.Attempt> | undefined, b: AutoPay.Attempt | PlainMessage<AutoPay.Attempt> | undefined): boolean {
      return proto3.util.equals(AutoPay.Attempt, a, b);
    }

    static _enums: {
      Status: typeof AutoPay.Attempt.Status,
    };
  }
}

export namespace AutoPay.Attempt {
  /**
   * @generated from enum web.public.AutoPay.Attempt.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILURE = 2;
     */
    FAILURE = 2,

    /**
     * @generated from enum value: GENERIC_FAILURE = 3;
     */
    GENERIC_FAILURE = 3,

    /**
     * @generated from enum value: INVALID_FAILURE = 4;
     */
    INVALID_FAILURE = 4,

    /**
     * @generated from enum value: INSUFFICIENT_FUNDS = 5;
     */
    INSUFFICIENT_FUNDS = 5,

    /**
     * @generated from enum value: TRY_AGAIN = 6;
     */
    TRY_AGAIN = 6,

    /**
     * @generated from enum value: MULTIPLE_FAILURES = 7;
     */
    MULTIPLE_FAILURES = 7,
  }
  // Retrieve enum metadata with: proto3.getEnumType(AutoPay.Attempt.Status)
  proto3.util.setEnumType(AutoPay.Attempt.Status, "web.public.AutoPay.Attempt.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILURE" },
    { no: 3, name: "GENERIC_FAILURE" },
    { no: 4, name: "INVALID_FAILURE" },
    { no: 5, name: "INSUFFICIENT_FUNDS" },
    { no: 6, name: "TRY_AGAIN" },
    { no: 7, name: "MULTIPLE_FAILURES" },
  ]);
}

