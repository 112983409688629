import { batch } from "solid-js";

import PaymentMethod from "@repo/models-kikoff/payments/PaymentMethod";
import Transaction, {
  TransactionCanada,
} from "@repo/models-kikoff/Transaction";
import canadaAdminRpc from "@repo/protobuf/api/canadaAdminRpc";
import FeatureStore from "@repo/utils-solid/FeatureStore";

const initialState = {
  paymentMethodById: {} as Record<PaymentMethod.Id, PaymentMethod>,
};

export const { Provider, usePayments } = FeatureStore.init(
  "Payments",
  initialState,
  ([store, setStore], { withLogger }) => {
    const accessors = {
      paymentMethod: (id: PaymentMethod.Id) => store.paymentMethodById[id],
    };

    const mutations = {
      onePaymentMethod(paymentMethod: PaymentMethod) {
        setStore("paymentMethodById", paymentMethod.id, paymentMethod);
      },
      manyPaymentMethods(paymentMethods: PaymentMethod[]) {
        batch(() => {
          for (const paymentMethod of paymentMethods) {
            mutations.onePaymentMethod(paymentMethod);
          }
        });
      },
    };

    const usa = {
      actions: {} as never,
    };
    const canada = {
      actions: {
        manyPaymentMethods: mutations.manyPaymentMethods,
        refund(transactionId: Transaction.Id) {
          return canadaAdminRpc.Payments.refundTransaction({
            transactionId,
          }).then(({ refund }) => TransactionCanada.normalize(refund!));
        },
      },
    };

    const actions = withLogger(
      "action",
      import.meta.env.API_ADAPTER === "canada" ? canada.actions : usa.actions,
    );

    return { accessors, actions };
  },
);
