// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/payment_methods.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Error } from "./error_pb.js";
import { ExternalBankAccount } from "./external_bank_accounts_pb.js";

/**
 * @generated from enum web.public.PaymentType
 */
export enum PaymentType {
  /**
   * UNKNOWN is taken, enum values are unfortunately global
   *
   * @generated from enum value: UNKNOWN_PAYMENT_TYPE = 0;
   */
  UNKNOWN_PAYMENT_TYPE = 0,

  /**
   * @generated from enum value: ACH_BANK_ACCOUNT = 1;
   */
  ACH_BANK_ACCOUNT = 1,

  /**
   * @generated from enum value: CARD = 2;
   */
  CARD = 2,

  /**
   * @generated from enum value: CREDIT_LINE_PAYMENT = 3;
   */
  CREDIT_LINE_PAYMENT = 3,

  /**
   * @generated from enum value: WALLET_PAYMENT_TYPE = 4;
   */
  WALLET_PAYMENT_TYPE = 4,

  /**
   * @generated from enum value: CASHAPP_STRIPE = 5;
   */
  CASHAPP_STRIPE = 5,

  /**
   * @generated from enum value: APPLE_PAY = 6;
   */
  APPLE_PAY = 6,

  /**
   * @generated from enum value: GOOGLE_PAY = 7;
   */
  GOOGLE_PAY = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentType)
proto3.util.setEnumType(PaymentType, "web.public.PaymentType", [
  { no: 0, name: "UNKNOWN_PAYMENT_TYPE" },
  { no: 1, name: "ACH_BANK_ACCOUNT" },
  { no: 2, name: "CARD" },
  { no: 3, name: "CREDIT_LINE_PAYMENT" },
  { no: 4, name: "WALLET_PAYMENT_TYPE" },
  { no: 5, name: "CASHAPP_STRIPE" },
  { no: 6, name: "APPLE_PAY" },
  { no: 7, name: "GOOGLE_PAY" },
]);

/**
 * @generated from message web.public.PaymentOption
 */
export class PaymentOption extends Message<PaymentOption> {
  /**
   * @generated from field: web.public.PaymentType payment_type = 1;
   */
  paymentType = PaymentType.UNKNOWN_PAYMENT_TYPE;

  /**
   * @generated from field: int32 percent_fee = 2;
   */
  percentFee = 0;

  /**
   * @generated from field: int32 fixed_fee_cents = 3;
   */
  fixedFeeCents = 0;

  constructor(data?: PartialMessage<PaymentOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PaymentOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_type", kind: "enum", T: proto3.getEnumType(PaymentType) },
    { no: 2, name: "percent_fee", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "fixed_fee_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentOption {
    return new PaymentOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentOption {
    return new PaymentOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentOption {
    return new PaymentOption().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentOption | PlainMessage<PaymentOption> | undefined, b: PaymentOption | PlainMessage<PaymentOption> | undefined): boolean {
    return proto3.util.equals(PaymentOption, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CardPayment
 */
export class CardPayment extends Message<CardPayment> {
  /**
   * @generated from field: string client_secret = 1;
   */
  clientSecret = "";

  /**
   * @generated from field: string payment_intent_id = 2;
   */
  paymentIntentId = "";

  constructor(data?: PartialMessage<CardPayment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CardPayment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payment_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardPayment {
    return new CardPayment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardPayment {
    return new CardPayment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardPayment {
    return new CardPayment().fromJsonString(jsonString, options);
  }

  static equals(a: CardPayment | PlainMessage<CardPayment> | undefined, b: CardPayment | PlainMessage<CardPayment> | undefined): boolean {
    return proto3.util.equals(CardPayment, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AutoPayStatus
 */
export class AutoPayStatus extends Message<AutoPayStatus> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: bool failed = 2;
   */
  failed = false;

  /**
   * @generated from field: string payment_method_token = 3;
   */
  paymentMethodToken = "";

  constructor(data?: PartialMessage<AutoPayStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AutoPayStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "failed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoPayStatus {
    return new AutoPayStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoPayStatus {
    return new AutoPayStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoPayStatus {
    return new AutoPayStatus().fromJsonString(jsonString, options);
  }

  static equals(a: AutoPayStatus | PlainMessage<AutoPayStatus> | undefined, b: AutoPayStatus | PlainMessage<AutoPayStatus> | undefined): boolean {
    return proto3.util.equals(AutoPayStatus, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PreauthCardPaymentRequest
 */
export class PreauthCardPaymentRequest extends Message<PreauthCardPaymentRequest> {
  /**
   * @generated from field: string loan_token = 1;
   */
  loanToken = "";

  constructor(data?: PartialMessage<PreauthCardPaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PreauthCardPaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreauthCardPaymentRequest {
    return new PreauthCardPaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreauthCardPaymentRequest {
    return new PreauthCardPaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreauthCardPaymentRequest {
    return new PreauthCardPaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PreauthCardPaymentRequest | PlainMessage<PreauthCardPaymentRequest> | undefined, b: PreauthCardPaymentRequest | PlainMessage<PreauthCardPaymentRequest> | undefined): boolean {
    return proto3.util.equals(PreauthCardPaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PreauthCardPaymentResponse
 */
export class PreauthCardPaymentResponse extends Message<PreauthCardPaymentResponse> {
  /**
   * @generated from field: web.public.PreauthCardPaymentResponse.Status status = 1;
   */
  status = PreauthCardPaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.CardPayment card_payment = 3;
   */
  cardPayment?: CardPayment;

  constructor(data?: PartialMessage<PreauthCardPaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PreauthCardPaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(PreauthCardPaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "card_payment", kind: "message", T: CardPayment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreauthCardPaymentResponse {
    return new PreauthCardPaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreauthCardPaymentResponse {
    return new PreauthCardPaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreauthCardPaymentResponse {
    return new PreauthCardPaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PreauthCardPaymentResponse | PlainMessage<PreauthCardPaymentResponse> | undefined, b: PreauthCardPaymentResponse | PlainMessage<PreauthCardPaymentResponse> | undefined): boolean {
    return proto3.util.equals(PreauthCardPaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof PreauthCardPaymentResponse.Status,
  };
}

export namespace PreauthCardPaymentResponse {
  /**
   * @generated from enum web.public.PreauthCardPaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PreauthCardPaymentResponse.Status)
  proto3.util.setEnumType(PreauthCardPaymentResponse.Status, "web.public.PreauthCardPaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.TriggerPostPaymentHookRequest
 */
export class TriggerPostPaymentHookRequest extends Message<TriggerPostPaymentHookRequest> {
  /**
   * @generated from field: string payment_intent_id = 1;
   */
  paymentIntentId = "";

  constructor(data?: PartialMessage<TriggerPostPaymentHookRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TriggerPostPaymentHookRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerPostPaymentHookRequest {
    return new TriggerPostPaymentHookRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerPostPaymentHookRequest {
    return new TriggerPostPaymentHookRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerPostPaymentHookRequest {
    return new TriggerPostPaymentHookRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerPostPaymentHookRequest | PlainMessage<TriggerPostPaymentHookRequest> | undefined, b: TriggerPostPaymentHookRequest | PlainMessage<TriggerPostPaymentHookRequest> | undefined): boolean {
    return proto3.util.equals(TriggerPostPaymentHookRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.TriggerPostPaymentHookResponse
 */
export class TriggerPostPaymentHookResponse extends Message<TriggerPostPaymentHookResponse> {
  /**
   * @generated from field: web.public.TriggerPostPaymentHookResponse.Status status = 1;
   */
  status = TriggerPostPaymentHookResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<TriggerPostPaymentHookResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TriggerPostPaymentHookResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(TriggerPostPaymentHookResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerPostPaymentHookResponse {
    return new TriggerPostPaymentHookResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerPostPaymentHookResponse {
    return new TriggerPostPaymentHookResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerPostPaymentHookResponse {
    return new TriggerPostPaymentHookResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerPostPaymentHookResponse | PlainMessage<TriggerPostPaymentHookResponse> | undefined, b: TriggerPostPaymentHookResponse | PlainMessage<TriggerPostPaymentHookResponse> | undefined): boolean {
    return proto3.util.equals(TriggerPostPaymentHookResponse, a, b);
  }

  static _enums: {
    Status: typeof TriggerPostPaymentHookResponse.Status,
  };
}

export namespace TriggerPostPaymentHookResponse {
  /**
   * @generated from enum web.public.TriggerPostPaymentHookResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(TriggerPostPaymentHookResponse.Status)
  proto3.util.setEnumType(TriggerPostPaymentHookResponse.Status, "web.public.TriggerPostPaymentHookResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.SetupIntent
 */
export class SetupIntent extends Message<SetupIntent> {
  /**
   * @generated from field: string client_secret = 1;
   */
  clientSecret = "";

  /**
   * @generated from field: string setup_intent_id = 2;
   */
  setupIntentId = "";

  constructor(data?: PartialMessage<SetupIntent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.SetupIntent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "setup_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetupIntent {
    return new SetupIntent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetupIntent {
    return new SetupIntent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetupIntent {
    return new SetupIntent().fromJsonString(jsonString, options);
  }

  static equals(a: SetupIntent | PlainMessage<SetupIntent> | undefined, b: SetupIntent | PlainMessage<SetupIntent> | undefined): boolean {
    return proto3.util.equals(SetupIntent, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AddPaymentMethodRequest
 */
export class AddPaymentMethodRequest extends Message<AddPaymentMethodRequest> {
  /**
   * @generated from field: web.public.PaymentMethod.Scope scope = 2;
   */
  scope = PaymentMethod.Scope.ALL;

  /**
   * @generated from field: string return_url = 3;
   */
  returnUrl = "";

  constructor(data?: PartialMessage<AddPaymentMethodRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AddPaymentMethodRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "scope", kind: "enum", T: proto3.getEnumType(PaymentMethod.Scope) },
    { no: 3, name: "return_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPaymentMethodRequest {
    return new AddPaymentMethodRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPaymentMethodRequest {
    return new AddPaymentMethodRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPaymentMethodRequest {
    return new AddPaymentMethodRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddPaymentMethodRequest | PlainMessage<AddPaymentMethodRequest> | undefined, b: AddPaymentMethodRequest | PlainMessage<AddPaymentMethodRequest> | undefined): boolean {
    return proto3.util.equals(AddPaymentMethodRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PublishableKey
 */
export class PublishableKey extends Message<PublishableKey> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  constructor(data?: PartialMessage<PublishableKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PublishableKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PublishableKey {
    return new PublishableKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PublishableKey {
    return new PublishableKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PublishableKey {
    return new PublishableKey().fromJsonString(jsonString, options);
  }

  static equals(a: PublishableKey | PlainMessage<PublishableKey> | undefined, b: PublishableKey | PlainMessage<PublishableKey> | undefined): boolean {
    return proto3.util.equals(PublishableKey, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AddPaymentMethodResponse
 */
export class AddPaymentMethodResponse extends Message<AddPaymentMethodResponse> {
  /**
   * @generated from field: web.public.AddPaymentMethodResponse.Status status = 1;
   */
  status = AddPaymentMethodResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.SetupIntent setup_intent = 3;
   */
  setupIntent?: SetupIntent;

  /**
   * @generated from field: web.public.PublishableKey payment_publishable_key = 4;
   */
  paymentPublishableKey?: PublishableKey;

  /**
   * @generated from field: optional web.public.PublishableKey disbursement_publishable_key = 5;
   */
  disbursementPublishableKey?: PublishableKey;

  /**
   * @generated from field: string redirect_url = 6;
   */
  redirectUrl = "";

  constructor(data?: PartialMessage<AddPaymentMethodResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AddPaymentMethodResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(AddPaymentMethodResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "setup_intent", kind: "message", T: SetupIntent },
    { no: 4, name: "payment_publishable_key", kind: "message", T: PublishableKey },
    { no: 5, name: "disbursement_publishable_key", kind: "message", T: PublishableKey, opt: true },
    { no: 6, name: "redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPaymentMethodResponse {
    return new AddPaymentMethodResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPaymentMethodResponse {
    return new AddPaymentMethodResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPaymentMethodResponse {
    return new AddPaymentMethodResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddPaymentMethodResponse | PlainMessage<AddPaymentMethodResponse> | undefined, b: AddPaymentMethodResponse | PlainMessage<AddPaymentMethodResponse> | undefined): boolean {
    return proto3.util.equals(AddPaymentMethodResponse, a, b);
  }

  static _enums: {
    Status: typeof AddPaymentMethodResponse.Status,
  };
}

export namespace AddPaymentMethodResponse {
  /**
   * @generated from enum web.public.AddPaymentMethodResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(AddPaymentMethodResponse.Status)
  proto3.util.setEnumType(AddPaymentMethodResponse.Status, "web.public.AddPaymentMethodResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.StripeToken
 */
export class StripeToken extends Message<StripeToken> {
  /**
   * @generated from field: web.public.StripeToken.Usage usage = 1;
   */
  usage = StripeToken.Usage.UNKNOWN;

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  constructor(data?: PartialMessage<StripeToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.StripeToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "usage", kind: "enum", T: proto3.getEnumType(StripeToken.Usage) },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeToken {
    return new StripeToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeToken {
    return new StripeToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeToken {
    return new StripeToken().fromJsonString(jsonString, options);
  }

  static equals(a: StripeToken | PlainMessage<StripeToken> | undefined, b: StripeToken | PlainMessage<StripeToken> | undefined): boolean {
    return proto3.util.equals(StripeToken, a, b);
  }

  static _enums: {
    Usage: typeof StripeToken.Usage,
  };
}

export namespace StripeToken {
  /**
   * @generated from enum web.public.StripeToken.Usage
   */
  export enum Usage {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: CASH_ADVANCE = 1;
     */
    CASH_ADVANCE = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(StripeToken.Usage)
  proto3.util.setEnumType(StripeToken.Usage, "web.public.StripeToken.Usage", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "CASH_ADVANCE" },
  ]);
}

/**
 * @generated from message web.public.TriggerPostAddPaymentMethodHookRequest
 */
export class TriggerPostAddPaymentMethodHookRequest extends Message<TriggerPostAddPaymentMethodHookRequest> {
  /**
   * @generated from field: string setup_intent_id = 1;
   */
  setupIntentId = "";

  /**
   * @generated from field: optional web.public.PaymentMethod.Scope scope = 2;
   */
  scope?: PaymentMethod.Scope;

  /**
   * to be deprecated
   *
   * @generated from field: optional string card_token = 3;
   */
  cardToken?: string;

  /**
   * @generated from field: optional web.public.StripeToken stripe_token = 4;
   */
  stripeToken?: StripeToken;

  constructor(data?: PartialMessage<TriggerPostAddPaymentMethodHookRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TriggerPostAddPaymentMethodHookRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "setup_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scope", kind: "enum", T: proto3.getEnumType(PaymentMethod.Scope), opt: true },
    { no: 3, name: "card_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "stripe_token", kind: "message", T: StripeToken, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerPostAddPaymentMethodHookRequest {
    return new TriggerPostAddPaymentMethodHookRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerPostAddPaymentMethodHookRequest {
    return new TriggerPostAddPaymentMethodHookRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerPostAddPaymentMethodHookRequest {
    return new TriggerPostAddPaymentMethodHookRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerPostAddPaymentMethodHookRequest | PlainMessage<TriggerPostAddPaymentMethodHookRequest> | undefined, b: TriggerPostAddPaymentMethodHookRequest | PlainMessage<TriggerPostAddPaymentMethodHookRequest> | undefined): boolean {
    return proto3.util.equals(TriggerPostAddPaymentMethodHookRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.TriggerPostAddPaymentMethodHookResponse
 */
export class TriggerPostAddPaymentMethodHookResponse extends Message<TriggerPostAddPaymentMethodHookResponse> {
  /**
   * @generated from field: web.public.TriggerPostAddPaymentMethodHookResponse.Status status = 1;
   */
  status = TriggerPostAddPaymentMethodHookResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string payment_method_token = 3;
   */
  paymentMethodToken = "";

  constructor(data?: PartialMessage<TriggerPostAddPaymentMethodHookResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TriggerPostAddPaymentMethodHookResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(TriggerPostAddPaymentMethodHookResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerPostAddPaymentMethodHookResponse {
    return new TriggerPostAddPaymentMethodHookResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerPostAddPaymentMethodHookResponse {
    return new TriggerPostAddPaymentMethodHookResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerPostAddPaymentMethodHookResponse {
    return new TriggerPostAddPaymentMethodHookResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerPostAddPaymentMethodHookResponse | PlainMessage<TriggerPostAddPaymentMethodHookResponse> | undefined, b: TriggerPostAddPaymentMethodHookResponse | PlainMessage<TriggerPostAddPaymentMethodHookResponse> | undefined): boolean {
    return proto3.util.equals(TriggerPostAddPaymentMethodHookResponse, a, b);
  }

  static _enums: {
    Status: typeof TriggerPostAddPaymentMethodHookResponse.Status,
  };
}

export namespace TriggerPostAddPaymentMethodHookResponse {
  /**
   * @generated from enum web.public.TriggerPostAddPaymentMethodHookResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,

    /**
     * @generated from enum value: DUPLICATE_CARD = 3;
     */
    DUPLICATE_CARD = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(TriggerPostAddPaymentMethodHookResponse.Status)
  proto3.util.setEnumType(TriggerPostAddPaymentMethodHookResponse.Status, "web.public.TriggerPostAddPaymentMethodHookResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
    { no: 3, name: "DUPLICATE_CARD" },
  ]);
}

/**
 * @generated from message web.public.StripeDetails
 */
export class StripeDetails extends Message<StripeDetails> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string stripe_payment_method_id = 4;
   */
  stripePaymentMethodId = "";

  /**
   * @generated from field: web.public.StripeDetails.FundingType funding_type = 5;
   */
  fundingType = StripeDetails.FundingType.UNKNOWN;

  /**
   * @generated from field: string zip_code = 6;
   */
  zipCode = "";

  constructor(data?: PartialMessage<StripeDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.StripeDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stripe_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "funding_type", kind: "enum", T: proto3.getEnumType(StripeDetails.FundingType) },
    { no: 6, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeDetails {
    return new StripeDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeDetails {
    return new StripeDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeDetails {
    return new StripeDetails().fromJsonString(jsonString, options);
  }

  static equals(a: StripeDetails | PlainMessage<StripeDetails> | undefined, b: StripeDetails | PlainMessage<StripeDetails> | undefined): boolean {
    return proto3.util.equals(StripeDetails, a, b);
  }

  static _enums: {
    FundingType: typeof StripeDetails.FundingType,
  };
}

export namespace StripeDetails {
  /**
   * @generated from enum web.public.StripeDetails.FundingType
   */
  export enum FundingType {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: DEBIT = 1;
     */
    DEBIT = 1,

    /**
     * @generated from enum value: CREDIT = 2;
     */
    CREDIT = 2,

    /**
     * @generated from enum value: PREPAID = 3;
     */
    PREPAID = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(StripeDetails.FundingType)
  proto3.util.setEnumType(StripeDetails.FundingType, "web.public.StripeDetails.FundingType", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "DEBIT" },
    { no: 2, name: "CREDIT" },
    { no: 3, name: "PREPAID" },
  ]);
}

/**
 * @generated from message web.public.CreditLineAccountDetails
 */
export class CreditLineAccountDetails extends Message<CreditLineAccountDetails> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: uint32 credit_limit_cents = 3;
   */
  creditLimitCents = 0;

  /**
   * @generated from field: int32 balance_cents = 4;
   */
  balanceCents = 0;

  /**
   * @generated from field: int32 available_balance_cents = 5;
   */
  availableBalanceCents = 0;

  constructor(data?: PartialMessage<CreditLineAccountDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditLineAccountDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "available_balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAccountDetails {
    return new CreditLineAccountDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAccountDetails {
    return new CreditLineAccountDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAccountDetails {
    return new CreditLineAccountDetails().fromJsonString(jsonString, options);
  }

  static equals(a: CreditLineAccountDetails | PlainMessage<CreditLineAccountDetails> | undefined, b: CreditLineAccountDetails | PlainMessage<CreditLineAccountDetails> | undefined): boolean {
    return proto3.util.equals(CreditLineAccountDetails, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PaymentMethod
 */
export class PaymentMethod extends Message<PaymentMethod> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string payment_type = 2;
   */
  paymentType = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string stripe_payment_method_id = 4;
   */
  stripePaymentMethodId = "";

  /**
   * to be deprecated
   *
   * @generated from field: repeated web.public.PaymentMethod.Usage uses = 5;
   */
  uses: PaymentMethod.Usage[] = [];

  /**
   * @generated from field: repeated web.public.PaymentMethod.Affiliation affiliations = 6;
   */
  affiliations: PaymentMethod.Affiliation[] = [];

  /**
   * @generated from field: web.public.PaymentType type = 7;
   */
  type = PaymentType.UNKNOWN_PAYMENT_TYPE;

  /**
   * @generated from oneof web.public.PaymentMethod.payment_details
   */
  paymentDetails: {
    /**
     * @generated from field: web.public.ExternalBankAccount external_bank_account = 8;
     */
    value: ExternalBankAccount;
    case: "externalBankAccount";
  } | {
    /**
     * @generated from field: web.public.StripeDetails stripe_details = 9;
     */
    value: StripeDetails;
    case: "stripeDetails";
  } | {
    /**
     * @generated from field: web.public.CreditLineAccountDetails credit_line = 11;
     */
    value: CreditLineAccountDetails;
    case: "creditLine";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string status = 10;
   */
  status = "";

  constructor(data?: PartialMessage<PaymentMethod>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PaymentMethod";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payment_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stripe_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "uses", kind: "enum", T: proto3.getEnumType(PaymentMethod.Usage), repeated: true },
    { no: 6, name: "affiliations", kind: "message", T: PaymentMethod.Affiliation, repeated: true },
    { no: 7, name: "type", kind: "enum", T: proto3.getEnumType(PaymentType) },
    { no: 8, name: "external_bank_account", kind: "message", T: ExternalBankAccount, oneof: "payment_details" },
    { no: 9, name: "stripe_details", kind: "message", T: StripeDetails, oneof: "payment_details" },
    { no: 11, name: "credit_line", kind: "message", T: CreditLineAccountDetails, oneof: "payment_details" },
    { no: 10, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethod {
    return new PaymentMethod().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethod {
    return new PaymentMethod().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethod {
    return new PaymentMethod().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethod | PlainMessage<PaymentMethod> | undefined, b: PaymentMethod | PlainMessage<PaymentMethod> | undefined): boolean {
    return proto3.util.equals(PaymentMethod, a, b);
  }

  static _enums: {
    Scope: typeof PaymentMethod.Scope,
    Usage: typeof PaymentMethod.Usage,
  };
}

export namespace PaymentMethod {
  /**
   * @generated from enum web.public.PaymentMethod.Scope
   */
  export enum Scope {
    /**
     * @generated from enum value: ALL = 0;
     */
    ALL = 0,

    /**
     * @generated from enum value: INSTANT_TRANSFER = 1;
     */
    INSTANT_TRANSFER = 1,

    /**
     * @generated from enum value: DISBURSEMENT = 2;
     */
    DISBURSEMENT = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PaymentMethod.Scope)
  proto3.util.setEnumType(PaymentMethod.Scope, "web.public.PaymentMethod.Scope", [
    { no: 0, name: "ALL" },
    { no: 1, name: "INSTANT_TRANSFER" },
    { no: 2, name: "DISBURSEMENT" },
  ]);
}

export namespace PaymentMethod {
  /**
   * @generated from enum web.public.PaymentMethod.Usage
   */
  export enum Usage {
    /**
     * @generated from enum value: DEFAULT = 0;
     */
    DEFAULT = 0,

    /**
     * @generated from enum value: AUTOPAY = 1;
     */
    AUTOPAY = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PaymentMethod.Usage)
  proto3.util.setEnumType(PaymentMethod.Usage, "web.public.PaymentMethod.Usage", [
    { no: 0, name: "DEFAULT" },
    { no: 1, name: "AUTOPAY" },
  ]);
}

export namespace PaymentMethod {
  /**
   * @generated from message web.public.PaymentMethod.Affiliation
   */
  export class Affiliation extends Message<PaymentMethod.Affiliation> {
    /**
     * @generated from field: web.public.PaymentMethod.Affiliation.Type type = 1;
     */
    type = PaymentMethod.Affiliation.Type.CREDIT_LINE;

    /**
     * @generated from field: string token = 2;
     */
    token = "";

    /**
     * @generated from field: web.public.PaymentMethod.Usage usage = 3;
     */
    usage = PaymentMethod.Usage.DEFAULT;

    constructor(data?: PartialMessage<PaymentMethod.Affiliation>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.PaymentMethod.Affiliation";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(PaymentMethod.Affiliation.Type) },
      { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "usage", kind: "enum", T: proto3.getEnumType(PaymentMethod.Usage) },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethod.Affiliation {
      return new PaymentMethod.Affiliation().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethod.Affiliation {
      return new PaymentMethod.Affiliation().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethod.Affiliation {
      return new PaymentMethod.Affiliation().fromJsonString(jsonString, options);
    }

    static equals(a: PaymentMethod.Affiliation | PlainMessage<PaymentMethod.Affiliation> | undefined, b: PaymentMethod.Affiliation | PlainMessage<PaymentMethod.Affiliation> | undefined): boolean {
      return proto3.util.equals(PaymentMethod.Affiliation, a, b);
    }

    static _enums: {
      Type: typeof PaymentMethod.Affiliation.Type,
    };
  }
}

export namespace PaymentMethod.Affiliation {
  /**
   * @generated from enum web.public.PaymentMethod.Affiliation.Type
   */
  export enum Type {
    /**
     * @generated from enum value: CREDIT_LINE = 0;
     */
    CREDIT_LINE = 0,

    /**
     * @generated from enum value: LOAN = 1;
     */
    LOAN = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PaymentMethod.Affiliation.Type)
  proto3.util.setEnumType(PaymentMethod.Affiliation.Type, "web.public.PaymentMethod.Affiliation.Type", [
    { no: 0, name: "CREDIT_LINE" },
    { no: 1, name: "LOAN" },
  ]);
}

/**
 * @generated from message web.public.GetPaymentMethodsRequest
 */
export class GetPaymentMethodsRequest extends Message<GetPaymentMethodsRequest> {
  /**
   * @generated from field: repeated web.public.PaymentType payment_types = 1;
   */
  paymentTypes: PaymentType[] = [];

  /**
   * @generated from field: optional web.public.PaymentMethod.Scope scope = 2;
   */
  scope?: PaymentMethod.Scope;

  constructor(data?: PartialMessage<GetPaymentMethodsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPaymentMethodsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_types", kind: "enum", T: proto3.getEnumType(PaymentType), repeated: true },
    { no: 2, name: "scope", kind: "enum", T: proto3.getEnumType(PaymentMethod.Scope), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPaymentMethodsRequest {
    return new GetPaymentMethodsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPaymentMethodsRequest {
    return new GetPaymentMethodsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPaymentMethodsRequest {
    return new GetPaymentMethodsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPaymentMethodsRequest | PlainMessage<GetPaymentMethodsRequest> | undefined, b: GetPaymentMethodsRequest | PlainMessage<GetPaymentMethodsRequest> | undefined): boolean {
    return proto3.util.equals(GetPaymentMethodsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetPaymentMethodsResponse
 */
export class GetPaymentMethodsResponse extends Message<GetPaymentMethodsResponse> {
  /**
   * @generated from field: web.public.GetPaymentMethodsResponse.Status status = 1;
   */
  status = GetPaymentMethodsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.PaymentMethod payment_methods = 3;
   */
  paymentMethods: PaymentMethod[] = [];

  /**
   * @generated from field: bool can_link_bank_account = 4;
   */
  canLinkBankAccount = false;

  constructor(data?: PartialMessage<GetPaymentMethodsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPaymentMethodsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetPaymentMethodsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "payment_methods", kind: "message", T: PaymentMethod, repeated: true },
    { no: 4, name: "can_link_bank_account", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPaymentMethodsResponse {
    return new GetPaymentMethodsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPaymentMethodsResponse {
    return new GetPaymentMethodsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPaymentMethodsResponse {
    return new GetPaymentMethodsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPaymentMethodsResponse | PlainMessage<GetPaymentMethodsResponse> | undefined, b: GetPaymentMethodsResponse | PlainMessage<GetPaymentMethodsResponse> | undefined): boolean {
    return proto3.util.equals(GetPaymentMethodsResponse, a, b);
  }

  static _enums: {
    Status: typeof GetPaymentMethodsResponse.Status,
  };
}

export namespace GetPaymentMethodsResponse {
  /**
   * @generated from enum web.public.GetPaymentMethodsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetPaymentMethodsResponse.Status)
  proto3.util.setEnumType(GetPaymentMethodsResponse.Status, "web.public.GetPaymentMethodsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.DeactivatePaymentMethodRequest
 */
export class DeactivatePaymentMethodRequest extends Message<DeactivatePaymentMethodRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<DeactivatePaymentMethodRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DeactivatePaymentMethodRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivatePaymentMethodRequest {
    return new DeactivatePaymentMethodRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivatePaymentMethodRequest {
    return new DeactivatePaymentMethodRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivatePaymentMethodRequest {
    return new DeactivatePaymentMethodRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivatePaymentMethodRequest | PlainMessage<DeactivatePaymentMethodRequest> | undefined, b: DeactivatePaymentMethodRequest | PlainMessage<DeactivatePaymentMethodRequest> | undefined): boolean {
    return proto3.util.equals(DeactivatePaymentMethodRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.DeactivatePaymentMethodResponse
 */
export class DeactivatePaymentMethodResponse extends Message<DeactivatePaymentMethodResponse> {
  /**
   * @generated from field: web.public.DeactivatePaymentMethodResponse.Status status = 1;
   */
  status = DeactivatePaymentMethodResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<DeactivatePaymentMethodResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DeactivatePaymentMethodResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(DeactivatePaymentMethodResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivatePaymentMethodResponse {
    return new DeactivatePaymentMethodResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivatePaymentMethodResponse {
    return new DeactivatePaymentMethodResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivatePaymentMethodResponse {
    return new DeactivatePaymentMethodResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivatePaymentMethodResponse | PlainMessage<DeactivatePaymentMethodResponse> | undefined, b: DeactivatePaymentMethodResponse | PlainMessage<DeactivatePaymentMethodResponse> | undefined): boolean {
    return proto3.util.equals(DeactivatePaymentMethodResponse, a, b);
  }

  static _enums: {
    Status: typeof DeactivatePaymentMethodResponse.Status,
  };
}

export namespace DeactivatePaymentMethodResponse {
  /**
   * @generated from enum web.public.DeactivatePaymentMethodResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(DeactivatePaymentMethodResponse.Status)
  proto3.util.setEnumType(DeactivatePaymentMethodResponse.Status, "web.public.DeactivatePaymentMethodResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.UnfreezePaymentMethodRequest
 */
export class UnfreezePaymentMethodRequest extends Message<UnfreezePaymentMethodRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<UnfreezePaymentMethodRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UnfreezePaymentMethodRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnfreezePaymentMethodRequest {
    return new UnfreezePaymentMethodRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnfreezePaymentMethodRequest {
    return new UnfreezePaymentMethodRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnfreezePaymentMethodRequest {
    return new UnfreezePaymentMethodRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UnfreezePaymentMethodRequest | PlainMessage<UnfreezePaymentMethodRequest> | undefined, b: UnfreezePaymentMethodRequest | PlainMessage<UnfreezePaymentMethodRequest> | undefined): boolean {
    return proto3.util.equals(UnfreezePaymentMethodRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UnfreezePaymentMethodResponse
 */
export class UnfreezePaymentMethodResponse extends Message<UnfreezePaymentMethodResponse> {
  /**
   * @generated from field: web.public.UnfreezePaymentMethodResponse.Status status = 1;
   */
  status = UnfreezePaymentMethodResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UnfreezePaymentMethodResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UnfreezePaymentMethodResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UnfreezePaymentMethodResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnfreezePaymentMethodResponse {
    return new UnfreezePaymentMethodResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnfreezePaymentMethodResponse {
    return new UnfreezePaymentMethodResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnfreezePaymentMethodResponse {
    return new UnfreezePaymentMethodResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UnfreezePaymentMethodResponse | PlainMessage<UnfreezePaymentMethodResponse> | undefined, b: UnfreezePaymentMethodResponse | PlainMessage<UnfreezePaymentMethodResponse> | undefined): boolean {
    return proto3.util.equals(UnfreezePaymentMethodResponse, a, b);
  }

  static _enums: {
    Status: typeof UnfreezePaymentMethodResponse.Status,
  };
}

export namespace UnfreezePaymentMethodResponse {
  /**
   * @generated from enum web.public.UnfreezePaymentMethodResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UnfreezePaymentMethodResponse.Status)
  proto3.util.setEnumType(UnfreezePaymentMethodResponse.Status, "web.public.UnfreezePaymentMethodResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.FundWalletRequest
 */
export class FundWalletRequest extends Message<FundWalletRequest> {
  /**
   * Funds credited to wallet
   *
   * @generated from field: uint32 funding_amount_cents = 1;
   */
  fundingAmountCents = 0;

  /**
   * Actual charge to the payment method to account for discounts
   *
   * @generated from field: uint32 charged_amount_cents = 2;
   */
  chargedAmountCents = 0;

  /**
   * @generated from field: string payment_method_token = 3;
   */
  paymentMethodToken = "";

  constructor(data?: PartialMessage<FundWalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.FundWalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "funding_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "charged_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FundWalletRequest {
    return new FundWalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FundWalletRequest {
    return new FundWalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FundWalletRequest {
    return new FundWalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FundWalletRequest | PlainMessage<FundWalletRequest> | undefined, b: FundWalletRequest | PlainMessage<FundWalletRequest> | undefined): boolean {
    return proto3.util.equals(FundWalletRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.FundWalletResponse
 */
export class FundWalletResponse extends Message<FundWalletResponse> {
  /**
   * @generated from field: web.public.FundWalletResponse.Status status = 1;
   */
  status = FundWalletResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.PaymentMethod wallet_payment_method = 3;
   */
  walletPaymentMethod?: PaymentMethod;

  constructor(data?: PartialMessage<FundWalletResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.FundWalletResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(FundWalletResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "wallet_payment_method", kind: "message", T: PaymentMethod },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FundWalletResponse {
    return new FundWalletResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FundWalletResponse {
    return new FundWalletResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FundWalletResponse {
    return new FundWalletResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FundWalletResponse | PlainMessage<FundWalletResponse> | undefined, b: FundWalletResponse | PlainMessage<FundWalletResponse> | undefined): boolean {
    return proto3.util.equals(FundWalletResponse, a, b);
  }

  static _enums: {
    Status: typeof FundWalletResponse.Status,
  };
}

export namespace FundWalletResponse {
  /**
   * @generated from enum web.public.FundWalletResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(FundWalletResponse.Status)
  proto3.util.setEnumType(FundWalletResponse.Status, "web.public.FundWalletResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

