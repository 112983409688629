import { Recurrence as CanadaRecurrencePb } from "@repo/protobuf/gen/kikoff_canada/protobuf/types/recurrence_pb";
import Protobuf, { PartialMessage } from "@repo/protobuf/utils";
import Case from "@repo/utils/Case";
import Enum from "@repo/utils/Enum";

import PaymentMethod from "./payments/PaymentMethod.js";

type Recurrence = {
  settings: Recurrence.Settings;
  nextAttempt?: Recurrence.Attempt;
  lastAttempt?: Recurrence.Attempt;
};

namespace Recurrence {
  export const intervals = ["monthly", "weekly"] as const;
  export type Interval = (typeof intervals)[number];
  export type Settings = {
    enabled: boolean;
    paymentMethodId: PaymentMethod.Id;
    interval: Interval;
    offset: number;
  };
  export type Attempt = {
    status?: Attempt.Status;
    at: Date;
    amountCents: number;
  };
  export namespace Attempt {
    export type Status = "success" | "failure";
  }
}

export default Recurrence;

export namespace RecurrenceCanada {
  export const normalize = (proto: CanadaRecurrencePb): Recurrence => ({
    settings: Settings.normalize(proto.settings!),
    lastAttempt: Attempt.normalize(proto.lastAttempt),
    nextAttempt: Attempt.normalize(proto.nextAttempt),
  });

  export namespace Settings {
    export const normalize = (
      proto: CanadaRecurrencePb.Settings,
    ): Recurrence.Settings => {
      const interval = Case.fromConstant(
        Enum.keyOf(CanadaRecurrencePb.Interval, proto.interval),
      ).toCamel();

      if (interval === "unknown")
        throw new Error("Invalid recurrence interval UNKNOWN.");
      return {
        enabled: proto.state === CanadaRecurrencePb.Settings.State.ENABLED,
        interval,
        offset: proto.offset,
        paymentMethodId: proto.paymentMethodId,
      };
    };
    export const denormalize = (
      settings: Partial<Recurrence.Settings>,
    ): PartialMessage<CanadaRecurrencePb.Settings> => {
      if (settings.interval === "weekly")
        throw new Error("Weekly recurrence interval not supported.");
      return {
        interval: { monthly: CanadaRecurrencePb.Interval.MONTHLY }[
          settings.interval!
        ],
        offset: settings.offset,
        paymentMethodId: settings.paymentMethodId,
        state: {
          true: CanadaRecurrencePb.Settings.State.ENABLED,
          false: CanadaRecurrencePb.Settings.State.DISABLED,
        }[`${settings.enabled!}`],
      };
    };
  }
  export namespace Attempt {
    export const normalize = <T extends CanadaRecurrencePb.Attempt | undefined>(
      proto: T,
    ): Recurrence.Attempt | Extract<T, undefined> => {
      if (!proto) return proto as never;

      const status = Case.fromConstant(
        Enum.keyOf(CanadaRecurrencePb.Attempt.Status, proto.status),
      ).toCamel();

      return {
        amountCents: proto.amountCents,
        at: Protobuf.Timestamp.toDate(proto.at!),
        status: status === "unknown" ? undefined : status,
      };
    };
  }
}
