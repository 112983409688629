/// <reference types="google.maps" />

import Address from "@repo/utils/Address";
import UFunction from "@repo/utils/UFunction";

import UHTMLScriptElement from "../UHTMLScriptElement.mjs";

namespace GoogleMaps {
  const callbackName = "googleMapsServiceCallback";

  export const load = UFunction.memo(
    (apiKey: string) =>
      new Promise<typeof google.maps>((resolve, reject) => {
        (window as any)[callbackName] = () => {
          delete (window as any)[callbackName];
          resolve(google.maps);
        };
        UHTMLScriptElement.inject(
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places&callback=${callbackName}`,
        ).catch((e) => {
          load.invalidate(apiKey);
          reject(e);
        });
      }),
  );

  export namespace Place {
    export type Id = string & {};
    export const fetchDetails = (
      client: typeof google.maps,
      placeId: string,
      {
        map = document.createElement("div"),
        fields = [],
        ...options
      }: Omit<google.maps.places.PlaceDetailsRequest, "placeId"> & {
        map?: HTMLDivElement;
      } = {},
    ) =>
      new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        new client.places.PlacesService(map).getDetails(
          {
            placeId,
            fields: ["address_components", "geometry", ...fields],
            ...options,
          },
          (result, status) => {
            if (status === client.places.PlacesServiceStatus.OK)
              return resolve(result!);

            reject(new Error("Google maps query failed"));
          },
        );
      });

    export class InvalidPlaceError extends Error {
      name = "GoogleMaps.Place.InvalidPlaceError";
      message = "Invalid Google Maps place.";

      // Messages cannot by dynamically set, apps may need to redefine to
      // support translations
      constructor() {
        super();
      }
    }
    export class MissingComponentError extends Error {
      name = "GoogleMaps.Place.MissingComponentError";

      constructor(
        public component: keyof Address,
        message: string,
      ) {
        super(message);
      }
    }
    export function toAddress(
      placeResult: google.maps.places.PlaceResult,
    ): Address {
      if (!placeResult.address_components) throw new InvalidPlaceError();

      const componentByType = Object.fromEntries(
        placeResult.address_components.flatMap((component) =>
          component.types.map((type) => [type, component]),
        ) || [],
      );

      if (!componentByType.route)
        throw new MissingComponentError(
          "addressLine1",
          "Address requires street.",
        );
      if (!componentByType.locality)
        throw new MissingComponentError("locality", "Address requires city.");
      if (!componentByType.administrative_area_level_1)
        throw new MissingComponentError(
          "subdivision",
          "Address requires state/province.",
        );
      if (!componentByType.postal_code)
        throw new MissingComponentError(
          "postalCode",
          "Address requires postal code.",
        );
      if (!componentByType.country)
        throw new MissingComponentError("country", "Address requires country.");

      return {
        addressLine1: [
          componentByType.street_number?.long_name,
          componentByType.route.long_name,
        ]
          .filter(Boolean)
          .join(" "),
        locality: componentByType.locality.long_name,
        subdivision: componentByType.administrative_area_level_1.short_name,
        postalCode: componentByType.postal_code.long_name,
        country: componentByType.country.short_name,
      };
    }
    export namespace Prediction {
      export const fetch = (
        client: typeof google.maps,
        input: string,
        options: Omit<google.maps.places.AutocompletionRequest, "input"> = {},
      ) =>
        new client.places.AutocompleteService()
          .getPlacePredictions({
            input,
            ...options,
            types: options.types || ["address"],
            componentRestrictions: options.componentRestrictions,
          })
          .then(({ predictions }) => predictions);
    }
  }
}

export default GoogleMaps;
