import { CreditLine as CanadaCreditLinePb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/lending/credit_line_pb";
import { CreditLineStatement as CanadaCreditLineStatementPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/lending/credit_line_statement_pb";
import Protobuf from "@repo/protobuf/utils";
import Money from "@repo/utils/Money";
import UDate from "@repo/utils/UDate";

import Recurrence, { RecurrenceCanada } from "../Recurrence.js";
import Transaction, { TransactionCanada } from "../Transaction.js";

type CreditLine = {
  id: CreditLine.Id;
  closedAt?: Date;
  minimumPaymentDueCents: number;
  autopay?: Recurrence;
  allowClosure: boolean;
  balanceCents: number;
  creditLimitCents: number;
  outstandingMinimumPaymentCents: number;
  paymentDueAt?: Date;
  openedAt: Date;
  nextStatementClosingAt: any;
  firstPaymentScheduledAt?: Date;
  agreement: CreditLine.Agreement;
  setupComplete: boolean;
};

namespace CreditLine {
  export type Id = string & {};

  export type Agreement = {
    id: Agreement.Id;
    url: string;

    minimumPaymentAmountCents: number;
    minimumPaymentAmountPercent: number;
    defaultCreditLimitCents: number;
  };
  export namespace Agreement {
    export type Id = string & {};
  }
  export type Statement = {
    id: Statement.Id;

    startAt: Date;
    endAt: Date;
    dueAt: Date;

    startingBalanceCents: number;
    endingBalanceCents: number;
    minimumPaymentCents: number;
    creditedCents: number;
    debitedCents: number;
    creditLimitCents: number;
    pastDueAmountCents: number;

    transactions: Transaction[];
  };
  export namespace Statement {
    export type Id = string & {};
  }

  export const isDue = (creditLine: CreditLine | undefined) =>
    !!creditLine && creditLine.outstandingMinimumPaymentCents > 0;
  export const isOverdue = (creditLine: CreditLine | undefined) =>
    !!creditLine &&
    // Only show overdue after the day is over to prevent confusion if the due
    // date is early in the day, they'll be in the 90 day grace period anyways
    Date.now() > +UDate.add.days(UDate.startOf.day(creditLine.paymentDueAt), 1);

  export const urgency = (
    creditLine: CreditLine | undefined,
  ): "high" | "low" | "none" => {
    if (isOverdue(creditLine)) return "high";
    if (isDue(creditLine) && !creditLine?.autopay?.settings.enabled)
      return "low";
    return "none";
  };

  export const nextAmount = {
    // Include promo credits ui here
    render: (creditLine: CreditLine | undefined) =>
      Money.format.cents(
        creditLine &&
          (creditLine.outstandingMinimumPaymentCents ||
            creditLine.minimumPaymentDueCents),
      ),
  };
}

export default CreditLine;

export namespace CreditLineCanada {
  export function normalize(proto: CanadaCreditLinePb): CreditLine {
    const { autopay } = proto;
    return {
      ...proto,
      agreement: proto.agreement!,

      autopay: autopay && RecurrenceCanada.normalize(autopay),

      closedAt: Protobuf.Timestamp.toDate(proto.closedAt),
      nextStatementClosingAt: Protobuf.Timestamp.toDate(
        proto.nextStatementClosingAt,
      ),
      openedAt: Protobuf.Timestamp.toDate(proto.openedAt!),
      paymentDueAt: Protobuf.Timestamp.toDate(proto.paymentDueAt),
      firstPaymentScheduledAt: Protobuf.Timestamp.toDate(
        proto.firstPaymentScheduledAt,
      ),
    };
  }

  export namespace Statement {
    export const normalize = (
      proto: CanadaCreditLineStatementPb,
    ): CreditLine.Statement => ({
      ...proto,

      startAt: Protobuf.Timestamp.toDate(proto.startAt!),
      endAt: Protobuf.Timestamp.toDate(proto.endAt!),
      dueAt: Protobuf.Timestamp.toDate(proto.dueAt!),

      transactions: proto.transactions.map(TransactionCanada.normalize),
    });
  }
}
