// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/user.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Error } from "./error_pb.js";
import { Loan } from "./lending_pb.js";
import { Wallet } from "./funds_pb.js";
import { CreditLineAccount } from "./credit_line_pb.js";
import { CreditMonitor } from "./credit_monitoring_pb.js";

/**
 * @generated from message web.public.ReVerifyIdentityRequest
 */
export class ReVerifyIdentityRequest extends Message<ReVerifyIdentityRequest> {
  /**
   * @generated from field: string ssn_last_4 = 1;
   */
  ssnLast4 = "";

  constructor(data?: PartialMessage<ReVerifyIdentityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ReVerifyIdentityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssn_last_4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReVerifyIdentityRequest {
    return new ReVerifyIdentityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReVerifyIdentityRequest {
    return new ReVerifyIdentityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReVerifyIdentityRequest {
    return new ReVerifyIdentityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReVerifyIdentityRequest | PlainMessage<ReVerifyIdentityRequest> | undefined, b: ReVerifyIdentityRequest | PlainMessage<ReVerifyIdentityRequest> | undefined): boolean {
    return proto3.util.equals(ReVerifyIdentityRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ReVerifyIdentityResponse
 */
export class ReVerifyIdentityResponse extends Message<ReVerifyIdentityResponse> {
  /**
   * @generated from field: web.public.ReVerifyIdentityResponse.Status status = 1;
   */
  status = ReVerifyIdentityResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string identity_verification_token = 3;
   */
  identityVerificationToken = "";

  /**
   * @generated from field: bool seems_legit = 4;
   */
  seemsLegit = false;

  constructor(data?: PartialMessage<ReVerifyIdentityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ReVerifyIdentityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ReVerifyIdentityResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "identity_verification_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seems_legit", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReVerifyIdentityResponse {
    return new ReVerifyIdentityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReVerifyIdentityResponse {
    return new ReVerifyIdentityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReVerifyIdentityResponse {
    return new ReVerifyIdentityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReVerifyIdentityResponse | PlainMessage<ReVerifyIdentityResponse> | undefined, b: ReVerifyIdentityResponse | PlainMessage<ReVerifyIdentityResponse> | undefined): boolean {
    return proto3.util.equals(ReVerifyIdentityResponse, a, b);
  }

  static _enums: {
    Status: typeof ReVerifyIdentityResponse.Status,
  };
}

export namespace ReVerifyIdentityResponse {
  /**
   * @generated from enum web.public.ReVerifyIdentityResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ReVerifyIdentityResponse.Status)
  proto3.util.setEnumType(ReVerifyIdentityResponse.Status, "web.public.ReVerifyIdentityResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.PersonalInfo
 */
export class PersonalInfo extends Message<PersonalInfo> {
  /**
   * @generated from field: string given_name = 1;
   */
  givenName = "";

  /**
   * @generated from field: string middle_name = 2;
   */
  middleName = "";

  /**
   * @generated from field: string surname = 3;
   */
  surname = "";

  /**
   * @generated from field: string address_line_1 = 4;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 5;
   */
  addressLine2 = "";

  /**
   * @generated from field: string city = 6;
   */
  city = "";

  /**
   * @generated from field: string state = 7;
   */
  state = "";

  /**
   * @generated from field: string zip = 8;
   */
  zip = "";

  /**
   * @generated from field: string country = 9;
   */
  country = "";

  /**
   * @generated from field: string phone = 10;
   */
  phone = "";

  /**
   * @generated from field: string email = 11;
   */
  email = "";

  /**
   * @generated from field: bool email_confirmed = 12;
   */
  emailConfirmed = false;

  /**
   * @generated from field: bool phone_confirmed = 13;
   */
  phoneConfirmed = false;

  /**
   * @generated from field: bool show_credit = 14;
   */
  showCredit = false;

  constructor(data?: PartialMessage<PersonalInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PersonalInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "given_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "surname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "email_confirmed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "phone_confirmed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "show_credit", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonalInfo {
    return new PersonalInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonalInfo {
    return new PersonalInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonalInfo {
    return new PersonalInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PersonalInfo | PlainMessage<PersonalInfo> | undefined, b: PersonalInfo | PlainMessage<PersonalInfo> | undefined): boolean {
    return proto3.util.equals(PersonalInfo, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UserAddress
 */
export class UserAddress extends Message<UserAddress> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string middle_name = 2;
   */
  middleName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  /**
   * @generated from field: string address_line_1 = 4;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 5;
   */
  addressLine2 = "";

  /**
   * @generated from field: string city = 6;
   */
  city = "";

  /**
   * @generated from field: string state = 7;
   */
  state = "";

  /**
   * @generated from field: string zip = 8;
   */
  zip = "";

  /**
   * @generated from field: string country = 9;
   */
  country = "";

  /**
   * @generated from field: string address_type = 10;
   */
  addressType = "";

  constructor(data?: PartialMessage<UserAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UserAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "address_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserAddress {
    return new UserAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserAddress {
    return new UserAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserAddress {
    return new UserAddress().fromJsonString(jsonString, options);
  }

  static equals(a: UserAddress | PlainMessage<UserAddress> | undefined, b: UserAddress | PlainMessage<UserAddress> | undefined): boolean {
    return proto3.util.equals(UserAddress, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.NotificationChannels
 */
export class NotificationChannels extends Message<NotificationChannels> {
  /**
   * @generated from field: web.public.NotificationChannels.Status email = 1;
   */
  email = NotificationChannels.Status.UNKNOWN;

  /**
   * @generated from field: web.public.NotificationChannels.Status mobile_push = 2;
   */
  mobilePush = NotificationChannels.Status.UNKNOWN;

  /**
   * @generated from field: web.public.NotificationChannels.Status sms = 3;
   */
  sms = NotificationChannels.Status.UNKNOWN;

  constructor(data?: PartialMessage<NotificationChannels>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.NotificationChannels";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "enum", T: proto3.getEnumType(NotificationChannels.Status) },
    { no: 2, name: "mobile_push", kind: "enum", T: proto3.getEnumType(NotificationChannels.Status) },
    { no: 3, name: "sms", kind: "enum", T: proto3.getEnumType(NotificationChannels.Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationChannels {
    return new NotificationChannels().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationChannels {
    return new NotificationChannels().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationChannels {
    return new NotificationChannels().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationChannels | PlainMessage<NotificationChannels> | undefined, b: NotificationChannels | PlainMessage<NotificationChannels> | undefined): boolean {
    return proto3.util.equals(NotificationChannels, a, b);
  }

  static _enums: {
    Status: typeof NotificationChannels.Status,
  };
}

export namespace NotificationChannels {
  /**
   * @generated from enum web.public.NotificationChannels.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: ENABLED = 1;
     */
    ENABLED = 1,

    /**
     * @generated from enum value: DISABLED = 2;
     */
    DISABLED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(NotificationChannels.Status)
  proto3.util.setEnumType(NotificationChannels.Status, "web.public.NotificationChannels.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ENABLED" },
    { no: 2, name: "DISABLED" },
  ]);
}

/**
 * @generated from message web.public.Promo
 */
export class Promo extends Message<Promo> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: uint32 months_credited = 3;
   */
  monthsCredited = 0;

  /**
   * @generated from field: uint32 monthly_credit_amount_cents = 4;
   */
  monthlyCreditAmountCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp end_at = 5;
   */
  endAt?: Timestamp;

  constructor(data?: PartialMessage<Promo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Promo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "months_credited", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "monthly_credit_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "end_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Promo {
    return new Promo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Promo {
    return new Promo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Promo {
    return new Promo().fromJsonString(jsonString, options);
  }

  static equals(a: Promo | PlainMessage<Promo> | undefined, b: Promo | PlainMessage<Promo> | undefined): boolean {
    return proto3.util.equals(Promo, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AccountType
 */
export class AccountType extends Message<AccountType> {
  /**
   * @generated from field: web.public.AccountType.Type type = 1;
   */
  type = AccountType.Type.UNKNOWN;

  constructor(data?: PartialMessage<AccountType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AccountType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AccountType.Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountType {
    return new AccountType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountType {
    return new AccountType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountType {
    return new AccountType().fromJsonString(jsonString, options);
  }

  static equals(a: AccountType | PlainMessage<AccountType> | undefined, b: AccountType | PlainMessage<AccountType> | undefined): boolean {
    return proto3.util.equals(AccountType, a, b);
  }

  static _enums: {
    Type: typeof AccountType.Type,
  };
}

export namespace AccountType {
  /**
   * @generated from enum web.public.AccountType.Type
   */
  export enum Type {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: DEFAULT = 1;
     */
    DEFAULT = 1,

    /**
     * @generated from enum value: CASH_CARD_ONLY = 2;
     */
    CASH_CARD_ONLY = 2,

    /**
     * @generated from enum value: FREEMIUM_SIGNUP = 3;
     */
    FREEMIUM_SIGNUP = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(AccountType.Type)
  proto3.util.setEnumType(AccountType.Type, "web.public.AccountType.Type", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "DEFAULT" },
    { no: 2, name: "CASH_CARD_ONLY" },
    { no: 3, name: "FREEMIUM_SIGNUP" },
  ]);
}

/**
 * @generated from message web.public.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: web.public.User.Status status = 1;
   */
  status = User.Status.UNKNOWN;

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  /**
   * @generated from field: web.public.PersonalInfo info = 3;
   */
  info?: PersonalInfo;

  /**
   * @generated from field: repeated web.public.Loan open_loans = 4;
   */
  openLoans: Loan[] = [];

  /**
   * @generated from field: web.public.Wallet wallet = 5;
   */
  wallet?: Wallet;

  /**
   * @generated from field: repeated web.public.UserAddress addresses = 6;
   */
  addresses: UserAddress[] = [];

  /**
   * @generated from field: web.public.CreditLineAccount open_credit_line = 7;
   */
  openCreditLine?: CreditLineAccount;

  /**
   * @generated from field: repeated web.public.TodoItem todos = 8;
   */
  todos: TodoItem[] = [];

  /**
   * @generated from field: repeated web.public.Offer offers = 9;
   */
  offers: Offer[] = [];

  /**
   * @generated from field: repeated web.public.Loan closed_loans = 10;
   */
  closedLoans: Loan[] = [];

  /**
   * NOT USED ANYWHERE, will NOT be serialized on backend.
   *
   * @generated from field: web.public.NotificationChannels notification_channels = 11;
   */
  notificationChannels?: NotificationChannels;

  /**
   * DEPRECATED
   *
   * @generated from field: web.public.Persona persona = 12;
   */
  persona?: Persona;

  /**
   * @generated from field: web.public.DownloadMobileAppInfo download_mobile_app_info = 13;
   */
  downloadMobileAppInfo?: DownloadMobileAppInfo;

  /**
   * @generated from field: web.public.PersonaAddressVerification persona_address_verification = 14;
   */
  personaAddressVerification?: PersonaAddressVerification;

  /**
   * @generated from field: string locale = 15;
   */
  locale = "";

  /**
   * If the user is eligible for secured card onboarding (ie. they don't have an account, but can create one)
   *
   * @generated from field: bool open_secured_card = 16;
   */
  openSecuredCard = false;

  /**
   * @generated from field: repeated web.public.Promo promos = 17;
   */
  promos: Promo[] = [];

  /**
   * If the user has an active secured card
   *
   * @generated from field: bool has_active_secured_card = 18;
   */
  hasActiveSecuredCard = false;

  /**
   * @generated from field: web.public.AccountType account_type = 19;
   */
  accountType?: AccountType;

  /**
   * @generated from field: double expected_ltv = 20;
   */
  expectedLtv = 0;

  /**
   * @generated from field: bool minimum_deposit_made_to_sc = 21;
   */
  minimumDepositMadeToSc = false;

  /**
   * If the user has a pending address change request
   *
   * @generated from field: bool pending_address_change = 22;
   */
  pendingAddressChange = false;

  /**
   * @generated from field: bool able_to_enroll_in_tribureau = 23;
   */
  ableToEnrollInTribureau = false;

  /**
   * @generated from field: string secured_card_last_four = 25;
   */
  securedCardLastFour = "";

  /**
   * @generated from field: bool needs_reverification = 26;
   */
  needsReverification = false;

  /**
   * @generated from field: bool rent_reporting_enabled = 27;
   */
  rentReportingEnabled = false;

  /**
   * @generated from field: string secured_card_cancelled_state = 28;
   */
  securedCardCancelledState = "";

  /**
   * @generated from field: bool funded_secured_card = 29;
   */
  fundedSecuredCard = false;

  /**
   * @generated from field: repeated web.public.CreditLineAccount closed_credit_lines = 30;
   */
  closedCreditLines: CreditLineAccount[] = [];

  /**
   * @generated from field: bool show_hello_privacy = 31;
   */
  showHelloPrivacy = false;

  /**
   * @generated from field: bool has_associated_sso = 32;
   */
  hasAssociatedSso = false;

  /**
   * @generated from field: bool needs_password = 33;
   */
  needsPassword = false;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(User.Status) },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "info", kind: "message", T: PersonalInfo },
    { no: 4, name: "open_loans", kind: "message", T: Loan, repeated: true },
    { no: 5, name: "wallet", kind: "message", T: Wallet },
    { no: 6, name: "addresses", kind: "message", T: UserAddress, repeated: true },
    { no: 7, name: "open_credit_line", kind: "message", T: CreditLineAccount },
    { no: 8, name: "todos", kind: "message", T: TodoItem, repeated: true },
    { no: 9, name: "offers", kind: "message", T: Offer, repeated: true },
    { no: 10, name: "closed_loans", kind: "message", T: Loan, repeated: true },
    { no: 11, name: "notification_channels", kind: "message", T: NotificationChannels },
    { no: 12, name: "persona", kind: "message", T: Persona },
    { no: 13, name: "download_mobile_app_info", kind: "message", T: DownloadMobileAppInfo },
    { no: 14, name: "persona_address_verification", kind: "message", T: PersonaAddressVerification },
    { no: 15, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "open_secured_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "promos", kind: "message", T: Promo, repeated: true },
    { no: 18, name: "has_active_secured_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "account_type", kind: "message", T: AccountType },
    { no: 20, name: "expected_ltv", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "minimum_deposit_made_to_sc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "pending_address_change", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "able_to_enroll_in_tribureau", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "secured_card_last_four", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "needs_reverification", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "rent_reporting_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "secured_card_cancelled_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "funded_secured_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "closed_credit_lines", kind: "message", T: CreditLineAccount, repeated: true },
    { no: 31, name: "show_hello_privacy", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "has_associated_sso", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "needs_password", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }

  static _enums: {
    Scope: typeof User.Scope,
    Status: typeof User.Status,
  };
}

export namespace User {
  /**
   *
   * If scopes is empty, then entire user is serialized.
   * Please keep this updated as needed (when scopes are added or new fields introduced)
   * DEFAULT           ->  everything (we may change this definition to MINIMAL after all clients are updated)
   *
   * ALL               ->  everything
   * MINIMAL           ->  status, token
   * FIRST_PARTY_INFO  ->  everything, except persona_address_verification
   *
   * BASIC_INFO        ->  info, account_type, download_mobile_app_info, locale
   * ADDRESS_INFO      ->  addresses, pending_address_change
   *
   * PRODUCTS          ->  open_loans, closed_loans, has_active_secured_card, open_credit_line, wallet, secured_card_cancelled_state
   * TODOS             ->  todos
   * FEATURES          ->  offers, open_secured_card, promos, minimum_deposit_made_to_sc,
   * able_to_enroll_in_tribureau, expected_ltv, needs_reverification, show_hello_privacy
   * PERSONA           ->  persona_address_verification
   * CREDIT_LINE       ->  credit_line
   * LOANS             ->  open_loans, closed_loans
   *
   * @generated from enum web.public.User.Scope
   */
  export enum Scope {
    /**
     * @generated from enum value: DEFAULT = 0;
     */
    DEFAULT = 0,

    /**
     * @generated from enum value: ALL = 1;
     */
    ALL = 1,

    /**
     * @generated from enum value: MINIMAL = 2;
     */
    MINIMAL = 2,

    /**
     * @generated from enum value: FIRST_PARTY_INFO = 3;
     */
    FIRST_PARTY_INFO = 3,

    /**
     * @generated from enum value: BASIC_INFO = 10;
     */
    BASIC_INFO = 10,

    /**
     * @generated from enum value: ADDRESS_INFO = 11;
     */
    ADDRESS_INFO = 11,

    /**
     * @generated from enum value: PRODUCTS = 20;
     */
    PRODUCTS = 20,

    /**
     * @generated from enum value: TODOS = 21;
     */
    TODOS = 21,

    /**
     * @generated from enum value: FEATURES = 22;
     */
    FEATURES = 22,

    /**
     * @generated from enum value: PERSONA = 23;
     */
    PERSONA = 23,

    /**
     * @generated from enum value: CREDIT_LINE = 24;
     */
    CREDIT_LINE = 24,

    /**
     * @generated from enum value: LOANS = 25;
     */
    LOANS = 25,
  }
  // Retrieve enum metadata with: proto3.getEnumType(User.Scope)
  proto3.util.setEnumType(User.Scope, "web.public.User.Scope", [
    { no: 0, name: "DEFAULT" },
    { no: 1, name: "ALL" },
    { no: 2, name: "MINIMAL" },
    { no: 3, name: "FIRST_PARTY_INFO" },
    { no: 10, name: "BASIC_INFO" },
    { no: 11, name: "ADDRESS_INFO" },
    { no: 20, name: "PRODUCTS" },
    { no: 21, name: "TODOS" },
    { no: 22, name: "FEATURES" },
    { no: 23, name: "PERSONA" },
    { no: 24, name: "CREDIT_LINE" },
    { no: 25, name: "LOANS" },
  ]);
}

export namespace User {
  /**
   * @generated from enum web.public.User.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * Entering PII and other information
     *
     * @generated from enum value: ONBOARDING = 1;
     */
    ONBOARDING = 1,

    /**
     * First loan experience
     *
     * @generated from enum value: POST_ONBOARDING = 2;
     */
    POST_ONBOARDING = 2,

    /**
     * Loan disbursed, and in payment
     *
     * @generated from enum value: ONBOARDED = 3;
     */
    ONBOARDED = 3,

    /**
     * @generated from enum value: WAITLISTED = 4;
     */
    WAITLISTED = 4,
  }
  // Retrieve enum metadata with: proto3.getEnumType(User.Status)
  proto3.util.setEnumType(User.Status, "web.public.User.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ONBOARDING" },
    { no: 2, name: "POST_ONBOARDING" },
    { no: 3, name: "ONBOARDED" },
    { no: 4, name: "WAITLISTED" },
  ]);
}

/**
 * @generated from message web.public.Persona
 */
export class Persona extends Message<Persona> {
  /**
   * @generated from field: bool address_verification_needed = 1;
   */
  addressVerificationNeeded = false;

  /**
   * @generated from field: bool address_verification_finished = 2;
   */
  addressVerificationFinished = false;

  /**
   * @generated from field: string address_verification_status = 3;
   */
  addressVerificationStatus = "";

  constructor(data?: PartialMessage<Persona>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Persona";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_verification_needed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "address_verification_finished", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "address_verification_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Persona {
    return new Persona().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Persona {
    return new Persona().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Persona {
    return new Persona().fromJsonString(jsonString, options);
  }

  static equals(a: Persona | PlainMessage<Persona> | undefined, b: Persona | PlainMessage<Persona> | undefined): boolean {
    return proto3.util.equals(Persona, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PersonaAddressVerification
 */
export class PersonaAddressVerification extends Message<PersonaAddressVerification> {
  /**
   * @generated from field: bool address_verification_required = 1;
   */
  addressVerificationRequired = false;

  /**
   * @generated from field: bool user_action_required = 2;
   */
  userActionRequired = false;

  /**
   * @generated from field: string address_verification_state = 3;
   */
  addressVerificationState = "";

  /**
   * @generated from field: string persona_url = 4;
   */
  personaUrl = "";

  constructor(data?: PartialMessage<PersonaAddressVerification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PersonaAddressVerification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_verification_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "user_action_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "address_verification_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "persona_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonaAddressVerification {
    return new PersonaAddressVerification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonaAddressVerification {
    return new PersonaAddressVerification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonaAddressVerification {
    return new PersonaAddressVerification().fromJsonString(jsonString, options);
  }

  static equals(a: PersonaAddressVerification | PlainMessage<PersonaAddressVerification> | undefined, b: PersonaAddressVerification | PlainMessage<PersonaAddressVerification> | undefined): boolean {
    return proto3.util.equals(PersonaAddressVerification, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.DownloadMobileAppInfo
 */
export class DownloadMobileAppInfo extends Message<DownloadMobileAppInfo> {
  /**
   * @generated from field: string cta = 2;
   */
  cta = "";

  /**
   * @generated from field: string onboarding_full_page_message = 3;
   */
  onboardingFullPageMessage = "";

  constructor(data?: PartialMessage<DownloadMobileAppInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DownloadMobileAppInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "cta", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "onboarding_full_page_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadMobileAppInfo {
    return new DownloadMobileAppInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadMobileAppInfo {
    return new DownloadMobileAppInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadMobileAppInfo {
    return new DownloadMobileAppInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadMobileAppInfo | PlainMessage<DownloadMobileAppInfo> | undefined, b: DownloadMobileAppInfo | PlainMessage<DownloadMobileAppInfo> | undefined): boolean {
    return proto3.util.equals(DownloadMobileAppInfo, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Offer
 */
export class Offer extends Message<Offer> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description_md = 2;
   */
  descriptionMd = "";

  /**
   * @generated from field: string cta_text = 3;
   */
  ctaText = "";

  /**
   * @generated from field: string cta_url = 4;
   */
  ctaUrl = "";

  /**
   * @generated from field: string icon_url = 5;
   */
  iconUrl = "";

  /**
   * @generated from field: string name = 6;
   */
  name = "";

  /**
   * @generated from field: string category = 7;
   */
  category = "";

  constructor(data?: PartialMessage<Offer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Offer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description_md", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cta_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cta_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Offer {
    return new Offer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Offer {
    return new Offer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Offer {
    return new Offer().fromJsonString(jsonString, options);
  }

  static equals(a: Offer | PlainMessage<Offer> | undefined, b: Offer | PlainMessage<Offer> | undefined): boolean {
    return proto3.util.equals(Offer, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.TodoItem
 */
export class TodoItem extends Message<TodoItem> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description_md = 2;
   */
  descriptionMd = "";

  /**
   * @generated from field: string cta_text = 3;
   */
  ctaText = "";

  /**
   * @generated from field: string cta_url = 4;
   */
  ctaUrl = "";

  /**
   * @generated from field: web.public.TodoItem.Urgency urgency = 5;
   */
  urgency = TodoItem.Urgency.UNKNOWN;

  /**
   * @generated from field: string footer_text = 6;
   */
  footerText = "";

  /**
   * @generated from field: string image_url = 7;
   */
  imageUrl = "";

  /**
   * @generated from field: string id = 8;
   */
  id = "";

  constructor(data?: PartialMessage<TodoItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TodoItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description_md", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cta_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cta_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "urgency", kind: "enum", T: proto3.getEnumType(TodoItem.Urgency) },
    { no: 6, name: "footer_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TodoItem {
    return new TodoItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TodoItem {
    return new TodoItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TodoItem {
    return new TodoItem().fromJsonString(jsonString, options);
  }

  static equals(a: TodoItem | PlainMessage<TodoItem> | undefined, b: TodoItem | PlainMessage<TodoItem> | undefined): boolean {
    return proto3.util.equals(TodoItem, a, b);
  }

  static _enums: {
    Urgency: typeof TodoItem.Urgency,
  };
}

export namespace TodoItem {
  /**
   * @generated from enum web.public.TodoItem.Urgency
   */
  export enum Urgency {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: NORMAL = 1;
     */
    NORMAL = 1,

    /**
     * @generated from enum value: EXCITING = 2;
     */
    EXCITING = 2,

    /**
     * @generated from enum value: HIGHLIGHTED = 3;
     */
    HIGHLIGHTED = 3,

    /**
     * @generated from enum value: HOT = 4;
     */
    HOT = 4,

    /**
     * @generated from enum value: COOL = 5;
     */
    COOL = 5,

    /**
     * @generated from enum value: ANNOYING = 99;
     */
    ANNOYING = 99,
  }
  // Retrieve enum metadata with: proto3.getEnumType(TodoItem.Urgency)
  proto3.util.setEnumType(TodoItem.Urgency, "web.public.TodoItem.Urgency", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "NORMAL" },
    { no: 2, name: "EXCITING" },
    { no: 3, name: "HIGHLIGHTED" },
    { no: 4, name: "HOT" },
    { no: 5, name: "COOL" },
    { no: 99, name: "ANNOYING" },
  ]);
}

/**
 * @generated from message web.public.FeatureFlag
 */
export class FeatureFlag extends Message<FeatureFlag> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: bool pass = 2;
   */
  pass = false;

  constructor(data?: PartialMessage<FeatureFlag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.FeatureFlag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pass", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeatureFlag {
    return new FeatureFlag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeatureFlag {
    return new FeatureFlag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeatureFlag {
    return new FeatureFlag().fromJsonString(jsonString, options);
  }

  static equals(a: FeatureFlag | PlainMessage<FeatureFlag> | undefined, b: FeatureFlag | PlainMessage<FeatureFlag> | undefined): boolean {
    return proto3.util.equals(FeatureFlag, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Experiment
 */
export class Experiment extends Message<Experiment> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string variant = 2;
   */
  variant = "";

  constructor(data?: PartialMessage<Experiment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Experiment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Experiment {
    return new Experiment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Experiment {
    return new Experiment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Experiment {
    return new Experiment().fromJsonString(jsonString, options);
  }

  static equals(a: Experiment | PlainMessage<Experiment> | undefined, b: Experiment | PlainMessage<Experiment> | undefined): boolean {
    return proto3.util.equals(Experiment, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetUserInfoRequest
 */
export class GetUserInfoRequest extends Message<GetUserInfoRequest> {
  /**
   * @generated from field: repeated web.public.User.Scope scopes = 1;
   */
  scopes: User.Scope[] = [];

  constructor(data?: PartialMessage<GetUserInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetUserInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scopes", kind: "enum", T: proto3.getEnumType(User.Scope), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserInfoRequest {
    return new GetUserInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserInfoRequest {
    return new GetUserInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserInfoRequest {
    return new GetUserInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserInfoRequest | PlainMessage<GetUserInfoRequest> | undefined, b: GetUserInfoRequest | PlainMessage<GetUserInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetUserInfoRequest, a, b);
  }

  static _enums: {
    Scope: typeof GetUserInfoRequest.Scope,
  };
}

export namespace GetUserInfoRequest {
  /**
   * DEPRECATED
   *
   * @generated from enum web.public.GetUserInfoRequest.Scope
   */
  export enum Scope {
    /**
     * @generated from enum value: DEFAULT = 0;
     */
    DEFAULT = 0,

    /**
     * @generated from enum value: ALL = 1;
     */
    ALL = 1,

    /**
     * @generated from enum value: MINIMAL = 2;
     */
    MINIMAL = 2,

    /**
     * @generated from enum value: FIRST_PARTY_INFO = 3;
     */
    FIRST_PARTY_INFO = 3,

    /**
     * @generated from enum value: BASIC_INFO = 10;
     */
    BASIC_INFO = 10,

    /**
     * @generated from enum value: ADDRESS_INFO = 11;
     */
    ADDRESS_INFO = 11,

    /**
     * @generated from enum value: PRODUCTS = 20;
     */
    PRODUCTS = 20,

    /**
     * @generated from enum value: TODOS = 21;
     */
    TODOS = 21,

    /**
     * @generated from enum value: FEATURES = 22;
     */
    FEATURES = 22,

    /**
     * @generated from enum value: PERSONA = 23;
     */
    PERSONA = 23,

    /**
     * @generated from enum value: CREDIT_LINE = 24;
     */
    CREDIT_LINE = 24,

    /**
     * @generated from enum value: LOANS = 25;
     */
    LOANS = 25,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetUserInfoRequest.Scope)
  proto3.util.setEnumType(GetUserInfoRequest.Scope, "web.public.GetUserInfoRequest.Scope", [
    { no: 0, name: "DEFAULT" },
    { no: 1, name: "ALL" },
    { no: 2, name: "MINIMAL" },
    { no: 3, name: "FIRST_PARTY_INFO" },
    { no: 10, name: "BASIC_INFO" },
    { no: 11, name: "ADDRESS_INFO" },
    { no: 20, name: "PRODUCTS" },
    { no: 21, name: "TODOS" },
    { no: 22, name: "FEATURES" },
    { no: 23, name: "PERSONA" },
    { no: 24, name: "CREDIT_LINE" },
    { no: 25, name: "LOANS" },
  ]);
}

/**
 * @generated from message web.public.GetUserInfoResponse
 */
export class GetUserInfoResponse extends Message<GetUserInfoResponse> {
  /**
   * @generated from field: web.public.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: repeated string user_fields = 2;
   */
  userFields: string[] = [];

  constructor(data?: PartialMessage<GetUserInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetUserInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "user_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserInfoResponse {
    return new GetUserInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserInfoResponse {
    return new GetUserInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserInfoResponse {
    return new GetUserInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserInfoResponse | PlainMessage<GetUserInfoResponse> | undefined, b: GetUserInfoResponse | PlainMessage<GetUserInfoResponse> | undefined): boolean {
    return proto3.util.equals(GetUserInfoResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetInitPageRequest
 */
export class GetInitPageRequest extends Message<GetInitPageRequest> {
  /**
   * @generated from field: repeated web.public.User.Scope scopes = 1;
   */
  scopes: User.Scope[] = [];

  constructor(data?: PartialMessage<GetInitPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetInitPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scopes", kind: "enum", T: proto3.getEnumType(User.Scope), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInitPageRequest {
    return new GetInitPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInitPageRequest {
    return new GetInitPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInitPageRequest {
    return new GetInitPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInitPageRequest | PlainMessage<GetInitPageRequest> | undefined, b: GetInitPageRequest | PlainMessage<GetInitPageRequest> | undefined): boolean {
    return proto3.util.equals(GetInitPageRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetInitPageResponse
 */
export class GetInitPageResponse extends Message<GetInitPageResponse> {
  /**
   * @generated from field: web.public.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: google.protobuf.Timestamp server_time = 2;
   */
  serverTime?: Timestamp;

  /**
   * @generated from field: bool impersonating = 3;
   */
  impersonating = false;

  /**
   * @generated from field: repeated string user_fields = 4;
   */
  userFields: string[] = [];

  /**
   * @generated from field: bool mfa_required = 5;
   */
  mfaRequired = false;

  constructor(data?: PartialMessage<GetInitPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetInitPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "server_time", kind: "message", T: Timestamp },
    { no: 3, name: "impersonating", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "user_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "mfa_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInitPageResponse {
    return new GetInitPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInitPageResponse {
    return new GetInitPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInitPageResponse {
    return new GetInitPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInitPageResponse | PlainMessage<GetInitPageResponse> | undefined, b: GetInitPageResponse | PlainMessage<GetInitPageResponse> | undefined): boolean {
    return proto3.util.equals(GetInitPageResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateNotificationChannelsRequest
 */
export class UpdateNotificationChannelsRequest extends Message<UpdateNotificationChannelsRequest> {
  /**
   * @generated from field: web.public.NotificationChannels notification_channels = 1;
   */
  notificationChannels?: NotificationChannels;

  constructor(data?: PartialMessage<UpdateNotificationChannelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateNotificationChannelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "notification_channels", kind: "message", T: NotificationChannels },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateNotificationChannelsRequest {
    return new UpdateNotificationChannelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateNotificationChannelsRequest {
    return new UpdateNotificationChannelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateNotificationChannelsRequest {
    return new UpdateNotificationChannelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateNotificationChannelsRequest | PlainMessage<UpdateNotificationChannelsRequest> | undefined, b: UpdateNotificationChannelsRequest | PlainMessage<UpdateNotificationChannelsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateNotificationChannelsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateNotificationChannelsResponse
 */
export class UpdateNotificationChannelsResponse extends Message<UpdateNotificationChannelsResponse> {
  /**
   * @generated from field: web.public.UpdateNotificationChannelsResponse.Status status = 1;
   */
  status = UpdateNotificationChannelsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.NotificationChannels notification_channels = 3;
   */
  notificationChannels?: NotificationChannels;

  constructor(data?: PartialMessage<UpdateNotificationChannelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateNotificationChannelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateNotificationChannelsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "notification_channels", kind: "message", T: NotificationChannels },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateNotificationChannelsResponse {
    return new UpdateNotificationChannelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateNotificationChannelsResponse {
    return new UpdateNotificationChannelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateNotificationChannelsResponse {
    return new UpdateNotificationChannelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateNotificationChannelsResponse | PlainMessage<UpdateNotificationChannelsResponse> | undefined, b: UpdateNotificationChannelsResponse | PlainMessage<UpdateNotificationChannelsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateNotificationChannelsResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateNotificationChannelsResponse.Status,
  };
}

export namespace UpdateNotificationChannelsResponse {
  /**
   * @generated from enum web.public.UpdateNotificationChannelsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateNotificationChannelsResponse.Status)
  proto3.util.setEnumType(UpdateNotificationChannelsResponse.Status, "web.public.UpdateNotificationChannelsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.UpdateLocaleRequest
 */
export class UpdateLocaleRequest extends Message<UpdateLocaleRequest> {
  /**
   * @generated from field: string locale = 1;
   */
  locale = "";

  constructor(data?: PartialMessage<UpdateLocaleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateLocaleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLocaleRequest {
    return new UpdateLocaleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLocaleRequest {
    return new UpdateLocaleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLocaleRequest {
    return new UpdateLocaleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLocaleRequest | PlainMessage<UpdateLocaleRequest> | undefined, b: UpdateLocaleRequest | PlainMessage<UpdateLocaleRequest> | undefined): boolean {
    return proto3.util.equals(UpdateLocaleRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateLocaleResponse
 */
export class UpdateLocaleResponse extends Message<UpdateLocaleResponse> {
  /**
   * @generated from field: web.public.UpdateLocaleResponse.Status status = 1;
   */
  status = UpdateLocaleResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateLocaleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateLocaleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateLocaleResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLocaleResponse {
    return new UpdateLocaleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLocaleResponse {
    return new UpdateLocaleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLocaleResponse {
    return new UpdateLocaleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLocaleResponse | PlainMessage<UpdateLocaleResponse> | undefined, b: UpdateLocaleResponse | PlainMessage<UpdateLocaleResponse> | undefined): boolean {
    return proto3.util.equals(UpdateLocaleResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateLocaleResponse.Status,
  };
}

export namespace UpdateLocaleResponse {
  /**
   * @generated from enum web.public.UpdateLocaleResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateLocaleResponse.Status)
  proto3.util.setEnumType(UpdateLocaleResponse.Status, "web.public.UpdateLocaleResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.UpdateThemeRequest
 */
export class UpdateThemeRequest extends Message<UpdateThemeRequest> {
  /**
   * @generated from field: string theme = 1;
   */
  theme = "";

  constructor(data?: PartialMessage<UpdateThemeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateThemeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "theme", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateThemeRequest {
    return new UpdateThemeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateThemeRequest {
    return new UpdateThemeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateThemeRequest {
    return new UpdateThemeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateThemeRequest | PlainMessage<UpdateThemeRequest> | undefined, b: UpdateThemeRequest | PlainMessage<UpdateThemeRequest> | undefined): boolean {
    return proto3.util.equals(UpdateThemeRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateThemeResponse
 */
export class UpdateThemeResponse extends Message<UpdateThemeResponse> {
  /**
   * @generated from field: web.public.UpdateThemeResponse.Status status = 1;
   */
  status = UpdateThemeResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateThemeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateThemeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateThemeResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateThemeResponse {
    return new UpdateThemeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateThemeResponse {
    return new UpdateThemeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateThemeResponse {
    return new UpdateThemeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateThemeResponse | PlainMessage<UpdateThemeResponse> | undefined, b: UpdateThemeResponse | PlainMessage<UpdateThemeResponse> | undefined): boolean {
    return proto3.util.equals(UpdateThemeResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateThemeResponse.Status,
  };
}

export namespace UpdateThemeResponse {
  /**
   * @generated from enum web.public.UpdateThemeResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateThemeResponse.Status)
  proto3.util.setEnumType(UpdateThemeResponse.Status, "web.public.UpdateThemeResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.UpdatePasswordRequest
 */
export class UpdatePasswordRequest extends Message<UpdatePasswordRequest> {
  /**
   * @generated from field: string old_password = 1;
   */
  oldPassword = "";

  /**
   * @generated from field: string new_password = 2;
   */
  newPassword = "";

  /**
   * @generated from field: string mfa_verification_proof = 3;
   */
  mfaVerificationProof = "";

  constructor(data?: PartialMessage<UpdatePasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdatePasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "old_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mfa_verification_proof", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePasswordRequest {
    return new UpdatePasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePasswordRequest {
    return new UpdatePasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePasswordRequest {
    return new UpdatePasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePasswordRequest | PlainMessage<UpdatePasswordRequest> | undefined, b: UpdatePasswordRequest | PlainMessage<UpdatePasswordRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePasswordRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdatePasswordResponse
 */
export class UpdatePasswordResponse extends Message<UpdatePasswordResponse> {
  /**
   * @generated from field: web.public.UpdatePasswordResponse.Status status = 1;
   */
  status = UpdatePasswordResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdatePasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdatePasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdatePasswordResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePasswordResponse {
    return new UpdatePasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePasswordResponse {
    return new UpdatePasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePasswordResponse {
    return new UpdatePasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePasswordResponse | PlainMessage<UpdatePasswordResponse> | undefined, b: UpdatePasswordResponse | PlainMessage<UpdatePasswordResponse> | undefined): boolean {
    return proto3.util.equals(UpdatePasswordResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdatePasswordResponse.Status,
  };
}

export namespace UpdatePasswordResponse {
  /**
   * @generated from enum web.public.UpdatePasswordResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdatePasswordResponse.Status)
  proto3.util.setEnumType(UpdatePasswordResponse.Status, "web.public.UpdatePasswordResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.UpdateAccountEmailRequest
 */
export class UpdateAccountEmailRequest extends Message<UpdateAccountEmailRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<UpdateAccountEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateAccountEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountEmailRequest {
    return new UpdateAccountEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountEmailRequest {
    return new UpdateAccountEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountEmailRequest {
    return new UpdateAccountEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountEmailRequest | PlainMessage<UpdateAccountEmailRequest> | undefined, b: UpdateAccountEmailRequest | PlainMessage<UpdateAccountEmailRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccountEmailRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateAccountEmailResponse
 */
export class UpdateAccountEmailResponse extends Message<UpdateAccountEmailResponse> {
  /**
   * @generated from field: web.public.UpdateAccountEmailResponse.Status status = 1;
   */
  status = UpdateAccountEmailResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateAccountEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateAccountEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateAccountEmailResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountEmailResponse {
    return new UpdateAccountEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountEmailResponse {
    return new UpdateAccountEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountEmailResponse {
    return new UpdateAccountEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountEmailResponse | PlainMessage<UpdateAccountEmailResponse> | undefined, b: UpdateAccountEmailResponse | PlainMessage<UpdateAccountEmailResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAccountEmailResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateAccountEmailResponse.Status,
  };
}

export namespace UpdateAccountEmailResponse {
  /**
   * @generated from enum web.public.UpdateAccountEmailResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,

    /**
     * @generated from enum value: TAKEN = 3;
     */
    TAKEN = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateAccountEmailResponse.Status)
  proto3.util.setEnumType(UpdateAccountEmailResponse.Status, "web.public.UpdateAccountEmailResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
    { no: 3, name: "TAKEN" },
  ]);
}

/**
 * @generated from message web.public.UpdateAccountAddressRequest
 */
export class UpdateAccountAddressRequest extends Message<UpdateAccountAddressRequest> {
  /**
   * @generated from field: string password = 1;
   */
  password = "";

  /**
   * @generated from field: string address_line_1 = 2;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 3;
   */
  addressLine2 = "";

  /**
   * @generated from field: string city = 4;
   */
  city = "";

  /**
   * @generated from field: string state = 5;
   */
  state = "";

  /**
   * @generated from field: string zip = 6;
   */
  zip = "";

  constructor(data?: PartialMessage<UpdateAccountAddressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateAccountAddressRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountAddressRequest {
    return new UpdateAccountAddressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountAddressRequest {
    return new UpdateAccountAddressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountAddressRequest {
    return new UpdateAccountAddressRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountAddressRequest | PlainMessage<UpdateAccountAddressRequest> | undefined, b: UpdateAccountAddressRequest | PlainMessage<UpdateAccountAddressRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccountAddressRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateAccountAddressResponse
 */
export class UpdateAccountAddressResponse extends Message<UpdateAccountAddressResponse> {
  /**
   * @generated from field: web.public.UpdateAccountAddressResponse.Status status = 1;
   */
  status = UpdateAccountAddressResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateAccountAddressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateAccountAddressResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateAccountAddressResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountAddressResponse {
    return new UpdateAccountAddressResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountAddressResponse {
    return new UpdateAccountAddressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountAddressResponse {
    return new UpdateAccountAddressResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountAddressResponse | PlainMessage<UpdateAccountAddressResponse> | undefined, b: UpdateAccountAddressResponse | PlainMessage<UpdateAccountAddressResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAccountAddressResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateAccountAddressResponse.Status,
  };
}

export namespace UpdateAccountAddressResponse {
  /**
   * @generated from enum web.public.UpdateAccountAddressResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateAccountAddressResponse.Status)
  proto3.util.setEnumType(UpdateAccountAddressResponse.Status, "web.public.UpdateAccountAddressResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.UpdateShippingAddressRequest
 */
export class UpdateShippingAddressRequest extends Message<UpdateShippingAddressRequest> {
  /**
   * @generated from field: string address_line_1 = 1;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 2;
   */
  addressLine2 = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * @generated from field: string zip = 5;
   */
  zip = "";

  /**
   * @generated from field: string country = 6;
   */
  country = "";

  /**
   * @generated from field: string first_name = 7;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 8;
   */
  lastName = "";

  /**
   * @generated from field: string middle_name = 9;
   */
  middleName = "";

  constructor(data?: PartialMessage<UpdateShippingAddressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateShippingAddressRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateShippingAddressRequest {
    return new UpdateShippingAddressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateShippingAddressRequest {
    return new UpdateShippingAddressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateShippingAddressRequest {
    return new UpdateShippingAddressRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateShippingAddressRequest | PlainMessage<UpdateShippingAddressRequest> | undefined, b: UpdateShippingAddressRequest | PlainMessage<UpdateShippingAddressRequest> | undefined): boolean {
    return proto3.util.equals(UpdateShippingAddressRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateShippingAddressResponse
 */
export class UpdateShippingAddressResponse extends Message<UpdateShippingAddressResponse> {
  /**
   * @generated from field: web.public.UpdateShippingAddressResponse.Status status = 1;
   */
  status = UpdateShippingAddressResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateShippingAddressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateShippingAddressResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateShippingAddressResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateShippingAddressResponse {
    return new UpdateShippingAddressResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateShippingAddressResponse {
    return new UpdateShippingAddressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateShippingAddressResponse {
    return new UpdateShippingAddressResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateShippingAddressResponse | PlainMessage<UpdateShippingAddressResponse> | undefined, b: UpdateShippingAddressResponse | PlainMessage<UpdateShippingAddressResponse> | undefined): boolean {
    return proto3.util.equals(UpdateShippingAddressResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateShippingAddressResponse.Status,
  };
}

export namespace UpdateShippingAddressResponse {
  /**
   * @generated from enum web.public.UpdateShippingAddressResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateShippingAddressResponse.Status)
  proto3.util.setEnumType(UpdateShippingAddressResponse.Status, "web.public.UpdateShippingAddressResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.UpdateAccountNameRequest
 */
export class UpdateAccountNameRequest extends Message<UpdateAccountNameRequest> {
  /**
   * @generated from field: string password = 1;
   */
  password = "";

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string middle_name = 3;
   */
  middleName = "";

  /**
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  constructor(data?: PartialMessage<UpdateAccountNameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateAccountNameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountNameRequest {
    return new UpdateAccountNameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountNameRequest {
    return new UpdateAccountNameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountNameRequest {
    return new UpdateAccountNameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountNameRequest | PlainMessage<UpdateAccountNameRequest> | undefined, b: UpdateAccountNameRequest | PlainMessage<UpdateAccountNameRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccountNameRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdateAccountNameResponse
 */
export class UpdateAccountNameResponse extends Message<UpdateAccountNameResponse> {
  /**
   * @generated from field: web.public.UpdateAccountNameResponse.Status status = 1;
   */
  status = UpdateAccountNameResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateAccountNameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateAccountNameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateAccountNameResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountNameResponse {
    return new UpdateAccountNameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountNameResponse {
    return new UpdateAccountNameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountNameResponse {
    return new UpdateAccountNameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountNameResponse | PlainMessage<UpdateAccountNameResponse> | undefined, b: UpdateAccountNameResponse | PlainMessage<UpdateAccountNameResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAccountNameResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateAccountNameResponse.Status,
  };
}

export namespace UpdateAccountNameResponse {
  /**
   * @generated from enum web.public.UpdateAccountNameResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateAccountNameResponse.Status)
  proto3.util.setEnumType(UpdateAccountNameResponse.Status, "web.public.UpdateAccountNameResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetAddressCorrectionRequest
 */
export class GetAddressCorrectionRequest extends Message<GetAddressCorrectionRequest> {
  /**
   * @generated from field: string address_line_1 = 1;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 2;
   */
  addressLine2 = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * @generated from field: string zip = 5;
   */
  zip = "";

  constructor(data?: PartialMessage<GetAddressCorrectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetAddressCorrectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAddressCorrectionRequest {
    return new GetAddressCorrectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAddressCorrectionRequest {
    return new GetAddressCorrectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAddressCorrectionRequest {
    return new GetAddressCorrectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAddressCorrectionRequest | PlainMessage<GetAddressCorrectionRequest> | undefined, b: GetAddressCorrectionRequest | PlainMessage<GetAddressCorrectionRequest> | undefined): boolean {
    return proto3.util.equals(GetAddressCorrectionRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetAddressCorrectionResponse
 */
export class GetAddressCorrectionResponse extends Message<GetAddressCorrectionResponse> {
  /**
   * @generated from field: web.public.GetAddressCorrectionResponse.Status status = 1;
   */
  status = GetAddressCorrectionResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string address_line_1 = 3;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 4;
   */
  addressLine2 = "";

  /**
   * @generated from field: string city = 5;
   */
  city = "";

  /**
   * @generated from field: string state = 6;
   */
  state = "";

  /**
   * @generated from field: string zip4 = 7;
   */
  zip4 = "";

  /**
   * @generated from field: string zip5 = 8;
   */
  zip5 = "";

  /**
   * @generated from field: string message = 9;
   */
  message = "";

  constructor(data?: PartialMessage<GetAddressCorrectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetAddressCorrectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetAddressCorrectionResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "zip4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "zip5", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAddressCorrectionResponse {
    return new GetAddressCorrectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAddressCorrectionResponse {
    return new GetAddressCorrectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAddressCorrectionResponse {
    return new GetAddressCorrectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAddressCorrectionResponse | PlainMessage<GetAddressCorrectionResponse> | undefined, b: GetAddressCorrectionResponse | PlainMessage<GetAddressCorrectionResponse> | undefined): boolean {
    return proto3.util.equals(GetAddressCorrectionResponse, a, b);
  }

  static _enums: {
    Status: typeof GetAddressCorrectionResponse.Status,
  };
}

export namespace GetAddressCorrectionResponse {
  /**
   * @generated from enum web.public.GetAddressCorrectionResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetAddressCorrectionResponse.Status)
  proto3.util.setEnumType(GetAddressCorrectionResponse.Status, "web.public.GetAddressCorrectionResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetPhoneValidationV2Request
 */
export class GetPhoneValidationV2Request extends Message<GetPhoneValidationV2Request> {
  /**
   * @generated from field: string phone = 1;
   */
  phone = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<GetPhoneValidationV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPhoneValidationV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneValidationV2Request {
    return new GetPhoneValidationV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneValidationV2Request {
    return new GetPhoneValidationV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneValidationV2Request {
    return new GetPhoneValidationV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneValidationV2Request | PlainMessage<GetPhoneValidationV2Request> | undefined, b: GetPhoneValidationV2Request | PlainMessage<GetPhoneValidationV2Request> | undefined): boolean {
    return proto3.util.equals(GetPhoneValidationV2Request, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetPhoneValidationV2Response
 */
export class GetPhoneValidationV2Response extends Message<GetPhoneValidationV2Response> {
  /**
   * @generated from field: web.public.GetPhoneValidationV2Response.Status status = 1;
   */
  status = GetPhoneValidationV2Response.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<GetPhoneValidationV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPhoneValidationV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetPhoneValidationV2Response.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneValidationV2Response {
    return new GetPhoneValidationV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneValidationV2Response {
    return new GetPhoneValidationV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneValidationV2Response {
    return new GetPhoneValidationV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneValidationV2Response | PlainMessage<GetPhoneValidationV2Response> | undefined, b: GetPhoneValidationV2Response | PlainMessage<GetPhoneValidationV2Response> | undefined): boolean {
    return proto3.util.equals(GetPhoneValidationV2Response, a, b);
  }

  static _enums: {
    Status: typeof GetPhoneValidationV2Response.Status,
  };
}

export namespace GetPhoneValidationV2Response {
  /**
   * @generated from enum web.public.GetPhoneValidationV2Response.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: TAKEN = 1;
     */
    TAKEN = 1,

    /**
     * @generated from enum value: WAITING = 2;
     */
    WAITING = 2,

    /**
     * @generated from enum value: INVALID = 3;
     */
    INVALID = 3,

    /**
     * @generated from enum value: VALID = 4;
     */
    VALID = 4,

    /**
     * @generated from enum value: FAILED = 5;
     */
    FAILED = 5,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetPhoneValidationV2Response.Status)
  proto3.util.setEnumType(GetPhoneValidationV2Response.Status, "web.public.GetPhoneValidationV2Response.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "TAKEN" },
    { no: 2, name: "WAITING" },
    { no: 3, name: "INVALID" },
    { no: 4, name: "VALID" },
    { no: 5, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.GetPhoneCodeV2Request
 */
export class GetPhoneCodeV2Request extends Message<GetPhoneCodeV2Request> {
  /**
   * @generated from field: string phone = 1;
   */
  phone = "";

  constructor(data?: PartialMessage<GetPhoneCodeV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPhoneCodeV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneCodeV2Request {
    return new GetPhoneCodeV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneCodeV2Request {
    return new GetPhoneCodeV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneCodeV2Request {
    return new GetPhoneCodeV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneCodeV2Request | PlainMessage<GetPhoneCodeV2Request> | undefined, b: GetPhoneCodeV2Request | PlainMessage<GetPhoneCodeV2Request> | undefined): boolean {
    return proto3.util.equals(GetPhoneCodeV2Request, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetPhoneCodeV2Response
 */
export class GetPhoneCodeV2Response extends Message<GetPhoneCodeV2Response> {
  /**
   * @generated from field: web.public.GetPhoneCodeV2Response.Status status = 1;
   */
  status = GetPhoneCodeV2Response.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<GetPhoneCodeV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPhoneCodeV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetPhoneCodeV2Response.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneCodeV2Response {
    return new GetPhoneCodeV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneCodeV2Response {
    return new GetPhoneCodeV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneCodeV2Response {
    return new GetPhoneCodeV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneCodeV2Response | PlainMessage<GetPhoneCodeV2Response> | undefined, b: GetPhoneCodeV2Response | PlainMessage<GetPhoneCodeV2Response> | undefined): boolean {
    return proto3.util.equals(GetPhoneCodeV2Response, a, b);
  }

  static _enums: {
    Status: typeof GetPhoneCodeV2Response.Status,
  };
}

export namespace GetPhoneCodeV2Response {
  /**
   * @generated from enum web.public.GetPhoneCodeV2Response.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetPhoneCodeV2Response.Status)
  proto3.util.setEnumType(GetPhoneCodeV2Response.Status, "web.public.GetPhoneCodeV2Response.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.UpdatePhoneV2Request
 */
export class UpdatePhoneV2Request extends Message<UpdatePhoneV2Request> {
  /**
   * @generated from field: string phone = 1;
   */
  phone = "";

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  constructor(data?: PartialMessage<UpdatePhoneV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdatePhoneV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePhoneV2Request {
    return new UpdatePhoneV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePhoneV2Request {
    return new UpdatePhoneV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePhoneV2Request {
    return new UpdatePhoneV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePhoneV2Request | PlainMessage<UpdatePhoneV2Request> | undefined, b: UpdatePhoneV2Request | PlainMessage<UpdatePhoneV2Request> | undefined): boolean {
    return proto3.util.equals(UpdatePhoneV2Request, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UpdatePhoneV2Response
 */
export class UpdatePhoneV2Response extends Message<UpdatePhoneV2Response> {
  /**
   * @generated from field: web.public.UpdatePhoneV2Response.Status status = 1;
   */
  status = UpdatePhoneV2Response.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdatePhoneV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdatePhoneV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdatePhoneV2Response.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePhoneV2Response {
    return new UpdatePhoneV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePhoneV2Response {
    return new UpdatePhoneV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePhoneV2Response {
    return new UpdatePhoneV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePhoneV2Response | PlainMessage<UpdatePhoneV2Response> | undefined, b: UpdatePhoneV2Response | PlainMessage<UpdatePhoneV2Response> | undefined): boolean {
    return proto3.util.equals(UpdatePhoneV2Response, a, b);
  }

  static _enums: {
    Status: typeof UpdatePhoneV2Response.Status,
  };
}

export namespace UpdatePhoneV2Response {
  /**
   * @generated from enum web.public.UpdatePhoneV2Response.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdatePhoneV2Response.Status)
  proto3.util.setEnumType(UpdatePhoneV2Response.Status, "web.public.UpdatePhoneV2Response.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.Dismissable
 */
export class Dismissable extends Message<Dismissable> {
  /**
   * @generated from field: web.public.Dismissable.Name name = 1;
   */
  name = Dismissable.Name.FREEMIUM_INTRO;

  /**
   * @generated from field: optional string id = 2;
   */
  id?: string;

  constructor(data?: PartialMessage<Dismissable>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Dismissable";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "enum", T: proto3.getEnumType(Dismissable.Name) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Dismissable {
    return new Dismissable().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Dismissable {
    return new Dismissable().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Dismissable {
    return new Dismissable().fromJsonString(jsonString, options);
  }

  static equals(a: Dismissable | PlainMessage<Dismissable> | undefined, b: Dismissable | PlainMessage<Dismissable> | undefined): boolean {
    return proto3.util.equals(Dismissable, a, b);
  }

  static _enums: {
    Name: typeof Dismissable.Name,
  };
}

export namespace Dismissable {
  /**
   * @generated from enum web.public.Dismissable.Name
   */
  export enum Name {
    /**
     * Credit
     *
     * @generated from enum value: FREEMIUM_INTRO = 0;
     */
    FREEMIUM_INTRO = 0,

    /**
     * @generated from enum value: COACH_SURVEY = 1;
     */
    COACH_SURVEY = 1,

    /**
     * @generated from enum value: DEBT_RESOLUTION_SURVEY = 2;
     */
    DEBT_RESOLUTION_SURVEY = 2,

    /**
     * Payment methods
     *
     * @generated from enum value: MUST_ACCEPT_ACH_AGREEMENT = 3;
     */
    MUST_ACCEPT_ACH_AGREEMENT = 3,

    /**
     * @generated from enum value: WEB_SECURED_CARD_ONBOARDED = 4;
     */
    WEB_SECURED_CARD_ONBOARDED = 4,

    /**
     * @generated from enum value: AUTOPAY_TERMS = 5;
     */
    AUTOPAY_TERMS = 5,

    /**
     * @generated from enum value: SCHEDULE_CA_AUTOPAY_PROMPT = 6;
     */
    SCHEDULE_CA_AUTOPAY_PROMPT = 6,

    /**
     * @generated from enum value: SCHEDULE_CBL_AUTOPAY_PROMPT = 7;
     */
    SCHEDULE_CBL_AUTOPAY_PROMPT = 7,

    /**
     * Dashboard
     *
     * @generated from enum value: PREMIUM_REPORT_AVAILABLE = 8;
     */
    PREMIUM_REPORT_AVAILABLE = 8,

    /**
     * Credit
     *
     * @generated from enum value: DEBT_ACCOUNT_PROMPT = 9;
     */
    DEBT_ACCOUNT_PROMPT = 9,

    /**
     * @generated from enum value: DEBT_SETTLEMENT_LETTER = 10;
     */
    DEBT_SETTLEMENT_LETTER = 10,

    /**
     * @generated from enum value: UPSELL_CREDIT_LINE_PROMO = 11;
     */
    UPSELL_CREDIT_LINE_PROMO = 11,

    /**
     * @generated from enum value: CL_PAYMENT_ACHIEVEMENT = 12;
     */
    CL_PAYMENT_ACHIEVEMENT = 12,

    /**
     * @generated from enum value: UPSELL_VIRTUAL_CARD = 13;
     */
    UPSELL_VIRTUAL_CARD = 13,

    /**
     * @generated from enum value: DEBT_PAID = 14;
     */
    DEBT_PAID = 14,

    /**
     * @generated from enum value: CONTINUE_DISPUTE = 15;
     */
    CONTINUE_DISPUTE = 15,

    /**
     * Credit
     *
     * @generated from enum value: CREDIT_EVALUATION_PROMPT = 16;
     */
    CREDIT_EVALUATION_PROMPT = 16,

    /**
     * Dashboard
     *
     * @generated from enum value: HELLO_PRIVACY_CTA = 17;
     */
    HELLO_PRIVACY_CTA = 17,

    /**
     * Credit
     *
     * @generated from enum value: DISPUTES_DRAWER = 18;
     */
    DISPUTES_DRAWER = 18,

    /**
     * Dashboard
     *
     * @generated from enum value: CREDIT_CARD_USAGE_CARD = 19;
     */
    CREDIT_CARD_USAGE_CARD = 19,

    /**
     * @generated from enum value: CREDIT_SCORE_CHANGE = 20;
     */
    CREDIT_SCORE_CHANGE = 20,

    /**
     * Recommendations
     *
     * @generated from enum value: RECOMMENDATIONS_DEBT_RELIEF_ELIGIBLE_CARD = 21;
     */
    RECOMMENDATIONS_DEBT_RELIEF_ELIGIBLE_CARD = 21,

    /**
     * @generated from enum value: RECOMMENDATIONS_DEBT_RELIEF_ELIGIBLE_PAGE = 22;
     */
    RECOMMENDATIONS_DEBT_RELIEF_ELIGIBLE_PAGE = 22,

    /**
     * @generated from enum value: RECOMMENDATIONS_DEBT_RELIEF_INELIGIBLE_CARD = 23;
     */
    RECOMMENDATIONS_DEBT_RELIEF_INELIGIBLE_CARD = 23,

    /**
     * @generated from enum value: RECOMMENDATIONS_DEBT_RELIEF_INELIGIBLE_PAGE = 24;
     */
    RECOMMENDATIONS_DEBT_RELIEF_INELIGIBLE_PAGE = 24,

    /**
     * @generated from enum value: RECOMMENDATIONS_DEBT_RELIEF_TIME_BARRED_CARD = 25;
     */
    RECOMMENDATIONS_DEBT_RELIEF_TIME_BARRED_CARD = 25,

    /**
     * @generated from enum value: RECOMMENDATIONS_DEBT_RELIEF_TIME_BARRED_PAGE = 26;
     */
    RECOMMENDATIONS_DEBT_RELIEF_TIME_BARRED_PAGE = 26,

    /**
     * @generated from enum value: RECOMMENDATIONS_DISPUTES_LATE_PAYMENT_CARD = 28;
     */
    RECOMMENDATIONS_DISPUTES_LATE_PAYMENT_CARD = 28,

    /**
     * @generated from enum value: RECOMMENDATIONS_DISPUTES_LATE_PAYMENT_PAGE = 29;
     */
    RECOMMENDATIONS_DISPUTES_LATE_PAYMENT_PAGE = 29,

    /**
     * @generated from enum value: RECOMMENDATIONS_DISPUTES_OLD_CARD = 30;
     */
    RECOMMENDATIONS_DISPUTES_OLD_CARD = 30,

    /**
     * @generated from enum value: RECOMMENDATIONS_DISPUTES_OLD_PAGE = 31;
     */
    RECOMMENDATIONS_DISPUTES_OLD_PAGE = 31,

    /**
     * @generated from enum value: RECOMMENDATIONS_DISPUTES_REDISPUTE_CARD = 32;
     */
    RECOMMENDATIONS_DISPUTES_REDISPUTE_CARD = 32,

    /**
     * @generated from enum value: RECOMMENDATIONS_DISPUTES_REDISPUTE_PAGE = 33;
     */
    RECOMMENDATIONS_DISPUTES_REDISPUTE_PAGE = 33,

    /**
     * Credit Changes
     *
     * @generated from enum value: CREDIT_CHANGES_SURVEY = 27;
     */
    CREDIT_CHANGES_SURVEY = 27,

    /**
     * Dashboard
     *
     * @generated from enum value: ID_THEFT_INSURANCE_UPSELL = 34;
     */
    ID_THEFT_INSURANCE_UPSELL = 34,

    /**
     * Community
     *
     * @generated from enum value: VIEW_COMMUNITY = 35;
     */
    VIEW_COMMUNITY = 35,

    /**
     * Authorized User
     *
     * @generated from enum value: ADD_AUTHORIZED_USER = 36;
     */
    ADD_AUTHORIZED_USER = 36,

    /**
     * @generated from enum value: ATTRIBUTION_SURVEY = 38;
     */
    ATTRIBUTION_SURVEY = 38,

    /**
     * Dispute Upsell
     *
     * @generated from enum value: DISPUTE_UPSELL_CARD = 39;
     */
    DISPUTE_UPSELL_CARD = 39,

    /**
     * Recommendations Payment
     *
     * @generated from enum value: RECOMMENDATIONS_CA_PAYMENT_30_OVERDUE_CARD = 40;
     */
    RECOMMENDATIONS_CA_PAYMENT_30_OVERDUE_CARD = 40,

    /**
     * @generated from enum value: RECOMMENDATIONS_CA_PAYMENT_30_OVERDUE_PAGE = 41;
     */
    RECOMMENDATIONS_CA_PAYMENT_30_OVERDUE_PAGE = 41,

    /**
     * @generated from enum value: RECOMMENDATIONS_CA_PAYMENT_90_OVERDUE_CARD = 42;
     */
    RECOMMENDATIONS_CA_PAYMENT_90_OVERDUE_CARD = 42,

    /**
     * @generated from enum value: RECOMMENDATIONS_CA_PAYMENT_90_OVERDUE_PAGE = 43;
     */
    RECOMMENDATIONS_CA_PAYMENT_90_OVERDUE_PAGE = 43,

    /**
     * @generated from enum value: RECOMMENDATIONS_PAYMENT_90_OVERDUE_CARD = 44;
     */
    RECOMMENDATIONS_PAYMENT_90_OVERDUE_CARD = 44,

    /**
     * @generated from enum value: RECOMMENDATIONS_PAYMENT_90_OVERDUE_PAGE = 45;
     */
    RECOMMENDATIONS_PAYMENT_90_OVERDUE_PAGE = 45,

    /**
     * @generated from enum value: RECOMMENDATIONS_PAYMENT_120_OVERDUE_CARD = 46;
     */
    RECOMMENDATIONS_PAYMENT_120_OVERDUE_CARD = 46,

    /**
     * @generated from enum value: RECOMMENDATIONS_PAYMENT_120_OVERDUE_PAGE = 47;
     */
    RECOMMENDATIONS_PAYMENT_120_OVERDUE_PAGE = 47,

    /**
     * Credit insights
     *
     * @generated from enum value: CREDIT_INSIGHTS_SURVEY = 37;
     */
    CREDIT_INSIGHTS_SURVEY = 37,

    /**
     * @generated from enum value: NEW_TRADELINE_PAGE = 48;
     */
    NEW_TRADELINE_PAGE = 48,

    /**
     * @generated from enum value: INITIAL_SCORE_BUMP_PAGE = 49;
     */
    INITIAL_SCORE_BUMP_PAGE = 49,

    /**
     * @generated from enum value: FURNISHING_PAGE = 50;
     */
    FURNISHING_PAGE = 50,

    /**
     * @generated from enum value: PROBLEM_ACCOUNTS_PAGE = 51;
     */
    PROBLEM_ACCOUNTS_PAGE = 51,

    /**
     * Bill Negotiation
     *
     * @generated from enum value: BILL_NEGOTIATION_PAINTED_DOOR_DEBT = 52;
     */
    BILL_NEGOTIATION_PAINTED_DOOR_DEBT = 52,

    /**
     * @generated from enum value: BILL_NEGOTIATION_PAINTED_DOOR_MEDICAL = 53;
     */
    BILL_NEGOTIATION_PAINTED_DOOR_MEDICAL = 53,

    /**
     * @generated from enum value: BILL_NEGOTIATION_PAINTED_DOOR_MONTHLY = 54;
     */
    BILL_NEGOTIATION_PAINTED_DOOR_MONTHLY = 54,

    /**
     * Recommendations Tradeline
     *
     * @generated from enum value: RECOMMENDATIONS_CBL_LOW_SCORE_CARD = 55;
     */
    RECOMMENDATIONS_CBL_LOW_SCORE_CARD = 55,

    /**
     * @generated from enum value: RECOMMENDATIONS_CBL_HIGH_SCORE_CARD = 56;
     */
    RECOMMENDATIONS_CBL_HIGH_SCORE_CARD = 56,

    /**
     * @generated from enum value: RECOMMENDATIONS_RR_LOW_SCORE_CARD = 57;
     */
    RECOMMENDATIONS_RR_LOW_SCORE_CARD = 57,

    /**
     * @generated from enum value: RECOMMENDATIONS_RR_HIGH_SCORE_CARD = 58;
     */
    RECOMMENDATIONS_RR_HIGH_SCORE_CARD = 58,

    /**
     * @generated from enum value: RECOMMENDATIONS_HELLO_PRIVACY_CARD = 59;
     */
    RECOMMENDATIONS_HELLO_PRIVACY_CARD = 59,

    /**
     * @generated from enum value: CONTINUE_DISPUTE_DRAWER = 60;
     */
    CONTINUE_DISPUTE_DRAWER = 60,

    /**
     * @generated from enum value: DISPUTE_RESPONDED_DRAWER = 61 [deprecated = true];
     * @deprecated
     */
    DISPUTE_RESPONDED_DRAWER = 61,

    /**
     * @generated from enum value: DISPUTE_UPDATED_DRAWER = 62;
     */
    DISPUTE_UPDATED_DRAWER = 62,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Dismissable.Name)
  proto3.util.setEnumType(Dismissable.Name, "web.public.Dismissable.Name", [
    { no: 0, name: "FREEMIUM_INTRO" },
    { no: 1, name: "COACH_SURVEY" },
    { no: 2, name: "DEBT_RESOLUTION_SURVEY" },
    { no: 3, name: "MUST_ACCEPT_ACH_AGREEMENT" },
    { no: 4, name: "WEB_SECURED_CARD_ONBOARDED" },
    { no: 5, name: "AUTOPAY_TERMS" },
    { no: 6, name: "SCHEDULE_CA_AUTOPAY_PROMPT" },
    { no: 7, name: "SCHEDULE_CBL_AUTOPAY_PROMPT" },
    { no: 8, name: "PREMIUM_REPORT_AVAILABLE" },
    { no: 9, name: "DEBT_ACCOUNT_PROMPT" },
    { no: 10, name: "DEBT_SETTLEMENT_LETTER" },
    { no: 11, name: "UPSELL_CREDIT_LINE_PROMO" },
    { no: 12, name: "CL_PAYMENT_ACHIEVEMENT" },
    { no: 13, name: "UPSELL_VIRTUAL_CARD" },
    { no: 14, name: "DEBT_PAID" },
    { no: 15, name: "CONTINUE_DISPUTE" },
    { no: 16, name: "CREDIT_EVALUATION_PROMPT" },
    { no: 17, name: "HELLO_PRIVACY_CTA" },
    { no: 18, name: "DISPUTES_DRAWER" },
    { no: 19, name: "CREDIT_CARD_USAGE_CARD" },
    { no: 20, name: "CREDIT_SCORE_CHANGE" },
    { no: 21, name: "RECOMMENDATIONS_DEBT_RELIEF_ELIGIBLE_CARD" },
    { no: 22, name: "RECOMMENDATIONS_DEBT_RELIEF_ELIGIBLE_PAGE" },
    { no: 23, name: "RECOMMENDATIONS_DEBT_RELIEF_INELIGIBLE_CARD" },
    { no: 24, name: "RECOMMENDATIONS_DEBT_RELIEF_INELIGIBLE_PAGE" },
    { no: 25, name: "RECOMMENDATIONS_DEBT_RELIEF_TIME_BARRED_CARD" },
    { no: 26, name: "RECOMMENDATIONS_DEBT_RELIEF_TIME_BARRED_PAGE" },
    { no: 28, name: "RECOMMENDATIONS_DISPUTES_LATE_PAYMENT_CARD" },
    { no: 29, name: "RECOMMENDATIONS_DISPUTES_LATE_PAYMENT_PAGE" },
    { no: 30, name: "RECOMMENDATIONS_DISPUTES_OLD_CARD" },
    { no: 31, name: "RECOMMENDATIONS_DISPUTES_OLD_PAGE" },
    { no: 32, name: "RECOMMENDATIONS_DISPUTES_REDISPUTE_CARD" },
    { no: 33, name: "RECOMMENDATIONS_DISPUTES_REDISPUTE_PAGE" },
    { no: 27, name: "CREDIT_CHANGES_SURVEY" },
    { no: 34, name: "ID_THEFT_INSURANCE_UPSELL" },
    { no: 35, name: "VIEW_COMMUNITY" },
    { no: 36, name: "ADD_AUTHORIZED_USER" },
    { no: 38, name: "ATTRIBUTION_SURVEY" },
    { no: 39, name: "DISPUTE_UPSELL_CARD" },
    { no: 40, name: "RECOMMENDATIONS_CA_PAYMENT_30_OVERDUE_CARD" },
    { no: 41, name: "RECOMMENDATIONS_CA_PAYMENT_30_OVERDUE_PAGE" },
    { no: 42, name: "RECOMMENDATIONS_CA_PAYMENT_90_OVERDUE_CARD" },
    { no: 43, name: "RECOMMENDATIONS_CA_PAYMENT_90_OVERDUE_PAGE" },
    { no: 44, name: "RECOMMENDATIONS_PAYMENT_90_OVERDUE_CARD" },
    { no: 45, name: "RECOMMENDATIONS_PAYMENT_90_OVERDUE_PAGE" },
    { no: 46, name: "RECOMMENDATIONS_PAYMENT_120_OVERDUE_CARD" },
    { no: 47, name: "RECOMMENDATIONS_PAYMENT_120_OVERDUE_PAGE" },
    { no: 37, name: "CREDIT_INSIGHTS_SURVEY" },
    { no: 48, name: "NEW_TRADELINE_PAGE" },
    { no: 49, name: "INITIAL_SCORE_BUMP_PAGE" },
    { no: 50, name: "FURNISHING_PAGE" },
    { no: 51, name: "PROBLEM_ACCOUNTS_PAGE" },
    { no: 52, name: "BILL_NEGOTIATION_PAINTED_DOOR_DEBT" },
    { no: 53, name: "BILL_NEGOTIATION_PAINTED_DOOR_MEDICAL" },
    { no: 54, name: "BILL_NEGOTIATION_PAINTED_DOOR_MONTHLY" },
    { no: 55, name: "RECOMMENDATIONS_CBL_LOW_SCORE_CARD" },
    { no: 56, name: "RECOMMENDATIONS_CBL_HIGH_SCORE_CARD" },
    { no: 57, name: "RECOMMENDATIONS_RR_LOW_SCORE_CARD" },
    { no: 58, name: "RECOMMENDATIONS_RR_HIGH_SCORE_CARD" },
    { no: 59, name: "RECOMMENDATIONS_HELLO_PRIVACY_CARD" },
    { no: 60, name: "CONTINUE_DISPUTE_DRAWER" },
    { no: 61, name: "DISPUTE_RESPONDED_DRAWER" },
    { no: 62, name: "DISPUTE_UPDATED_DRAWER" },
  ]);
}

/**
 * @generated from message web.public.DismissRequest
 */
export class DismissRequest extends Message<DismissRequest> {
  /**
   * @generated from field: web.public.Dismissable.Name key_old = 1 [deprecated = true];
   * @deprecated
   */
  keyOld = Dismissable.Name.FREEMIUM_INTRO;

  /**
   * @generated from field: web.public.Dismissable dismissable = 4;
   */
  dismissable?: Dismissable;

  constructor(data?: PartialMessage<DismissRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DismissRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key_old", kind: "enum", T: proto3.getEnumType(Dismissable.Name) },
    { no: 4, name: "dismissable", kind: "message", T: Dismissable },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DismissRequest {
    return new DismissRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DismissRequest {
    return new DismissRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DismissRequest {
    return new DismissRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DismissRequest | PlainMessage<DismissRequest> | undefined, b: DismissRequest | PlainMessage<DismissRequest> | undefined): boolean {
    return proto3.util.equals(DismissRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.DismissResponse
 */
export class DismissResponse extends Message<DismissResponse> {
  /**
   * @generated from field: web.public.DismissResponse.Status status = 1;
   */
  status = DismissResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<DismissResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DismissResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(DismissResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DismissResponse {
    return new DismissResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DismissResponse {
    return new DismissResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DismissResponse {
    return new DismissResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DismissResponse | PlainMessage<DismissResponse> | undefined, b: DismissResponse | PlainMessage<DismissResponse> | undefined): boolean {
    return proto3.util.equals(DismissResponse, a, b);
  }

  static _enums: {
    Status: typeof DismissResponse.Status,
  };
}

export namespace DismissResponse {
  /**
   * @generated from enum web.public.DismissResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(DismissResponse.Status)
  proto3.util.setEnumType(DismissResponse.Status, "web.public.DismissResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.BrazeEvent
 */
export class BrazeEvent extends Message<BrazeEvent> {
  /**
   * @generated from field: web.public.BrazeEvent.Name name = 1;
   */
  name = BrazeEvent.Name.VIEW_DEBT_LANDING_PAGE;

  constructor(data?: PartialMessage<BrazeEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.BrazeEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "enum", T: proto3.getEnumType(BrazeEvent.Name) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrazeEvent {
    return new BrazeEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrazeEvent {
    return new BrazeEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrazeEvent {
    return new BrazeEvent().fromJsonString(jsonString, options);
  }

  static equals(a: BrazeEvent | PlainMessage<BrazeEvent> | undefined, b: BrazeEvent | PlainMessage<BrazeEvent> | undefined): boolean {
    return proto3.util.equals(BrazeEvent, a, b);
  }

  static _enums: {
    Name: typeof BrazeEvent.Name,
  };
}

export namespace BrazeEvent {
  /**
   * @generated from enum web.public.BrazeEvent.Name
   */
  export enum Name {
    /**
     * @generated from enum value: VIEW_DEBT_LANDING_PAGE = 0;
     */
    VIEW_DEBT_LANDING_PAGE = 0,

    /**
     * @generated from enum value: CLICK_RECOMMENDATIONS_CARD_PRIMARY_CTA = 1;
     */
    CLICK_RECOMMENDATIONS_CARD_PRIMARY_CTA = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(BrazeEvent.Name)
  proto3.util.setEnumType(BrazeEvent.Name, "web.public.BrazeEvent.Name", [
    { no: 0, name: "VIEW_DEBT_LANDING_PAGE" },
    { no: 1, name: "CLICK_RECOMMENDATIONS_CARD_PRIMARY_CTA" },
  ]);
}

/**
 * @generated from message web.public.TriggerBrazeEventRequest
 */
export class TriggerBrazeEventRequest extends Message<TriggerBrazeEventRequest> {
  /**
   * @generated from field: web.public.BrazeEvent braze_event = 1;
   */
  brazeEvent?: BrazeEvent;

  constructor(data?: PartialMessage<TriggerBrazeEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TriggerBrazeEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "braze_event", kind: "message", T: BrazeEvent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerBrazeEventRequest {
    return new TriggerBrazeEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerBrazeEventRequest {
    return new TriggerBrazeEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerBrazeEventRequest {
    return new TriggerBrazeEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerBrazeEventRequest | PlainMessage<TriggerBrazeEventRequest> | undefined, b: TriggerBrazeEventRequest | PlainMessage<TriggerBrazeEventRequest> | undefined): boolean {
    return proto3.util.equals(TriggerBrazeEventRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.TriggerBrazeEventResponse
 */
export class TriggerBrazeEventResponse extends Message<TriggerBrazeEventResponse> {
  /**
   * @generated from field: web.public.TriggerBrazeEventResponse.Status status = 1;
   */
  status = TriggerBrazeEventResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<TriggerBrazeEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TriggerBrazeEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(TriggerBrazeEventResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerBrazeEventResponse {
    return new TriggerBrazeEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerBrazeEventResponse {
    return new TriggerBrazeEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerBrazeEventResponse {
    return new TriggerBrazeEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerBrazeEventResponse | PlainMessage<TriggerBrazeEventResponse> | undefined, b: TriggerBrazeEventResponse | PlainMessage<TriggerBrazeEventResponse> | undefined): boolean {
    return proto3.util.equals(TriggerBrazeEventResponse, a, b);
  }

  static _enums: {
    Status: typeof TriggerBrazeEventResponse.Status,
  };
}

export namespace TriggerBrazeEventResponse {
  /**
   * @generated from enum web.public.TriggerBrazeEventResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(TriggerBrazeEventResponse.Status)
  proto3.util.setEnumType(TriggerBrazeEventResponse.Status, "web.public.TriggerBrazeEventResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetSiteVarsRequest
 */
export class GetSiteVarsRequest extends Message<GetSiteVarsRequest> {
  /**
   * experiment hints
   *
   * @generated from field: repeated web.public.Experiment experiments = 1 [deprecated = true];
   * @deprecated
   */
  experiments: Experiment[] = [];

  constructor(data?: PartialMessage<GetSiteVarsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetSiteVarsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "experiments", kind: "message", T: Experiment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSiteVarsRequest {
    return new GetSiteVarsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSiteVarsRequest {
    return new GetSiteVarsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSiteVarsRequest {
    return new GetSiteVarsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSiteVarsRequest | PlainMessage<GetSiteVarsRequest> | undefined, b: GetSiteVarsRequest | PlainMessage<GetSiteVarsRequest> | undefined): boolean {
    return proto3.util.equals(GetSiteVarsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetSiteVarsResponse
 */
export class GetSiteVarsResponse extends Message<GetSiteVarsResponse> {
  /**
   * @generated from field: repeated web.public.FeatureFlag feature_flags = 1;
   */
  featureFlags: FeatureFlag[] = [];

  /**
   * @generated from field: repeated web.public.Experiment experiments = 2;
   */
  experiments: Experiment[] = [];

  /**
   * @generated from field: repeated web.public.Dismissable.Name dismissed_old = 3 [deprecated = true];
   * @deprecated
   */
  dismissedOld: Dismissable.Name[] = [];

  /**
   * @generated from field: repeated web.public.Dismissable dismissed = 4;
   */
  dismissed: Dismissable[] = [];

  constructor(data?: PartialMessage<GetSiteVarsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetSiteVarsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "feature_flags", kind: "message", T: FeatureFlag, repeated: true },
    { no: 2, name: "experiments", kind: "message", T: Experiment, repeated: true },
    { no: 3, name: "dismissed_old", kind: "enum", T: proto3.getEnumType(Dismissable.Name), repeated: true },
    { no: 4, name: "dismissed", kind: "message", T: Dismissable, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSiteVarsResponse {
    return new GetSiteVarsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSiteVarsResponse {
    return new GetSiteVarsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSiteVarsResponse {
    return new GetSiteVarsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSiteVarsResponse | PlainMessage<GetSiteVarsResponse> | undefined, b: GetSiteVarsResponse | PlainMessage<GetSiteVarsResponse> | undefined): boolean {
    return proto3.util.equals(GetSiteVarsResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ResendConfirmationEmailRequest
 */
export class ResendConfirmationEmailRequest extends Message<ResendConfirmationEmailRequest> {
  constructor(data?: PartialMessage<ResendConfirmationEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ResendConfirmationEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResendConfirmationEmailRequest {
    return new ResendConfirmationEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResendConfirmationEmailRequest {
    return new ResendConfirmationEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResendConfirmationEmailRequest {
    return new ResendConfirmationEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResendConfirmationEmailRequest | PlainMessage<ResendConfirmationEmailRequest> | undefined, b: ResendConfirmationEmailRequest | PlainMessage<ResendConfirmationEmailRequest> | undefined): boolean {
    return proto3.util.equals(ResendConfirmationEmailRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ResendConfirmationEmailResponse
 */
export class ResendConfirmationEmailResponse extends Message<ResendConfirmationEmailResponse> {
  /**
   * @generated from field: web.public.ResendConfirmationEmailResponse.Status status = 1;
   */
  status = ResendConfirmationEmailResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<ResendConfirmationEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ResendConfirmationEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ResendConfirmationEmailResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResendConfirmationEmailResponse {
    return new ResendConfirmationEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResendConfirmationEmailResponse {
    return new ResendConfirmationEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResendConfirmationEmailResponse {
    return new ResendConfirmationEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResendConfirmationEmailResponse | PlainMessage<ResendConfirmationEmailResponse> | undefined, b: ResendConfirmationEmailResponse | PlainMessage<ResendConfirmationEmailResponse> | undefined): boolean {
    return proto3.util.equals(ResendConfirmationEmailResponse, a, b);
  }

  static _enums: {
    Status: typeof ResendConfirmationEmailResponse.Status,
  };
}

export namespace ResendConfirmationEmailResponse {
  /**
   * @generated from enum web.public.ResendConfirmationEmailResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ResendConfirmationEmailResponse.Status)
  proto3.util.setEnumType(ResendConfirmationEmailResponse.Status, "web.public.ResendConfirmationEmailResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetAddressChangeStatusResponse
 */
export class GetAddressChangeStatusResponse extends Message<GetAddressChangeStatusResponse> {
  /**
   * @generated from field: web.public.GetAddressChangeStatusResponse.Status status = 1;
   */
  status = GetAddressChangeStatusResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.PersonalInfo requested_changes = 3;
   */
  requestedChanges?: PersonalInfo;

  constructor(data?: PartialMessage<GetAddressChangeStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetAddressChangeStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetAddressChangeStatusResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "requested_changes", kind: "message", T: PersonalInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAddressChangeStatusResponse {
    return new GetAddressChangeStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAddressChangeStatusResponse {
    return new GetAddressChangeStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAddressChangeStatusResponse {
    return new GetAddressChangeStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAddressChangeStatusResponse | PlainMessage<GetAddressChangeStatusResponse> | undefined, b: GetAddressChangeStatusResponse | PlainMessage<GetAddressChangeStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetAddressChangeStatusResponse, a, b);
  }

  static _enums: {
    Status: typeof GetAddressChangeStatusResponse.Status,
  };
}

export namespace GetAddressChangeStatusResponse {
  /**
   * @generated from enum web.public.GetAddressChangeStatusResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,

    /**
     * @generated from enum value: PENDING_TRANSUNION = 3;
     */
    PENDING_TRANSUNION = 3,

    /**
     * @generated from enum value: PENDING_PERSONA = 4;
     */
    PENDING_PERSONA = 4,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetAddressChangeStatusResponse.Status)
  proto3.util.setEnumType(GetAddressChangeStatusResponse.Status, "web.public.GetAddressChangeStatusResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
    { no: 3, name: "PENDING_TRANSUNION" },
    { no: 4, name: "PENDING_PERSONA" },
  ]);
}

/**
 * @generated from message web.public.GetPersonaAddressVerificationLinkResponse
 */
export class GetPersonaAddressVerificationLinkResponse extends Message<GetPersonaAddressVerificationLinkResponse> {
  /**
   * @generated from field: web.public.GetPersonaAddressVerificationLinkResponse.Status status = 1;
   */
  status = GetPersonaAddressVerificationLinkResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string persona_address_verification_url = 3;
   */
  personaAddressVerificationUrl = "";

  constructor(data?: PartialMessage<GetPersonaAddressVerificationLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPersonaAddressVerificationLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetPersonaAddressVerificationLinkResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "persona_address_verification_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPersonaAddressVerificationLinkResponse {
    return new GetPersonaAddressVerificationLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPersonaAddressVerificationLinkResponse {
    return new GetPersonaAddressVerificationLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPersonaAddressVerificationLinkResponse {
    return new GetPersonaAddressVerificationLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPersonaAddressVerificationLinkResponse | PlainMessage<GetPersonaAddressVerificationLinkResponse> | undefined, b: GetPersonaAddressVerificationLinkResponse | PlainMessage<GetPersonaAddressVerificationLinkResponse> | undefined): boolean {
    return proto3.util.equals(GetPersonaAddressVerificationLinkResponse, a, b);
  }

  static _enums: {
    Status: typeof GetPersonaAddressVerificationLinkResponse.Status,
  };
}

export namespace GetPersonaAddressVerificationLinkResponse {
  /**
   * @generated from enum web.public.GetPersonaAddressVerificationLinkResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetPersonaAddressVerificationLinkResponse.Status)
  proto3.util.setEnumType(GetPersonaAddressVerificationLinkResponse.Status, "web.public.GetPersonaAddressVerificationLinkResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetInquiryIdRequest
 */
export class GetInquiryIdRequest extends Message<GetInquiryIdRequest> {
  /**
   * @generated from field: string inquiry_id = 1;
   */
  inquiryId = "";

  constructor(data?: PartialMessage<GetInquiryIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetInquiryIdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inquiry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInquiryIdRequest {
    return new GetInquiryIdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInquiryIdRequest {
    return new GetInquiryIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInquiryIdRequest {
    return new GetInquiryIdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInquiryIdRequest | PlainMessage<GetInquiryIdRequest> | undefined, b: GetInquiryIdRequest | PlainMessage<GetInquiryIdRequest> | undefined): boolean {
    return proto3.util.equals(GetInquiryIdRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetPersonaInquiryStatusResponse
 */
export class GetPersonaInquiryStatusResponse extends Message<GetPersonaInquiryStatusResponse> {
  /**
   * @generated from field: web.public.GetPersonaInquiryStatusResponse.Status status = 1;
   */
  status = GetPersonaInquiryStatusResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string inquiry_status = 3;
   */
  inquiryStatus = "";

  constructor(data?: PartialMessage<GetPersonaInquiryStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPersonaInquiryStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetPersonaInquiryStatusResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "inquiry_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPersonaInquiryStatusResponse {
    return new GetPersonaInquiryStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPersonaInquiryStatusResponse {
    return new GetPersonaInquiryStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPersonaInquiryStatusResponse {
    return new GetPersonaInquiryStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPersonaInquiryStatusResponse | PlainMessage<GetPersonaInquiryStatusResponse> | undefined, b: GetPersonaInquiryStatusResponse | PlainMessage<GetPersonaInquiryStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetPersonaInquiryStatusResponse, a, b);
  }

  static _enums: {
    Status: typeof GetPersonaInquiryStatusResponse.Status,
  };
}

export namespace GetPersonaInquiryStatusResponse {
  /**
   * @generated from enum web.public.GetPersonaInquiryStatusResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetPersonaInquiryStatusResponse.Status)
  proto3.util.setEnumType(GetPersonaInquiryStatusResponse.Status, "web.public.GetPersonaInquiryStatusResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetCreditMonitoringAlertsRequest
 */
export class GetCreditMonitoringAlertsRequest extends Message<GetCreditMonitoringAlertsRequest> {
  constructor(data?: PartialMessage<GetCreditMonitoringAlertsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditMonitoringAlertsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditMonitoringAlertsRequest {
    return new GetCreditMonitoringAlertsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditMonitoringAlertsRequest {
    return new GetCreditMonitoringAlertsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditMonitoringAlertsRequest {
    return new GetCreditMonitoringAlertsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditMonitoringAlertsRequest | PlainMessage<GetCreditMonitoringAlertsRequest> | undefined, b: GetCreditMonitoringAlertsRequest | PlainMessage<GetCreditMonitoringAlertsRequest> | undefined): boolean {
    return proto3.util.equals(GetCreditMonitoringAlertsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetCreditMonitoringAlertsResponse
 */
export class GetCreditMonitoringAlertsResponse extends Message<GetCreditMonitoringAlertsResponse> {
  /**
   * @generated from field: web.public.GetCreditMonitoringAlertsResponse.Status status = 1;
   */
  status = GetCreditMonitoringAlertsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.CreditMonitor credit_alerts = 3;
   */
  creditAlerts: CreditMonitor[] = [];

  constructor(data?: PartialMessage<GetCreditMonitoringAlertsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditMonitoringAlertsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetCreditMonitoringAlertsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "credit_alerts", kind: "message", T: CreditMonitor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditMonitoringAlertsResponse {
    return new GetCreditMonitoringAlertsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditMonitoringAlertsResponse {
    return new GetCreditMonitoringAlertsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditMonitoringAlertsResponse {
    return new GetCreditMonitoringAlertsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditMonitoringAlertsResponse | PlainMessage<GetCreditMonitoringAlertsResponse> | undefined, b: GetCreditMonitoringAlertsResponse | PlainMessage<GetCreditMonitoringAlertsResponse> | undefined): boolean {
    return proto3.util.equals(GetCreditMonitoringAlertsResponse, a, b);
  }

  static _enums: {
    Status: typeof GetCreditMonitoringAlertsResponse.Status,
  };
}

export namespace GetCreditMonitoringAlertsResponse {
  /**
   * @generated from enum web.public.GetCreditMonitoringAlertsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetCreditMonitoringAlertsResponse.Status)
  proto3.util.setEnumType(GetCreditMonitoringAlertsResponse.Status, "web.public.GetCreditMonitoringAlertsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

