// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/credit_monitoring.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Error } from "./error_pb.js";

/**
 * @generated from message web.public.GetCreditScoresRequest
 */
export class GetCreditScoresRequest extends Message<GetCreditScoresRequest> {
  constructor(data?: PartialMessage<GetCreditScoresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditScoresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditScoresRequest {
    return new GetCreditScoresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditScoresRequest {
    return new GetCreditScoresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditScoresRequest {
    return new GetCreditScoresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditScoresRequest | PlainMessage<GetCreditScoresRequest> | undefined, b: GetCreditScoresRequest | PlainMessage<GetCreditScoresRequest> | undefined): boolean {
    return proto3.util.equals(GetCreditScoresRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetCreditInformationRequest
 */
export class GetCreditInformationRequest extends Message<GetCreditInformationRequest> {
  constructor(data?: PartialMessage<GetCreditInformationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditInformationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditInformationRequest {
    return new GetCreditInformationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditInformationRequest {
    return new GetCreditInformationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditInformationRequest {
    return new GetCreditInformationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditInformationRequest | PlainMessage<GetCreditInformationRequest> | undefined, b: GetCreditInformationRequest | PlainMessage<GetCreditInformationRequest> | undefined): boolean {
    return proto3.util.equals(GetCreditInformationRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetCreditMonitoringRequest
 */
export class GetCreditMonitoringRequest extends Message<GetCreditMonitoringRequest> {
  /**
   * use credit monitor file date to fetch all alerts we get on that day
   *
   * @generated from field: web.public.Date generated_date = 1;
   */
  generatedDate?: Date;

  constructor(data?: PartialMessage<GetCreditMonitoringRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditMonitoringRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "generated_date", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditMonitoringRequest {
    return new GetCreditMonitoringRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditMonitoringRequest {
    return new GetCreditMonitoringRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditMonitoringRequest {
    return new GetCreditMonitoringRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditMonitoringRequest | PlainMessage<GetCreditMonitoringRequest> | undefined, b: GetCreditMonitoringRequest | PlainMessage<GetCreditMonitoringRequest> | undefined): boolean {
    return proto3.util.equals(GetCreditMonitoringRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetCreditScoresResponse
 */
export class GetCreditScoresResponse extends Message<GetCreditScoresResponse> {
  /**
   * @generated from field: web.public.CreditMonitoringEnrollment enrollment = 1;
   */
  enrollment?: CreditMonitoringEnrollment;

  /**
   * @generated from field: repeated web.public.CreditScore credit_scores = 2;
   */
  creditScores: CreditScore[] = [];

  /**
   * @generated from field: web.public.Error error = 3;
   */
  error?: Error;

  /**
   * @generated from field: web.public.GetCreditScoresResponse.Status status = 4;
   */
  status = GetCreditScoresResponse.Status.UNKNOWN;

  constructor(data?: PartialMessage<GetCreditScoresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditScoresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enrollment", kind: "message", T: CreditMonitoringEnrollment },
    { no: 2, name: "credit_scores", kind: "message", T: CreditScore, repeated: true },
    { no: 3, name: "error", kind: "message", T: Error },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(GetCreditScoresResponse.Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditScoresResponse {
    return new GetCreditScoresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditScoresResponse {
    return new GetCreditScoresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditScoresResponse {
    return new GetCreditScoresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditScoresResponse | PlainMessage<GetCreditScoresResponse> | undefined, b: GetCreditScoresResponse | PlainMessage<GetCreditScoresResponse> | undefined): boolean {
    return proto3.util.equals(GetCreditScoresResponse, a, b);
  }

  static _enums: {
    Status: typeof GetCreditScoresResponse.Status,
  };
}

export namespace GetCreditScoresResponse {
  /**
   * @generated from enum web.public.GetCreditScoresResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetCreditScoresResponse.Status)
  proto3.util.setEnumType(GetCreditScoresResponse.Status, "web.public.GetCreditScoresResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetCreditInformationResponse
 */
export class GetCreditInformationResponse extends Message<GetCreditInformationResponse> {
  /**
   * @generated from field: web.public.CreditMonitoringEnrollment enrollment = 1;
   */
  enrollment?: CreditMonitoringEnrollment;

  /**
   * @generated from field: repeated web.public.CreditScore credit_scores = 2;
   */
  creditScores: CreditScore[] = [];

  /**
   * @generated from field: web.public.Error error = 3;
   */
  error?: Error;

  /**
   * @generated from field: web.public.GetCreditInformationResponse.Status status = 4;
   */
  status = GetCreditInformationResponse.Status.UNKNOWN;

  /**
   * @generated from field: repeated web.public.CreditReport credit_reports = 5;
   */
  creditReports: CreditReport[] = [];

  /**
   * @generated from field: web.public.CreditScoreGain credit_score_gain = 6;
   */
  creditScoreGain?: CreditScoreGain;

  /**
   * @generated from field: web.public.CreditScoreHistory credit_score_history = 7;
   */
  creditScoreHistory?: CreditScoreHistory;

  constructor(data?: PartialMessage<GetCreditInformationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditInformationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enrollment", kind: "message", T: CreditMonitoringEnrollment },
    { no: 2, name: "credit_scores", kind: "message", T: CreditScore, repeated: true },
    { no: 3, name: "error", kind: "message", T: Error },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(GetCreditInformationResponse.Status) },
    { no: 5, name: "credit_reports", kind: "message", T: CreditReport, repeated: true },
    { no: 6, name: "credit_score_gain", kind: "message", T: CreditScoreGain },
    { no: 7, name: "credit_score_history", kind: "message", T: CreditScoreHistory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditInformationResponse {
    return new GetCreditInformationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditInformationResponse {
    return new GetCreditInformationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditInformationResponse {
    return new GetCreditInformationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditInformationResponse | PlainMessage<GetCreditInformationResponse> | undefined, b: GetCreditInformationResponse | PlainMessage<GetCreditInformationResponse> | undefined): boolean {
    return proto3.util.equals(GetCreditInformationResponse, a, b);
  }

  static _enums: {
    Status: typeof GetCreditInformationResponse.Status,
  };
}

export namespace GetCreditInformationResponse {
  /**
   * @generated from enum web.public.GetCreditInformationResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetCreditInformationResponse.Status)
  proto3.util.setEnumType(GetCreditInformationResponse.Status, "web.public.GetCreditInformationResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.CreditScoreGain
 */
export class CreditScoreGain extends Message<CreditScoreGain> {
  /**
   * @generated from field: uint32 score_gain = 1;
   */
  scoreGain = 0;

  /**
   * @generated from field: string congratulatory_message = 2;
   */
  congratulatoryMessage = "";

  /**
   * @generated from field: uint32 months_since_joining_kikoff = 3;
   */
  monthsSinceJoiningKikoff = 0;

  constructor(data?: PartialMessage<CreditScoreGain>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditScoreGain";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "score_gain", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "congratulatory_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "months_since_joining_kikoff", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScoreGain {
    return new CreditScoreGain().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScoreGain {
    return new CreditScoreGain().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScoreGain {
    return new CreditScoreGain().fromJsonString(jsonString, options);
  }

  static equals(a: CreditScoreGain | PlainMessage<CreditScoreGain> | undefined, b: CreditScoreGain | PlainMessage<CreditScoreGain> | undefined): boolean {
    return proto3.util.equals(CreditScoreGain, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CreditScoreHistory
 */
export class CreditScoreHistory extends Message<CreditScoreHistory> {
  /**
   * @generated from field: repeated web.public.LeanCreditScore credit_scores = 1;
   */
  creditScores: LeanCreditScore[] = [];

  /**
   * @generated from field: uint32 chart_lower_bound = 2;
   */
  chartLowerBound = 0;

  /**
   * @generated from field: uint32 chart_upper_bound = 3;
   */
  chartUpperBound = 0;

  /**
   * @generated from field: string score_type = 4;
   */
  scoreType = "";

  /**
   * @generated from field: google.protobuf.Timestamp chart_start_date = 5;
   */
  chartStartDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp chart_end_date = 6;
   */
  chartEndDate?: Timestamp;

  constructor(data?: PartialMessage<CreditScoreHistory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditScoreHistory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credit_scores", kind: "message", T: LeanCreditScore, repeated: true },
    { no: 2, name: "chart_lower_bound", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "chart_upper_bound", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "score_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "chart_start_date", kind: "message", T: Timestamp },
    { no: 6, name: "chart_end_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScoreHistory {
    return new CreditScoreHistory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScoreHistory {
    return new CreditScoreHistory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScoreHistory {
    return new CreditScoreHistory().fromJsonString(jsonString, options);
  }

  static equals(a: CreditScoreHistory | PlainMessage<CreditScoreHistory> | undefined, b: CreditScoreHistory | PlainMessage<CreditScoreHistory> | undefined): boolean {
    return proto3.util.equals(CreditScoreHistory, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.LeanCreditScore
 */
export class LeanCreditScore extends Message<LeanCreditScore> {
  /**
   * @generated from field: google.protobuf.Timestamp recorded_date = 1;
   */
  recordedDate?: Timestamp;

  /**
   * @generated from field: uint32 score = 2;
   */
  score = 0;

  constructor(data?: PartialMessage<LeanCreditScore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.LeanCreditScore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recorded_date", kind: "message", T: Timestamp },
    { no: 2, name: "score", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeanCreditScore {
    return new LeanCreditScore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeanCreditScore {
    return new LeanCreditScore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeanCreditScore {
    return new LeanCreditScore().fromJsonString(jsonString, options);
  }

  static equals(a: LeanCreditScore | PlainMessage<LeanCreditScore> | undefined, b: LeanCreditScore | PlainMessage<LeanCreditScore> | undefined): boolean {
    return proto3.util.equals(LeanCreditScore, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetCreditMonitoringResponse
 */
export class GetCreditMonitoringResponse extends Message<GetCreditMonitoringResponse> {
  /**
   * @generated from field: web.public.CreditMonitoringEnrollment enrollment = 1;
   */
  enrollment?: CreditMonitoringEnrollment;

  /**
   * @generated from field: repeated web.public.CreditMonitor credit_monitors = 2;
   */
  creditMonitors: CreditMonitor[] = [];

  /**
   * @generated from field: web.public.Error error = 3;
   */
  error?: Error;

  /**
   * @generated from field: web.public.GetCreditMonitoringResponse.Status status = 4;
   */
  status = GetCreditMonitoringResponse.Status.UNKNWON;

  constructor(data?: PartialMessage<GetCreditMonitoringResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetCreditMonitoringResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enrollment", kind: "message", T: CreditMonitoringEnrollment },
    { no: 2, name: "credit_monitors", kind: "message", T: CreditMonitor, repeated: true },
    { no: 3, name: "error", kind: "message", T: Error },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(GetCreditMonitoringResponse.Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditMonitoringResponse {
    return new GetCreditMonitoringResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditMonitoringResponse {
    return new GetCreditMonitoringResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditMonitoringResponse {
    return new GetCreditMonitoringResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditMonitoringResponse | PlainMessage<GetCreditMonitoringResponse> | undefined, b: GetCreditMonitoringResponse | PlainMessage<GetCreditMonitoringResponse> | undefined): boolean {
    return proto3.util.equals(GetCreditMonitoringResponse, a, b);
  }

  static _enums: {
    Status: typeof GetCreditMonitoringResponse.Status,
  };
}

export namespace GetCreditMonitoringResponse {
  /**
   * @generated from enum web.public.GetCreditMonitoringResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNWON = 0;
     */
    UNKNWON = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetCreditMonitoringResponse.Status)
  proto3.util.setEnumType(GetCreditMonitoringResponse.Status, "web.public.GetCreditMonitoringResponse.Status", [
    { no: 0, name: "UNKNWON" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.CreditMonitoringEnrollment
 */
export class CreditMonitoringEnrollment extends Message<CreditMonitoringEnrollment> {
  /**
   * @generated from field: bool active = 1;
   */
  active = false;

  constructor(data?: PartialMessage<CreditMonitoringEnrollment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditMonitoringEnrollment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditMonitoringEnrollment {
    return new CreditMonitoringEnrollment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditMonitoringEnrollment {
    return new CreditMonitoringEnrollment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditMonitoringEnrollment {
    return new CreditMonitoringEnrollment().fromJsonString(jsonString, options);
  }

  static equals(a: CreditMonitoringEnrollment | PlainMessage<CreditMonitoringEnrollment> | undefined, b: CreditMonitoringEnrollment | PlainMessage<CreditMonitoringEnrollment> | undefined): boolean {
    return proto3.util.equals(CreditMonitoringEnrollment, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CreditReport
 */
export class CreditReport extends Message<CreditReport> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: repeated web.public.CreditReport.CreditReportProviderView provider_views = 2;
   */
  providerViews: CreditReport.CreditReportProviderView[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp generated_date = 3;
   */
  generatedDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp fetched_date = 4;
   */
  fetchedDate?: Timestamp;

  constructor(data?: PartialMessage<CreditReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "provider_views", kind: "message", T: CreditReport.CreditReportProviderView, repeated: true },
    { no: 3, name: "generated_date", kind: "message", T: Timestamp },
    { no: 4, name: "fetched_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport {
    return new CreditReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport {
    return new CreditReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport {
    return new CreditReport().fromJsonString(jsonString, options);
  }

  static equals(a: CreditReport | PlainMessage<CreditReport> | undefined, b: CreditReport | PlainMessage<CreditReport> | undefined): boolean {
    return proto3.util.equals(CreditReport, a, b);
  }

  static _enums: {
  };
}

export namespace CreditReport {
  /**
   * @generated from message web.public.CreditReport.CreditReportProviderView
   */
  export class CreditReportProviderView extends Message<CreditReport.CreditReportProviderView> {
    /**
     * @generated from field: web.public.CreditReport.CreditReportProviderView.Provider provider = 1;
     */
    provider = CreditReport.CreditReportProviderView.Provider.UNKNOWN;

    /**
     * @generated from field: repeated web.public.CreditReport.CreditReportProviderView.Account accounts = 2;
     */
    accounts: CreditReport.CreditReportProviderView.Account[] = [];

    constructor(data?: PartialMessage<CreditReport.CreditReportProviderView>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditReport.CreditReportProviderView";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "provider", kind: "enum", T: proto3.getEnumType(CreditReport.CreditReportProviderView.Provider) },
      { no: 2, name: "accounts", kind: "message", T: CreditReport.CreditReportProviderView.Account, repeated: true },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport.CreditReportProviderView {
      return new CreditReport.CreditReportProviderView().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView {
      return new CreditReport.CreditReportProviderView().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView {
      return new CreditReport.CreditReportProviderView().fromJsonString(jsonString, options);
    }

    static equals(a: CreditReport.CreditReportProviderView | PlainMessage<CreditReport.CreditReportProviderView> | undefined, b: CreditReport.CreditReportProviderView | PlainMessage<CreditReport.CreditReportProviderView> | undefined): boolean {
      return proto3.util.equals(CreditReport.CreditReportProviderView, a, b);
    }

    static _enums: {
      Provider: typeof CreditReport.CreditReportProviderView.Provider,
    };
  }
}

export namespace CreditReport.CreditReportProviderView {
  /**
   * @generated from enum web.public.CreditReport.CreditReportProviderView.Provider
   */
  export enum Provider {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: EFX = 1;
     */
    EFX = 1,

    /**
     * @generated from enum value: TU = 2;
     */
    TU = 2,

    /**
     * @generated from enum value: EXP = 3;
     */
    EXP = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditReport.CreditReportProviderView.Provider)
  proto3.util.setEnumType(CreditReport.CreditReportProviderView.Provider, "web.public.CreditReport.CreditReportProviderView.Provider", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "EFX" },
    { no: 2, name: "TU" },
    { no: 3, name: "EXP" },
  ]);
}

export namespace CreditReport.CreditReportProviderView {
  /**
   * @generated from message web.public.CreditReport.CreditReportProviderView.Account
   */
  export class Account extends Message<CreditReport.CreditReportProviderView.Account> {
    /**
     * @generated from field: string id = 1;
     */
    id = "";

    /**
     * @generated from field: string account_name = 2;
     */
    accountName = "";

    /**
     * @generated from field: bool account_open = 3;
     */
    accountOpen = false;

    /**
     * @generated from field: repeated web.public.CreditReport.CreditReportProviderView.Account.AccountProperty account_properties = 4;
     */
    accountProperties: CreditReport.CreditReportProviderView.Account.AccountProperty[] = [];

    /**
     * @generated from field: repeated web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo payment_history = 5;
     */
    paymentHistory: CreditReport.CreditReportProviderView.Account.PaymentInfo[] = [];

    constructor(data?: PartialMessage<CreditReport.CreditReportProviderView.Account>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditReport.CreditReportProviderView.Account";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "account_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "account_open", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 4, name: "account_properties", kind: "message", T: CreditReport.CreditReportProviderView.Account.AccountProperty, repeated: true },
      { no: 5, name: "payment_history", kind: "message", T: CreditReport.CreditReportProviderView.Account.PaymentInfo, repeated: true },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport.CreditReportProviderView.Account {
      return new CreditReport.CreditReportProviderView.Account().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account {
      return new CreditReport.CreditReportProviderView.Account().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account {
      return new CreditReport.CreditReportProviderView.Account().fromJsonString(jsonString, options);
    }

    static equals(a: CreditReport.CreditReportProviderView.Account | PlainMessage<CreditReport.CreditReportProviderView.Account> | undefined, b: CreditReport.CreditReportProviderView.Account | PlainMessage<CreditReport.CreditReportProviderView.Account> | undefined): boolean {
      return proto3.util.equals(CreditReport.CreditReportProviderView.Account, a, b);
    }

    static _enums: {
    };
  }
}

export namespace CreditReport.CreditReportProviderView.Account {
  /**
   * @generated from message web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo
   */
  export class PaymentInfo extends Message<CreditReport.CreditReportProviderView.Account.PaymentInfo> {
    /**
     * @generated from field: google.protobuf.Timestamp payment_time = 1;
     */
    paymentTime?: Timestamp;

    /**
     * @generated from field: web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum value = 2;
     */
    value = CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum.OTHER;

    /**
     * @generated from field: web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum month_type = 3;
     */
    monthType = CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum.OTHER;

    constructor(data?: PartialMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "payment_time", kind: "message", T: Timestamp },
      { no: 2, name: "value", kind: "enum", T: proto3.getEnumType(CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum) },
      { no: 3, name: "month_type", kind: "enum", T: proto3.getEnumType(CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum) },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo().fromJsonString(jsonString, options);
    }

    static equals(a: CreditReport.CreditReportProviderView.Account.PaymentInfo | PlainMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo> | undefined, b: CreditReport.CreditReportProviderView.Account.PaymentInfo | PlainMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo> | undefined): boolean {
      return proto3.util.equals(CreditReport.CreditReportProviderView.Account.PaymentInfo, a, b);
    }

    static _enums: {
    };
  }
}

export namespace CreditReport.CreditReportProviderView.Account.PaymentInfo {
  /**
   * @generated from message web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType
   */
  export class MonthType extends Message<CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType> {
    constructor(data?: PartialMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType().fromJsonString(jsonString, options);
    }

    static equals(a: CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType | PlainMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType> | undefined, b: CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType | PlainMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType> | undefined): boolean {
      return proto3.util.equals(CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType, a, b);
    }

    static _enums: {
      Enum: typeof CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum,
    };
  }
}

export namespace CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType {
  /**
   * @generated from enum web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum
   */
  export enum Enum {
    /**
     * @generated from enum value: OTHER = 0;
     */
    OTHER = 0,

    /**
     * @generated from enum value: POSITIVE = 1;
     */
    POSITIVE = 1,

    /**
     * @generated from enum value: NEGATIVE = 2;
     */
    NEGATIVE = 2,

    /**
     * @generated from enum value: COLLECTION = 3;
     */
    COLLECTION = 3,

    /**
     * @generated from enum value: CHARGE_OFF = 4;
     */
    CHARGE_OFF = 4,

    /**
     * @generated from enum value: REPOSSESSION = 5;
     */
    REPOSSESSION = 5,

    /**
     * @generated from enum value: NO_DATA = 6;
     */
    NO_DATA = 6,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum)
  proto3.util.setEnumType(CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum, "web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.MonthType.Enum", [
    { no: 0, name: "OTHER" },
    { no: 1, name: "POSITIVE" },
    { no: 2, name: "NEGATIVE" },
    { no: 3, name: "COLLECTION" },
    { no: 4, name: "CHARGE_OFF" },
    { no: 5, name: "REPOSSESSION" },
    { no: 6, name: "NO_DATA" },
  ]);
}

export namespace CreditReport.CreditReportProviderView.Account.PaymentInfo {
  /**
   * @generated from message web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.Value
   */
  export class Value extends Message<CreditReport.CreditReportProviderView.Account.PaymentInfo.Value> {
    constructor(data?: PartialMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo.Value>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.Value";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo.Value {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo.Value().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo.Value {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo.Value().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.PaymentInfo.Value {
      return new CreditReport.CreditReportProviderView.Account.PaymentInfo.Value().fromJsonString(jsonString, options);
    }

    static equals(a: CreditReport.CreditReportProviderView.Account.PaymentInfo.Value | PlainMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo.Value> | undefined, b: CreditReport.CreditReportProviderView.Account.PaymentInfo.Value | PlainMessage<CreditReport.CreditReportProviderView.Account.PaymentInfo.Value> | undefined): boolean {
      return proto3.util.equals(CreditReport.CreditReportProviderView.Account.PaymentInfo.Value, a, b);
    }

    static _enums: {
      Enum: typeof CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum,
    };
  }
}

export namespace CreditReport.CreditReportProviderView.Account.PaymentInfo.Value {
  /**
   * @generated from enum web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum
   */
  export enum Enum {
    /**
     * @generated from enum value: OTHER = 0;
     */
    OTHER = 0,

    /**
     * @generated from enum value: PAYS_AS_AGREED = 1;
     */
    PAYS_AS_AGREED = 1,

    /**
     * @generated from enum value: UNAVAILABLE = 2;
     */
    UNAVAILABLE = 2,

    /**
     * @generated from enum value: DAYSLATE_30 = 3;
     */
    DAYSLATE_30 = 3,

    /**
     * @generated from enum value: DAYSLATE_60 = 4;
     */
    DAYSLATE_60 = 4,

    /**
     * @generated from enum value: DAYSLATE_90 = 5;
     */
    DAYSLATE_90 = 5,

    /**
     * @generated from enum value: DAYSLATE_120 = 6;
     */
    DAYSLATE_120 = 6,

    /**
     * @generated from enum value: CHARGE_OFF = 7;
     */
    CHARGE_OFF = 7,

    /**
     * @generated from enum value: COLLECTION = 8;
     */
    COLLECTION = 8,

    /**
     * @generated from enum value: REPOSSESSION = 9;
     */
    REPOSSESSION = 9,

    /**
     * @generated from enum value: ZERO_BAL_AND_CURR_ACCT = 10;
     */
    ZERO_BAL_AND_CURR_ACCT = 10,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum)
  proto3.util.setEnumType(CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum, "web.public.CreditReport.CreditReportProviderView.Account.PaymentInfo.Value.Enum", [
    { no: 0, name: "OTHER" },
    { no: 1, name: "PAYS_AS_AGREED" },
    { no: 2, name: "UNAVAILABLE" },
    { no: 3, name: "DAYSLATE_30" },
    { no: 4, name: "DAYSLATE_60" },
    { no: 5, name: "DAYSLATE_90" },
    { no: 6, name: "DAYSLATE_120" },
    { no: 7, name: "CHARGE_OFF" },
    { no: 8, name: "COLLECTION" },
    { no: 9, name: "REPOSSESSION" },
    { no: 10, name: "ZERO_BAL_AND_CURR_ACCT" },
  ]);
}

export namespace CreditReport.CreditReportProviderView.Account {
  /**
   * @generated from message web.public.CreditReport.CreditReportProviderView.Account.AccountProperty
   */
  export class AccountProperty extends Message<CreditReport.CreditReportProviderView.Account.AccountProperty> {
    /**
     * @generated from field: string id = 1;
     */
    id = "";

    /**
     * @generated from field: string name = 2;
     */
    name = "";

    /**
     * @generated from field: string value = 3;
     */
    value = "";

    /**
     * this will be used to order the properties on the client.
     * In the future, we can add another field to help the client decide which property to
     * emphasize.
     *
     * @generated from field: uint32 importance = 4;
     */
    importance = 0;

    constructor(data?: PartialMessage<CreditReport.CreditReportProviderView.Account.AccountProperty>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditReport.CreditReportProviderView.Account.AccountProperty";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 4, name: "importance", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditReport.CreditReportProviderView.Account.AccountProperty {
      return new CreditReport.CreditReportProviderView.Account.AccountProperty().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.AccountProperty {
      return new CreditReport.CreditReportProviderView.Account.AccountProperty().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditReport.CreditReportProviderView.Account.AccountProperty {
      return new CreditReport.CreditReportProviderView.Account.AccountProperty().fromJsonString(jsonString, options);
    }

    static equals(a: CreditReport.CreditReportProviderView.Account.AccountProperty | PlainMessage<CreditReport.CreditReportProviderView.Account.AccountProperty> | undefined, b: CreditReport.CreditReportProviderView.Account.AccountProperty | PlainMessage<CreditReport.CreditReportProviderView.Account.AccountProperty> | undefined): boolean {
      return proto3.util.equals(CreditReport.CreditReportProviderView.Account.AccountProperty, a, b);
    }

    static _enums: {
    };
  }
}

/**
 * @generated from message web.public.CreditScore
 */
export class CreditScore extends Message<CreditScore> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: repeated web.public.CreditScore.CreditScoreProviderView provider_views = 2;
   */
  providerViews: CreditScore.CreditScoreProviderView[] = [];

  /**
   * @generated from field: string score_type = 3;
   */
  scoreType = "";

  /**
   * @generated from field: web.public.CreditScore.ScoreModel score_model = 4;
   */
  scoreModel = CreditScore.ScoreModel.UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp generated_date = 5;
   */
  generatedDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp fetched_date = 6;
   */
  fetchedDate?: Timestamp;

  /**
   * @generated from field: bool outdated = 7;
   */
  outdated = false;

  constructor(data?: PartialMessage<CreditScore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditScore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "provider_views", kind: "message", T: CreditScore.CreditScoreProviderView, repeated: true },
    { no: 3, name: "score_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_model", kind: "enum", T: proto3.getEnumType(CreditScore.ScoreModel) },
    { no: 5, name: "generated_date", kind: "message", T: Timestamp },
    { no: 6, name: "fetched_date", kind: "message", T: Timestamp },
    { no: 7, name: "outdated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScore {
    return new CreditScore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScore {
    return new CreditScore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScore {
    return new CreditScore().fromJsonString(jsonString, options);
  }

  static equals(a: CreditScore | PlainMessage<CreditScore> | undefined, b: CreditScore | PlainMessage<CreditScore> | undefined): boolean {
    return proto3.util.equals(CreditScore, a, b);
  }

  static _enums: {
    ScoreModel: typeof CreditScore.ScoreModel,
  };
}

export namespace CreditScore {
  /**
   * @generated from enum web.public.CreditScore.ScoreModel
   */
  export enum ScoreModel {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: VANTAGE = 1;
     */
    VANTAGE = 1,

    /**
     * @generated from enum value: ERS = 2;
     */
    ERS = 2,

    /**
     * @generated from enum value: FICO = 3;
     */
    FICO = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditScore.ScoreModel)
  proto3.util.setEnumType(CreditScore.ScoreModel, "web.public.CreditScore.ScoreModel", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "VANTAGE" },
    { no: 2, name: "ERS" },
    { no: 3, name: "FICO" },
  ]);
}

export namespace CreditScore {
  /**
   * @generated from message web.public.CreditScore.CreditScoreProviderView
   */
  export class CreditScoreProviderView extends Message<CreditScore.CreditScoreProviderView> {
    /**
     * @generated from field: web.public.CreditScore.CreditScoreProviderView.Provider provider = 1;
     */
    provider = CreditScore.CreditScoreProviderView.Provider.UNKNOWN;

    /**
     * @generated from field: uint32 score = 2;
     */
    score = 0;

    /**
     * SKIP FOR NOW
     *
     * @generated from field: repeated web.public.CreditScore.CreditScoreProviderView.CreditScoreRange score_ranges = 3;
     */
    scoreRanges: CreditScore.CreditScoreProviderView.CreditScoreRange[] = [];

    /**
     * SKIP FOR NOW
     *
     * @generated from field: repeated web.public.CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange loan_risk_ranges = 4;
     */
    loanRiskRanges: CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange[] = [];

    /**
     * @generated from field: repeated web.public.CreditScore.CreditScoreProviderView.CreditScoreReason score_reasons = 5;
     */
    scoreReasons: CreditScore.CreditScoreProviderView.CreditScoreReason[] = [];

    constructor(data?: PartialMessage<CreditScore.CreditScoreProviderView>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditScore.CreditScoreProviderView";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "provider", kind: "enum", T: proto3.getEnumType(CreditScore.CreditScoreProviderView.Provider) },
      { no: 2, name: "score", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 3, name: "score_ranges", kind: "message", T: CreditScore.CreditScoreProviderView.CreditScoreRange, repeated: true },
      { no: 4, name: "loan_risk_ranges", kind: "message", T: CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange, repeated: true },
      { no: 5, name: "score_reasons", kind: "message", T: CreditScore.CreditScoreProviderView.CreditScoreReason, repeated: true },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScore.CreditScoreProviderView {
      return new CreditScore.CreditScoreProviderView().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView {
      return new CreditScore.CreditScoreProviderView().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView {
      return new CreditScore.CreditScoreProviderView().fromJsonString(jsonString, options);
    }

    static equals(a: CreditScore.CreditScoreProviderView | PlainMessage<CreditScore.CreditScoreProviderView> | undefined, b: CreditScore.CreditScoreProviderView | PlainMessage<CreditScore.CreditScoreProviderView> | undefined): boolean {
      return proto3.util.equals(CreditScore.CreditScoreProviderView, a, b);
    }

    static _enums: {
      Provider: typeof CreditScore.CreditScoreProviderView.Provider,
    };
  }
}

export namespace CreditScore.CreditScoreProviderView {
  /**
   * @generated from enum web.public.CreditScore.CreditScoreProviderView.Provider
   */
  export enum Provider {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: EFX = 1;
     */
    EFX = 1,

    /**
     * @generated from enum value: TU = 2;
     */
    TU = 2,

    /**
     * @generated from enum value: EXP = 3;
     */
    EXP = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditScore.CreditScoreProviderView.Provider)
  proto3.util.setEnumType(CreditScore.CreditScoreProviderView.Provider, "web.public.CreditScore.CreditScoreProviderView.Provider", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "EFX" },
    { no: 2, name: "TU" },
    { no: 3, name: "EXP" },
  ]);
}

export namespace CreditScore.CreditScoreProviderView {
  /**
   * @generated from message web.public.CreditScore.CreditScoreProviderView.CreditScoreRange
   */
  export class CreditScoreRange extends Message<CreditScore.CreditScoreProviderView.CreditScoreRange> {
    /**
     * @generated from field: uint32 low = 1;
     */
    low = 0;

    /**
     * @generated from field: uint32 high = 2;
     */
    high = 0;

    /**
     * @generated from field: string name = 3;
     */
    name = "";

    constructor(data?: PartialMessage<CreditScore.CreditScoreProviderView.CreditScoreRange>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditScore.CreditScoreProviderView.CreditScoreRange";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "low", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 2, name: "high", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreRange {
      return new CreditScore.CreditScoreProviderView.CreditScoreRange().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreRange {
      return new CreditScore.CreditScoreProviderView.CreditScoreRange().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreRange {
      return new CreditScore.CreditScoreProviderView.CreditScoreRange().fromJsonString(jsonString, options);
    }

    static equals(a: CreditScore.CreditScoreProviderView.CreditScoreRange | PlainMessage<CreditScore.CreditScoreProviderView.CreditScoreRange> | undefined, b: CreditScore.CreditScoreProviderView.CreditScoreRange | PlainMessage<CreditScore.CreditScoreProviderView.CreditScoreRange> | undefined): boolean {
      return proto3.util.equals(CreditScore.CreditScoreProviderView.CreditScoreRange, a, b);
    }

    static _enums: {
    };
  }
}

export namespace CreditScore.CreditScoreProviderView {
  /**
   * @generated from message web.public.CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange
   */
  export class CreditScoreLoanRiskRange extends Message<CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange> {
    /**
     * @generated from field: uint32 low = 1;
     */
    low = 0;

    /**
     * @generated from field: uint32 high = 2;
     */
    high = 0;

    /**
     * @generated from field: string name = 3;
     */
    name = "";

    constructor(data?: PartialMessage<CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "low", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 2, name: "high", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange {
      return new CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange {
      return new CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange {
      return new CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange().fromJsonString(jsonString, options);
    }

    static equals(a: CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange | PlainMessage<CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange> | undefined, b: CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange | PlainMessage<CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange> | undefined): boolean {
      return proto3.util.equals(CreditScore.CreditScoreProviderView.CreditScoreLoanRiskRange, a, b);
    }

    static _enums: {
    };
  }
}

export namespace CreditScore.CreditScoreProviderView {
  /**
   * @generated from message web.public.CreditScore.CreditScoreProviderView.CreditScoreReason
   */
  export class CreditScoreReason extends Message<CreditScore.CreditScoreProviderView.CreditScoreReason> {
    /**
     * @generated from field: string code = 1;
     */
    code = "";

    /**
     * @generated from field: string description = 2;
     */
    description = "";

    /**
     * @generated from field: web.public.CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect effect = 3;
     */
    effect = CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect.UNKNOWN;

    /**
     * @generated from field: string category = 4;
     */
    category = "";

    /**
     * @generated from field: string kikoff_comment = 5;
     */
    kikoffComment = "";

    constructor(data?: PartialMessage<CreditScore.CreditScoreProviderView.CreditScoreReason>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditScore.CreditScoreProviderView.CreditScoreReason";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "effect", kind: "enum", T: proto3.getEnumType(CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect) },
      { no: 4, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 5, name: "kikoff_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreReason {
      return new CreditScore.CreditScoreProviderView.CreditScoreReason().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreReason {
      return new CreditScore.CreditScoreProviderView.CreditScoreReason().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditScore.CreditScoreProviderView.CreditScoreReason {
      return new CreditScore.CreditScoreProviderView.CreditScoreReason().fromJsonString(jsonString, options);
    }

    static equals(a: CreditScore.CreditScoreProviderView.CreditScoreReason | PlainMessage<CreditScore.CreditScoreProviderView.CreditScoreReason> | undefined, b: CreditScore.CreditScoreProviderView.CreditScoreReason | PlainMessage<CreditScore.CreditScoreProviderView.CreditScoreReason> | undefined): boolean {
      return proto3.util.equals(CreditScore.CreditScoreProviderView.CreditScoreReason, a, b);
    }

    static _enums: {
      CreditScoreFactorEffect: typeof CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect,
    };
  }
}

export namespace CreditScore.CreditScoreProviderView.CreditScoreReason {
  /**
   * @generated from enum web.public.CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect
   */
  export enum CreditScoreFactorEffect {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: HELPING = 1;
     */
    HELPING = 1,

    /**
     * @generated from enum value: HURTING = 2;
     */
    HURTING = 2,

    /**
     * @generated from enum value: NEUTRAL = 3;
     */
    NEUTRAL = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect)
  proto3.util.setEnumType(CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect, "web.public.CreditScore.CreditScoreProviderView.CreditScoreReason.CreditScoreFactorEffect", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "HELPING" },
    { no: 2, name: "HURTING" },
    { no: 3, name: "NEUTRAL" },
  ]);
}

/**
 * @generated from message web.public.CreditMonitor
 */
export class CreditMonitor extends Message<CreditMonitor> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: web.public.CreditMonitor.AlertType alert_type = 2;
   */
  alertType = CreditMonitor.AlertType.AT_UNKNOWN;

  /**
   * @generated from field: web.public.CreditMonitor.AlertChangeType alert_change_type = 3;
   */
  alertChangeType = CreditMonitor.AlertChangeType.ACT_UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp generated_date = 4;
   */
  generatedDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp reported_date = 5;
   */
  reportedDate?: Timestamp;

  /**
   * @generated from oneof web.public.CreditMonitor.alert_details
   */
  alertDetails: {
    /**
     * @generated from field: web.public.TradeLineAlert trade_line_alert = 10;
     */
    value: TradeLineAlert;
    case: "tradeLineAlert";
  } | {
    /**
     * @generated from field: web.public.InquiryAlert inquiry_alert = 11;
     */
    value: InquiryAlert;
    case: "inquiryAlert";
  } | {
    /**
     * @generated from field: web.public.AccountNarrativeChangeAlert account_narrative_change_alert = 12;
     */
    value: AccountNarrativeChangeAlert;
    case: "accountNarrativeChangeAlert";
  } | {
    /**
     * @generated from field: web.public.AccountStatusChangeAlert account_status_change_alert = 13;
     */
    value: AccountStatusChangeAlert;
    case: "accountStatusChangeAlert";
  } | {
    /**
     * @generated from field: web.public.AddressAlert address_alert = 14;
     */
    value: AddressAlert;
    case: "addressAlert";
  } | {
    /**
     * @generated from field: web.public.CollectionAlert collection_alert = 15;
     */
    value: CollectionAlert;
    case: "collectionAlert";
  } | {
    /**
     * @generated from field: web.public.NameAlert name_alert = 16;
     */
    value: NameAlert;
    case: "nameAlert";
  } | {
    /**
     * @generated from field: web.public.AccountActivityDesignatorChangeAlert account_activity_designator_change_alert = 17;
     */
    value: AccountActivityDesignatorChangeAlert;
    case: "accountActivityDesignatorChangeAlert";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreditMonitor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditMonitor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alert_type", kind: "enum", T: proto3.getEnumType(CreditMonitor.AlertType) },
    { no: 3, name: "alert_change_type", kind: "enum", T: proto3.getEnumType(CreditMonitor.AlertChangeType) },
    { no: 4, name: "generated_date", kind: "message", T: Timestamp },
    { no: 5, name: "reported_date", kind: "message", T: Timestamp },
    { no: 10, name: "trade_line_alert", kind: "message", T: TradeLineAlert, oneof: "alert_details" },
    { no: 11, name: "inquiry_alert", kind: "message", T: InquiryAlert, oneof: "alert_details" },
    { no: 12, name: "account_narrative_change_alert", kind: "message", T: AccountNarrativeChangeAlert, oneof: "alert_details" },
    { no: 13, name: "account_status_change_alert", kind: "message", T: AccountStatusChangeAlert, oneof: "alert_details" },
    { no: 14, name: "address_alert", kind: "message", T: AddressAlert, oneof: "alert_details" },
    { no: 15, name: "collection_alert", kind: "message", T: CollectionAlert, oneof: "alert_details" },
    { no: 16, name: "name_alert", kind: "message", T: NameAlert, oneof: "alert_details" },
    { no: 17, name: "account_activity_designator_change_alert", kind: "message", T: AccountActivityDesignatorChangeAlert, oneof: "alert_details" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditMonitor {
    return new CreditMonitor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditMonitor {
    return new CreditMonitor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditMonitor {
    return new CreditMonitor().fromJsonString(jsonString, options);
  }

  static equals(a: CreditMonitor | PlainMessage<CreditMonitor> | undefined, b: CreditMonitor | PlainMessage<CreditMonitor> | undefined): boolean {
    return proto3.util.equals(CreditMonitor, a, b);
  }

  static _enums: {
    AlertType: typeof CreditMonitor.AlertType,
    AlertChangeType: typeof CreditMonitor.AlertChangeType,
  };
}

export namespace CreditMonitor {
  /**
   * @generated from enum web.public.CreditMonitor.AlertType
   */
  export enum AlertType {
    /**
     * @generated from enum value: AT_UNKNOWN = 0;
     */
    AT_UNKNOWN = 0,

    /**
     * @generated from enum value: TRADE_LINE_ALERT = 1;
     */
    TRADE_LINE_ALERT = 1,

    /**
     * @generated from enum value: INQUIRY_ALERT = 2;
     */
    INQUIRY_ALERT = 2,

    /**
     * @generated from enum value: ACCOUNT_NARRATIVE_CHANGE_ALERT = 3;
     */
    ACCOUNT_NARRATIVE_CHANGE_ALERT = 3,

    /**
     * @generated from enum value: ACCOUNT_STATUS_CHANGE_ALERT = 4;
     */
    ACCOUNT_STATUS_CHANGE_ALERT = 4,

    /**
     * @generated from enum value: ADDRESS_ALERT = 5;
     */
    ADDRESS_ALERT = 5,

    /**
     * @generated from enum value: COLLECTION_ALERT = 6;
     */
    COLLECTION_ALERT = 6,

    /**
     * @generated from enum value: NAME_ALERT = 7;
     */
    NAME_ALERT = 7,

    /**
     * @generated from enum value: ACCOUNT_ACTIVITY_DESIGNATOR_CHANGE_ALERT = 8;
     */
    ACCOUNT_ACTIVITY_DESIGNATOR_CHANGE_ALERT = 8,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditMonitor.AlertType)
  proto3.util.setEnumType(CreditMonitor.AlertType, "web.public.CreditMonitor.AlertType", [
    { no: 0, name: "AT_UNKNOWN" },
    { no: 1, name: "TRADE_LINE_ALERT" },
    { no: 2, name: "INQUIRY_ALERT" },
    { no: 3, name: "ACCOUNT_NARRATIVE_CHANGE_ALERT" },
    { no: 4, name: "ACCOUNT_STATUS_CHANGE_ALERT" },
    { no: 5, name: "ADDRESS_ALERT" },
    { no: 6, name: "COLLECTION_ALERT" },
    { no: 7, name: "NAME_ALERT" },
    { no: 8, name: "ACCOUNT_ACTIVITY_DESIGNATOR_CHANGE_ALERT" },
  ]);
}

export namespace CreditMonitor {
  /**
   * @generated from enum web.public.CreditMonitor.AlertChangeType
   */
  export enum AlertChangeType {
    /**
     * @generated from enum value: ACT_UNKNOWN = 0;
     */
    ACT_UNKNOWN = 0,

    /**
     * @generated from enum value: NEW = 1;
     */
    NEW = 1,

    /**
     * @generated from enum value: CHANGE = 2;
     */
    CHANGE = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditMonitor.AlertChangeType)
  proto3.util.setEnumType(CreditMonitor.AlertChangeType, "web.public.CreditMonitor.AlertChangeType", [
    { no: 0, name: "ACT_UNKNOWN" },
    { no: 1, name: "NEW" },
    { no: 2, name: "CHANGE" },
  ]);
}

/**
 * @generated from message web.public.TradeLineAlert
 */
export class TradeLineAlert extends Message<TradeLineAlert> {
  /**
   * @generated from field: google.protobuf.Timestamp date_reported = 1;
   */
  dateReported?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp date_opened = 2;
   */
  dateOpened?: Timestamp;

  /**
   * @generated from field: string account_type = 3;
   */
  accountType = "";

  /**
   * @generated from field: uint32 balance_amount_cents = 4;
   */
  balanceAmountCents = 0;

  /**
   * @generated from field: web.public.Address address = 5;
   */
  address?: Address;

  /**
   * @generated from field: string creditor_contact_info = 6;
   */
  creditorContactInfo = "";

  /**
   * @generated from field: string description = 7;
   */
  description = "";

  /**
   * @generated from field: string creditor_name = 8;
   */
  creditorName = "";

  constructor(data?: PartialMessage<TradeLineAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.TradeLineAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date_reported", kind: "message", T: Timestamp },
    { no: 2, name: "date_opened", kind: "message", T: Timestamp },
    { no: 3, name: "account_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "balance_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "address", kind: "message", T: Address },
    { no: 6, name: "creditor_contact_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "creditor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TradeLineAlert {
    return new TradeLineAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TradeLineAlert {
    return new TradeLineAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TradeLineAlert {
    return new TradeLineAlert().fromJsonString(jsonString, options);
  }

  static equals(a: TradeLineAlert | PlainMessage<TradeLineAlert> | undefined, b: TradeLineAlert | PlainMessage<TradeLineAlert> | undefined): boolean {
    return proto3.util.equals(TradeLineAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Address
 */
export class Address extends Message<Address> {
  /**
   * @generated from field: string address_line1 = 1;
   */
  addressLine1 = "";

  /**
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * @generated from field: string state = 3;
   */
  state = "";

  /**
   * @generated from field: string zip5 = 4;
   */
  zip5 = "";

  /**
   * @generated from field: string address_line2 = 5;
   */
  addressLine2 = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "zip5", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address_line2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.InquiryAlert
 */
export class InquiryAlert extends Message<InquiryAlert> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: web.public.Address address = 2;
   */
  address?: Address;

  /**
   * @generated from field: string creditor_contact_info = 3;
   */
  creditorContactInfo = "";

  /**
   * @generated from field: string creditor_name = 4;
   */
  creditorName = "";

  constructor(data?: PartialMessage<InquiryAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InquiryAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address", kind: "message", T: Address },
    { no: 3, name: "creditor_contact_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creditor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InquiryAlert {
    return new InquiryAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InquiryAlert {
    return new InquiryAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InquiryAlert {
    return new InquiryAlert().fromJsonString(jsonString, options);
  }

  static equals(a: InquiryAlert | PlainMessage<InquiryAlert> | undefined, b: InquiryAlert | PlainMessage<InquiryAlert> | undefined): boolean {
    return proto3.util.equals(InquiryAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AccountNarrativeChangeAlert
 */
export class AccountNarrativeChangeAlert extends Message<AccountNarrativeChangeAlert> {
  /**
   * @generated from field: google.protobuf.Timestamp date_opened = 1;
   */
  dateOpened?: Timestamp;

  /**
   * @generated from field: uint32 balance_amount_cents = 2;
   */
  balanceAmountCents = 0;

  /**
   * @generated from field: repeated string narratives = 3;
   */
  narratives: string[] = [];

  /**
   * @generated from field: string creditor_name = 4;
   */
  creditorName = "";

  constructor(data?: PartialMessage<AccountNarrativeChangeAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AccountNarrativeChangeAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date_opened", kind: "message", T: Timestamp },
    { no: 2, name: "balance_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "narratives", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "creditor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountNarrativeChangeAlert {
    return new AccountNarrativeChangeAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountNarrativeChangeAlert {
    return new AccountNarrativeChangeAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountNarrativeChangeAlert {
    return new AccountNarrativeChangeAlert().fromJsonString(jsonString, options);
  }

  static equals(a: AccountNarrativeChangeAlert | PlainMessage<AccountNarrativeChangeAlert> | undefined, b: AccountNarrativeChangeAlert | PlainMessage<AccountNarrativeChangeAlert> | undefined): boolean {
    return proto3.util.equals(AccountNarrativeChangeAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AccountStatusChangeAlert
 */
export class AccountStatusChangeAlert extends Message<AccountStatusChangeAlert> {
  /**
   * @generated from field: google.protobuf.Timestamp date_opened = 1;
   */
  dateOpened?: Timestamp;

  /**
   * @generated from field: uint32 balance_amount_cents = 2;
   */
  balanceAmountCents = 0;

  /**
   * @generated from field: web.public.Address address = 3;
   */
  address?: Address;

  /**
   * @generated from field: string creditor_contact_info = 4;
   */
  creditorContactInfo = "";

  /**
   * @generated from field: string status_description = 5;
   */
  statusDescription = "";

  /**
   * @generated from field: string description = 6;
   */
  description = "";

  /**
   * @generated from field: string creditor_name = 7;
   */
  creditorName = "";

  constructor(data?: PartialMessage<AccountStatusChangeAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AccountStatusChangeAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date_opened", kind: "message", T: Timestamp },
    { no: 2, name: "balance_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "creditor_contact_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "creditor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountStatusChangeAlert {
    return new AccountStatusChangeAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountStatusChangeAlert {
    return new AccountStatusChangeAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountStatusChangeAlert {
    return new AccountStatusChangeAlert().fromJsonString(jsonString, options);
  }

  static equals(a: AccountStatusChangeAlert | PlainMessage<AccountStatusChangeAlert> | undefined, b: AccountStatusChangeAlert | PlainMessage<AccountStatusChangeAlert> | undefined): boolean {
    return proto3.util.equals(AccountStatusChangeAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AddressAlert
 */
export class AddressAlert extends Message<AddressAlert> {
  /**
   * @generated from field: web.public.Address new_address = 1;
   */
  newAddress?: Address;

  /**
   * @generated from field: web.public.Address previous_address = 2;
   */
  previousAddress?: Address;

  constructor(data?: PartialMessage<AddressAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AddressAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "new_address", kind: "message", T: Address },
    { no: 2, name: "previous_address", kind: "message", T: Address },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddressAlert {
    return new AddressAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddressAlert {
    return new AddressAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddressAlert {
    return new AddressAlert().fromJsonString(jsonString, options);
  }

  static equals(a: AddressAlert | PlainMessage<AddressAlert> | undefined, b: AddressAlert | PlainMessage<AddressAlert> | undefined): boolean {
    return proto3.util.equals(AddressAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CollectionAlert
 */
export class CollectionAlert extends Message<CollectionAlert> {
  /**
   * @generated from field: string customer_name = 1;
   */
  customerName = "";

  /**
   * @generated from field: uint32 balance_amount_cents = 2;
   */
  balanceAmountCents = 0;

  /**
   * @generated from field: web.public.Address address = 3;
   */
  address?: Address;

  /**
   * @generated from field: string creditor_contact_info = 4;
   */
  creditorContactInfo = "";

  /**
   * @generated from field: string status_description = 5;
   */
  statusDescription = "";

  /**
   * @generated from field: string creditor_name = 6;
   */
  creditorName = "";

  constructor(data?: PartialMessage<CollectionAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CollectionAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balance_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "creditor_contact_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "creditor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollectionAlert {
    return new CollectionAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollectionAlert {
    return new CollectionAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollectionAlert {
    return new CollectionAlert().fromJsonString(jsonString, options);
  }

  static equals(a: CollectionAlert | PlainMessage<CollectionAlert> | undefined, b: CollectionAlert | PlainMessage<CollectionAlert> | undefined): boolean {
    return proto3.util.equals(CollectionAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.NameAlert
 */
export class NameAlert extends Message<NameAlert> {
  /**
   * @generated from field: web.public.Name new_name = 1;
   */
  newName?: Name;

  /**
   * @generated from field: web.public.Name previous_name = 2;
   */
  previousName?: Name;

  constructor(data?: PartialMessage<NameAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.NameAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "new_name", kind: "message", T: Name },
    { no: 2, name: "previous_name", kind: "message", T: Name },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NameAlert {
    return new NameAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NameAlert {
    return new NameAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NameAlert {
    return new NameAlert().fromJsonString(jsonString, options);
  }

  static equals(a: NameAlert | PlainMessage<NameAlert> | undefined, b: NameAlert | PlainMessage<NameAlert> | undefined): boolean {
    return proto3.util.equals(NameAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Name
 */
export class Name extends Message<Name> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string middle_name = 2;
   */
  middleName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  constructor(data?: PartialMessage<Name>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Name";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Name {
    return new Name().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Name {
    return new Name().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Name {
    return new Name().fromJsonString(jsonString, options);
  }

  static equals(a: Name | PlainMessage<Name> | undefined, b: Name | PlainMessage<Name> | undefined): boolean {
    return proto3.util.equals(Name, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AccountActivityDesignatorChangeAlert
 */
export class AccountActivityDesignatorChangeAlert extends Message<AccountActivityDesignatorChangeAlert> {
  /**
   * @generated from field: google.protobuf.Timestamp date_opened = 1;
   */
  dateOpened?: Timestamp;

  /**
   * @generated from field: uint32 balance_amount_cents = 2;
   */
  balanceAmountCents = 0;

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string creditor_name = 4;
   */
  creditorName = "";

  constructor(data?: PartialMessage<AccountActivityDesignatorChangeAlert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AccountActivityDesignatorChangeAlert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date_opened", kind: "message", T: Timestamp },
    { no: 2, name: "balance_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creditor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountActivityDesignatorChangeAlert {
    return new AccountActivityDesignatorChangeAlert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountActivityDesignatorChangeAlert {
    return new AccountActivityDesignatorChangeAlert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountActivityDesignatorChangeAlert {
    return new AccountActivityDesignatorChangeAlert().fromJsonString(jsonString, options);
  }

  static equals(a: AccountActivityDesignatorChangeAlert | PlainMessage<AccountActivityDesignatorChangeAlert> | undefined, b: AccountActivityDesignatorChangeAlert | PlainMessage<AccountActivityDesignatorChangeAlert> | undefined): boolean {
    return proto3.util.equals(AccountActivityDesignatorChangeAlert, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Date
 */
export class Date extends Message<Date> {
  /**
   * Year of date. Must be from 1 to 9999, or 0 if specifying a date without
   * a year.
   *
   * @generated from field: int32 year = 1;
   */
  year = 0;

  /**
   * Month of year. Must be from 1 to 12, or 0 if specifying a year without a
   * month and day.
   *
   * @generated from field: int32 month = 2;
   */
  month = 0;

  /**
   * Day of month. Must be from 1 to 31 and valid for the year and month, or 0
   * if specifying a year by itself or a year and month where the day is not
   * significant.
   *
   * @generated from field: int32 day = 3;
   */
  day = 0;

  constructor(data?: PartialMessage<Date>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Date";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Date {
    return new Date().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Date {
    return new Date().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Date {
    return new Date().fromJsonString(jsonString, options);
  }

  static equals(a: Date | PlainMessage<Date> | undefined, b: Date | PlainMessage<Date> | undefined): boolean {
    return proto3.util.equals(Date, a, b);
  }

  static _enums: {
  };
}

