import Chainable from "./Chainable.mts";
import UTuple from "./UTuple.mts";
import UType from "./UType.mts";

declare global {
  interface Math {
    sign(x: number): UNumber.Sign;
  }
}

namespace UNumber {
  export type Sign = -1 | 0 | 1;
  export type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  export namespace Hex {
    export type Digit = UNumber.Digit | "a" | "b" | "c" | "d" | "e" | "f";
    export const from = (number: number) => number.toString(16);
  }
  export type Add<N1 extends number, N2 extends number> = UType.Narrow<
    [...UTuple.Of<any, N1>, ...UTuple.Of<any, N2>]["length"],
    number
  >;
  export type Subtract<N1 extends number, N2 extends number> =
    UTuple.Of<any, N1> extends [...UTuple.Of<any, N2>, ...infer Rest]
      ? Rest["length"]
      : number;

  export type IsNegative<N extends number> = `${N}` extends `-${string}`
    ? true
    : false;

  type _Multiply<A extends any[], B extends any[]> = B extends [
    any,
    ...infer Rest extends any[],
  ]
    ? [...A, ..._Multiply<A, Rest>]
    : [];
  export type Multiply<A extends number, B extends number> = _Multiply<
    UTuple.Of<any, A>,
    UTuple.Of<any, B>
  >["length"];

  export type Bounds = readonly [start: number, end: number];

  export namespace Bounds {
    export const fromOffset = (start: number, offset: number): Bounds => [
      start,
      start + offset,
    ];

    export const includes = (bounds: Bounds, value: number) =>
      value === bounds[0] || isBetween(value, bounds);
    export const intersects = (subject: Bounds, target: Bounds) =>
      isBetween(subject[0], target) ||
      isBetween(subject[1], target) ||
      isBetween(target[0], subject) ||
      isBetween(target[1], subject);
  }

  export function isBetween(num: number, bounds: Bounds) {
    return num > bounds[0] && num < bounds[1];
  }
  isBetween.inclusive = (num: number, bounds: Bounds) =>
    num === bounds[0] || num === bounds[1] || isBetween(num, bounds);

  export const clamp = Object.assign(
    (num: number, { min = -Infinity, max = Infinity } = {}) =>
      Math.min(Math.max(num, min), max),
    {
      bounds: (num: number, bounds: UNumber.Bounds) =>
        UNumber.clamp(
          num,
          bounds[0] < bounds[1]
            ? {
                min: bounds[0],
                max: bounds[1],
              }
            : {
                min: bounds[1],
                max: bounds[0],
              },
        ),
    },
  );
  export function progress(num: number, [from, to]: UNumber.Bounds): number {
    const res = (num - from) / (to - from);
    if (Number.isNaN(res)) return 0;
    return UNumber.clamp(res, { min: 0, max: 1 });
  }
  progress.toValue = (progress: number, [from, to]: UNumber.Bounds) => {
    const res = progress * (to - from) + from;
    if (Number.isNaN(res)) return 0;
    return res;
  };

  export const floor = (num: number, toNearest = 1) =>
    Math.floor(num / toNearest) * toNearest;
  export const ceil = (num: number, toNearest = 1) =>
    Math.ceil(num / toNearest) * toNearest;
  export const round = (num: number, toNearest = 1) =>
    Math.round(num / toNearest) * toNearest;

  export const format = Chainable.create(
    Chainable.wrapFactory(
      ({ withSign = false, pad = 0 }) =>
        (number: number) =>
          `${withSign && number > 0 ? "+" : ""}${number < 0 ? "-" : ""}${`${Math.abs(number)}`.padStart(pad, "0")}`,
    ),
    {
      withSign: { withSign: true },
      pad: (amount: number) => ({ pad: amount }),
    },
  );

  export const random = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  export const toPluralized = (n: number, unit: string) => `${n} ${unit}${n === 1 ? "" : "s"}`;
}

export default UNumber;
