// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/lending.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { AutoPayStatus, CardPayment, PaymentMethod, PaymentOption, PaymentType } from "./payment_methods_pb.js";
import { AutoPay as AutoPay$1 } from "./auto_pay_pb.js";
import { FundingType } from "./funds_pb.js";
import { Error } from "./error_pb.js";

/**
 * Core Loan objects
 *
 * @generated from message web.public.LoanOffer
 */
export class LoanOffer extends Message<LoanOffer> {
  /**
   * @generated from field: string identifier = 1;
   */
  identifier = "";

  /**
   * @generated from field: uint32 principal_cents = 2;
   */
  principalCents = 0;

  /**
   * @generated from field: uint32 monthly_payment_cents = 3;
   */
  monthlyPaymentCents = 0;

  /**
   * @generated from field: uint32 duration = 4;
   */
  duration = 0;

  /**
   * @generated from field: string frequency = 5;
   */
  frequency = "";

  /**
   * Human-friendly description
   *
   * @generated from field: repeated string description_steps = 6;
   */
  descriptionSteps: string[] = [];

  /**
   * @generated from field: string loan_agreement = 7;
   */
  loanAgreement = "";

  /**
   * @generated from field: web.public.OfferTerms offer_terms = 8;
   */
  offerTerms?: OfferTerms;

  /**
   * deprecated
   *
   * @generated from field: string loan_plan_name = 9;
   */
  loanPlanName = "";

  /**
   * @generated from field: web.public.Loan.LoanType loan_type = 10;
   */
  loanType = Loan.LoanType.UNKNOWN_TYPE;

  /**
   * @generated from field: web.public.LoanOffer.OfferStatus status = 11;
   */
  status = LoanOffer.OfferStatus.UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp first_payment_due_at = 12;
   */
  firstPaymentDueAt?: Timestamp;

  /**
   * @generated from field: repeated web.public.PaymentOption payment_options = 13;
   */
  paymentOptions: PaymentOption[] = [];

  /**
   * @generated from field: bool emphasize_card = 14;
   */
  emphasizeCard = false;

  /**
   * Premium fields
   *
   * @generated from field: uint32 admin_fee_cents = 15;
   */
  adminFeeCents = 0;

  /**
   * @generated from field: uint32 monthly_principal_cents = 16;
   */
  monthlyPrincipalCents = 0;

  /**
   * @generated from field: uint32 monthly_fee_cents = 17;
   */
  monthlyFeeCents = 0;

  /**
   * @generated from field: float total_apr = 18;
   */
  totalApr = 0;

  /**
   * Unesecured Lending fields
   *
   * @generated from field: float interest_percentage = 19;
   */
  interestPercentage = 0;

  constructor(data?: PartialMessage<LoanOffer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.LoanOffer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "monthly_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "duration", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "frequency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description_steps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "loan_agreement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "offer_terms", kind: "message", T: OfferTerms },
    { no: 9, name: "loan_plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "loan_type", kind: "enum", T: proto3.getEnumType(Loan.LoanType) },
    { no: 11, name: "status", kind: "enum", T: proto3.getEnumType(LoanOffer.OfferStatus) },
    { no: 12, name: "first_payment_due_at", kind: "message", T: Timestamp },
    { no: 13, name: "payment_options", kind: "message", T: PaymentOption, repeated: true },
    { no: 14, name: "emphasize_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "admin_fee_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 16, name: "monthly_principal_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 17, name: "monthly_fee_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 18, name: "total_apr", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "interest_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanOffer {
    return new LoanOffer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanOffer {
    return new LoanOffer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanOffer {
    return new LoanOffer().fromJsonString(jsonString, options);
  }

  static equals(a: LoanOffer | PlainMessage<LoanOffer> | undefined, b: LoanOffer | PlainMessage<LoanOffer> | undefined): boolean {
    return proto3.util.equals(LoanOffer, a, b);
  }

  static _enums: {
    OfferStatus: typeof LoanOffer.OfferStatus,
  };
}

export namespace LoanOffer {
  /**
   * @generated from enum web.public.LoanOffer.OfferStatus
   */
  export enum OfferStatus {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: AVAILABLE = 1;
     */
    AVAILABLE = 1,

    /**
     * @generated from enum value: REQUIRES_SUBSCRIPTION = 2;
     */
    REQUIRES_SUBSCRIPTION = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(LoanOffer.OfferStatus)
  proto3.util.setEnumType(LoanOffer.OfferStatus, "web.public.LoanOffer.OfferStatus", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "AVAILABLE" },
    { no: 2, name: "REQUIRES_SUBSCRIPTION" },
  ]);
}

/**
 * @generated from message web.public.OfferTerms
 */
export class OfferTerms extends Message<OfferTerms> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string summary = 2;
   */
  summary = "";

  /**
   * @generated from field: repeated string steps = 3;
   */
  steps: string[] = [];

  /**
   * @generated from field: string product_image = 4;
   */
  productImage = "";

  /**
   * @generated from field: string product_title = 5;
   */
  productTitle = "";

  /**
   * @generated from field: repeated string product_specs = 6;
   */
  productSpecs: string[] = [];

  constructor(data?: PartialMessage<OfferTerms>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.OfferTerms";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "steps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "product_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "product_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "product_specs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OfferTerms {
    return new OfferTerms().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OfferTerms {
    return new OfferTerms().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OfferTerms {
    return new OfferTerms().fromJsonString(jsonString, options);
  }

  static equals(a: OfferTerms | PlainMessage<OfferTerms> | undefined, b: OfferTerms | PlainMessage<OfferTerms> | undefined): boolean {
    return proto3.util.equals(OfferTerms, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Loan
 */
export class Loan extends Message<Loan> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: uint32 principal_cents = 2;
   */
  principalCents = 0;

  /**
   * @generated from field: uint32 monthly_payment_cents = 3;
   */
  monthlyPaymentCents = 0;

  /**
   * @generated from field: uint32 duration = 4;
   */
  duration = 0;

  /**
   * @generated from field: string frequency = 5;
   */
  frequency = "";

  /**
   * @generated from field: sint32 current_balance_cents = 6;
   */
  currentBalanceCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp opened_at = 7;
   */
  openedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp closed_at = 8;
   */
  closedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_payment_at = 9;
   */
  lastPaymentAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp first_delinquency_at = 10;
   */
  firstDelinquencyAt?: Timestamp;

  /**
   * @generated from field: web.public.Loan.Status status = 11;
   */
  status = Loan.Status.UNKNOWN;

  /**
   * @generated from field: repeated web.public.LoanInstallment loan_installments = 12;
   */
  loanInstallments: LoanInstallment[] = [];

  /**
   * @generated from field: string loan_agreement = 13;
   */
  loanAgreement = "";

  /**
   * @generated from field: google.protobuf.Timestamp reporting_hold_at = 14;
   */
  reportingHoldAt?: Timestamp;

  /**
   * @generated from field: bool experian_reporting = 15;
   */
  experianReporting = false;

  /**
   * @generated from field: bool equifax_reporting = 16;
   */
  equifaxReporting = false;

  /**
   * @generated from field: string loan_plan_name = 17 [deprecated = true];
   * @deprecated
   */
  loanPlanName = "";

  /**
   * @generated from field: web.public.Loan.LoanType loan_type = 18;
   */
  loanType = Loan.LoanType.UNKNOWN_TYPE;

  /**
   * @generated from field: repeated web.public.PaymentType allowed_payments = 19 [deprecated = true];
   * @deprecated
   */
  allowedPayments: PaymentType[] = [];

  /**
   * @generated from field: string body_description = 20;
   */
  bodyDescription = "";

  /**
   * @generated from field: bool autopay_enabled = 21 [deprecated = true];
   * @deprecated
   */
  autopayEnabled = false;

  /**
   * @generated from field: bool autopay_failed = 22 [deprecated = true];
   * @deprecated
   */
  autopayFailed = false;

  /**
   * @generated from field: string autopay_payment_method_description = 23 [deprecated = true];
   * @deprecated
   */
  autopayPaymentMethodDescription = "";

  /**
   * @generated from field: repeated web.public.PaymentOption payment_options = 24;
   */
  paymentOptions: PaymentOption[] = [];

  /**
   * @generated from field: web.public.SavingsVoucher savings_voucher = 25;
   */
  savingsVoucher?: SavingsVoucher;

  /**
   * @generated from field: bool allow_closure = 26;
   */
  allowClosure = false;

  /**
   * @generated from field: bool allow_withdrawal = 27;
   */
  allowWithdrawal = false;

  /**
   * @generated from field: web.public.AutoPayStatus auto_pay_legacy = 28 [deprecated = true];
   * @deprecated
   */
  autoPayLegacy?: AutoPayStatus;

  /**
   * @generated from field: web.public.AutoPay auto_pay = 29;
   */
  autoPay?: AutoPay$1;

  /**
   * @generated from field: bool allow_card_disbursement = 30;
   */
  allowCardDisbursement = false;

  /**
   * @generated from field: bool withdrew_savings_voucher = 31;
   */
  withdrewSavingsVoucher = false;

  /**
   * @generated from field: repeated web.public.Transaction transactions = 32;
   */
  transactions: Transaction[] = [];

  /**
   * @generated from field: uint32 admin_fee_cents = 33;
   */
  adminFeeCents = 0;

  /**
   * @generated from field: uint32 monthly_principal_cents = 34;
   */
  monthlyPrincipalCents = 0;

  /**
   * @generated from field: uint32 monthly_fee_cents = 35;
   */
  monthlyFeeCents = 0;

  /**
   * @generated from field: float total_apr = 36;
   */
  totalApr = 0;

  /**
   * @generated from field: float interest_percentage = 37;
   */
  interestPercentage = 0;

  /**
   * @generated from field: uint32 current_principal_cents = 38;
   */
  currentPrincipalCents = 0;

  /**
   * @generated from field: repeated web.public.UnsecuredLoanStatement unsecured_loan_statements = 39;
   */
  unsecuredLoanStatements: UnsecuredLoanStatement[] = [];

  constructor(data?: PartialMessage<Loan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Loan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "monthly_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "duration", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "frequency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "current_balance_cents", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
    { no: 7, name: "opened_at", kind: "message", T: Timestamp },
    { no: 8, name: "closed_at", kind: "message", T: Timestamp },
    { no: 9, name: "last_payment_at", kind: "message", T: Timestamp },
    { no: 10, name: "first_delinquency_at", kind: "message", T: Timestamp },
    { no: 11, name: "status", kind: "enum", T: proto3.getEnumType(Loan.Status) },
    { no: 12, name: "loan_installments", kind: "message", T: LoanInstallment, repeated: true },
    { no: 13, name: "loan_agreement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "reporting_hold_at", kind: "message", T: Timestamp },
    { no: 15, name: "experian_reporting", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "equifax_reporting", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "loan_plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "loan_type", kind: "enum", T: proto3.getEnumType(Loan.LoanType) },
    { no: 19, name: "allowed_payments", kind: "enum", T: proto3.getEnumType(PaymentType), repeated: true },
    { no: 20, name: "body_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "autopay_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "autopay_failed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "autopay_payment_method_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "payment_options", kind: "message", T: PaymentOption, repeated: true },
    { no: 25, name: "savings_voucher", kind: "message", T: SavingsVoucher },
    { no: 26, name: "allow_closure", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "allow_withdrawal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "auto_pay_legacy", kind: "message", T: AutoPayStatus },
    { no: 29, name: "auto_pay", kind: "message", T: AutoPay$1 },
    { no: 30, name: "allow_card_disbursement", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "withdrew_savings_voucher", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 33, name: "admin_fee_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 34, name: "monthly_principal_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 35, name: "monthly_fee_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 36, name: "total_apr", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 37, name: "interest_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 38, name: "current_principal_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 39, name: "unsecured_loan_statements", kind: "message", T: UnsecuredLoanStatement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Loan {
    return new Loan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Loan {
    return new Loan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Loan {
    return new Loan().fromJsonString(jsonString, options);
  }

  static equals(a: Loan | PlainMessage<Loan> | undefined, b: Loan | PlainMessage<Loan> | undefined): boolean {
    return proto3.util.equals(Loan, a, b);
  }

  static _enums: {
    Status: typeof Loan.Status,
    LoanType: typeof Loan.LoanType,
  };
}

export namespace Loan {
  /**
   * @generated from enum web.public.Loan.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FUNDED = 1;
     */
    FUNDED = 1,

    /**
     * @generated from enum value: PAID = 2;
     */
    PAID = 2,

    /**
     * @generated from enum value: CHARGED_OFF = 3;
     */
    CHARGED_OFF = 3,

    /**
     * @generated from enum value: TERMINATED = 4;
     */
    TERMINATED = 4,

    /**
     * @generated from enum value: FAILED_DISBURSEMENT = 5;
     */
    FAILED_DISBURSEMENT = 5,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Loan.Status)
  proto3.util.setEnumType(Loan.Status, "web.public.Loan.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FUNDED" },
    { no: 2, name: "PAID" },
    { no: 3, name: "CHARGED_OFF" },
    { no: 4, name: "TERMINATED" },
    { no: 5, name: "FAILED_DISBURSEMENT" },
  ]);
}

export namespace Loan {
  /**
   * @generated from enum web.public.Loan.LoanType
   */
  export enum LoanType {
    /**
     * Enum names must be unique in their scope, so this cannot be UNKNOWN
     *
     * @generated from enum value: UNKNOWN_TYPE = 0;
     */
    UNKNOWN_TYPE = 0,

    /**
     * deprecated
     *
     * @generated from enum value: BOTTLE = 1;
     */
    BOTTLE = 1,

    /**
     * deprecated
     *
     * @generated from enum value: MICRO = 2;
     */
    MICRO = 2,

    /**
     * @generated from enum value: CREDIT_BUILDER_SAVING = 3;
     */
    CREDIT_BUILDER_SAVING = 3,

    /**
     * @generated from enum value: RECURRING_SUBSCRIPTION_BUILDER = 4;
     */
    RECURRING_SUBSCRIPTION_BUILDER = 4,

    /**
     * @generated from enum value: PREMIUM = 5;
     */
    PREMIUM = 5,

    /**
     * @generated from enum value: UNSECURED_PERSONAL = 6;
     */
    UNSECURED_PERSONAL = 6,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Loan.LoanType)
  proto3.util.setEnumType(Loan.LoanType, "web.public.Loan.LoanType", [
    { no: 0, name: "UNKNOWN_TYPE" },
    { no: 1, name: "BOTTLE" },
    { no: 2, name: "MICRO" },
    { no: 3, name: "CREDIT_BUILDER_SAVING" },
    { no: 4, name: "RECURRING_SUBSCRIPTION_BUILDER" },
    { no: 5, name: "PREMIUM" },
    { no: 6, name: "UNSECURED_PERSONAL" },
  ]);
}

/**
 * @generated from message web.public.LoanInstallment
 */
export class LoanInstallment extends Message<LoanInstallment> {
  /**
   * @generated from field: string loan_token = 1;
   */
  loanToken = "";

  /**
   * @generated from field: google.protobuf.Timestamp billing_end = 2;
   */
  billingEnd?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp billing_start = 3;
   */
  billingStart?: Timestamp;

  /**
   * @generated from field: uint32 payment_amount_cents = 4;
   */
  paymentAmountCents = 0;

  /**
   * @generated from field: uint32 paid_amount_cents = 5;
   */
  paidAmountCents = 0;

  /**
   * @generated from field: bool paid = 6;
   */
  paid = false;

  /**
   * @generated from field: uint32 days_late = 7;
   */
  daysLate = 0;

  /**
   * @generated from field: google.protobuf.Timestamp paid_at = 8;
   */
  paidAt?: Timestamp;

  /**
   * @generated from field: web.public.PaymentMethod payment_method = 9;
   */
  paymentMethod?: PaymentMethod;

  /**
   * @generated from field: string token = 10;
   */
  token = "";

  /**
   * @generated from field: web.public.LoanInstallment.Status status = 11;
   */
  status = LoanInstallment.Status.UNKNOWN;

  /**
   * @generated from field: string failure_reason = 12;
   */
  failureReason = "";

  /**
   * @generated from field: uint32 payment_principal_amount_cents = 13;
   */
  paymentPrincipalAmountCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp unsecured_autopay_date = 14;
   */
  unsecuredAutopayDate?: Timestamp;

  constructor(data?: PartialMessage<LoanInstallment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.LoanInstallment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "billing_end", kind: "message", T: Timestamp },
    { no: 3, name: "billing_start", kind: "message", T: Timestamp },
    { no: 4, name: "payment_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "paid_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "days_late", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "paid_at", kind: "message", T: Timestamp },
    { no: 9, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 10, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "status", kind: "enum", T: proto3.getEnumType(LoanInstallment.Status) },
    { no: 12, name: "failure_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "payment_principal_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 14, name: "unsecured_autopay_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanInstallment {
    return new LoanInstallment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanInstallment {
    return new LoanInstallment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanInstallment {
    return new LoanInstallment().fromJsonString(jsonString, options);
  }

  static equals(a: LoanInstallment | PlainMessage<LoanInstallment> | undefined, b: LoanInstallment | PlainMessage<LoanInstallment> | undefined): boolean {
    return proto3.util.equals(LoanInstallment, a, b);
  }

  static _enums: {
    Status: typeof LoanInstallment.Status,
  };
}

export namespace LoanInstallment {
  /**
   * @generated from enum web.public.LoanInstallment.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: PENDING = 1;
     */
    PENDING = 1,

    /**
     * @generated from enum value: SUCCESSFUL = 2;
     */
    SUCCESSFUL = 2,

    /**
     * @generated from enum value: FAILED = 3;
     */
    FAILED = 3,

    /**
     * @generated from enum value: DISPUTED = 4;
     */
    DISPUTED = 4,

    /**
     * @generated from enum value: RETURNED = 5;
     */
    RETURNED = 5,

    /**
     * @generated from enum value: COMPLETED = 6;
     */
    COMPLETED = 6,

    /**
     * @generated from enum value: FAILED_TO_INITIATE = 7;
     */
    FAILED_TO_INITIATE = 7,

    /**
     * @generated from enum value: REVERSED = 8;
     */
    REVERSED = 8,

    /**
     * @generated from enum value: PARTIALLY_REVERSED = 9;
     */
    PARTIALLY_REVERSED = 9,
  }
  // Retrieve enum metadata with: proto3.getEnumType(LoanInstallment.Status)
  proto3.util.setEnumType(LoanInstallment.Status, "web.public.LoanInstallment.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "PENDING" },
    { no: 2, name: "SUCCESSFUL" },
    { no: 3, name: "FAILED" },
    { no: 4, name: "DISPUTED" },
    { no: 5, name: "RETURNED" },
    { no: 6, name: "COMPLETED" },
    { no: 7, name: "FAILED_TO_INITIATE" },
    { no: 8, name: "REVERSED" },
    { no: 9, name: "PARTIALLY_REVERSED" },
  ]);
}

/**
 * @generated from message web.public.Transaction
 */
export class Transaction extends Message<Transaction> {
  /**
   * @generated from field: web.public.FundingType credit_type = 1;
   */
  creditType = FundingType.UNKNOWN;

  /**
   * @generated from field: string credit_token = 2;
   */
  creditToken = "";

  /**
   * @generated from field: web.public.FundingType debit_type = 3;
   */
  debitType = FundingType.UNKNOWN;

  /**
   * @generated from field: string debit_token = 4;
   */
  debitToken = "";

  /**
   * @generated from field: sint32 amount_cents = 5;
   */
  amountCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp transacted_at = 6;
   */
  transactedAt?: Timestamp;

  /**
   * @generated from field: string transaction_title = 7;
   */
  transactionTitle = "";

  /**
   * @generated from field: string transaction_description = 8;
   */
  transactionDescription = "";

  /**
   * @generated from field: bool refund = 9;
   */
  refund = false;

  constructor(data?: PartialMessage<Transaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Transaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credit_type", kind: "enum", T: proto3.getEnumType(FundingType) },
    { no: 2, name: "credit_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "debit_type", kind: "enum", T: proto3.getEnumType(FundingType) },
    { no: 4, name: "debit_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "amount_cents", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
    { no: 6, name: "transacted_at", kind: "message", T: Timestamp },
    { no: 7, name: "transaction_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "transaction_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "refund", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Transaction {
    return new Transaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJsonString(jsonString, options);
  }

  static equals(a: Transaction | PlainMessage<Transaction> | undefined, b: Transaction | PlainMessage<Transaction> | undefined): boolean {
    return proto3.util.equals(Transaction, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.FundWithdrawal
 */
export class FundWithdrawal extends Message<FundWithdrawal> {
  /**
   * @generated from field: web.public.FundWithdrawal.Status status = 1;
   */
  status = FundWithdrawal.Status.UNKNOWN;

  /**
   * @generated from field: uint32 amount_cents = 2;
   */
  amountCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp withdrawn_at = 3;
   */
  withdrawnAt?: Timestamp;

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  constructor(data?: PartialMessage<FundWithdrawal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.FundWithdrawal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(FundWithdrawal.Status) },
    { no: 2, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "withdrawn_at", kind: "message", T: Timestamp },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FundWithdrawal {
    return new FundWithdrawal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FundWithdrawal {
    return new FundWithdrawal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FundWithdrawal {
    return new FundWithdrawal().fromJsonString(jsonString, options);
  }

  static equals(a: FundWithdrawal | PlainMessage<FundWithdrawal> | undefined, b: FundWithdrawal | PlainMessage<FundWithdrawal> | undefined): boolean {
    return proto3.util.equals(FundWithdrawal, a, b);
  }

  static _enums: {
    Status: typeof FundWithdrawal.Status,
  };
}

export namespace FundWithdrawal {
  /**
   * @generated from enum web.public.FundWithdrawal.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: PENDING = 1;
     */
    PENDING = 1,

    /**
     * @generated from enum value: SUCCESSFUL = 2;
     */
    SUCCESSFUL = 2,

    /**
     * @generated from enum value: FAILED = 3;
     */
    FAILED = 3,

    /**
     * @generated from enum value: DISPUTED = 4;
     */
    DISPUTED = 4,

    /**
     * @generated from enum value: RETURNED = 5;
     */
    RETURNED = 5,

    /**
     * @generated from enum value: COMPLETED = 6;
     */
    COMPLETED = 6,

    /**
     * @generated from enum value: CANCELLED = 7;
     */
    CANCELLED = 7,
  }
  // Retrieve enum metadata with: proto3.getEnumType(FundWithdrawal.Status)
  proto3.util.setEnumType(FundWithdrawal.Status, "web.public.FundWithdrawal.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "PENDING" },
    { no: 2, name: "SUCCESSFUL" },
    { no: 3, name: "FAILED" },
    { no: 4, name: "DISPUTED" },
    { no: 5, name: "RETURNED" },
    { no: 6, name: "COMPLETED" },
    { no: 7, name: "CANCELLED" },
  ]);
}

/**
 * @generated from message web.public.SavingsVoucher
 */
export class SavingsVoucher extends Message<SavingsVoucher> {
  /**
   * @generated from field: uint32 value_cents = 1;
   */
  valueCents = 0;

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  /**
   * @generated from field: google.protobuf.Timestamp redeemed_at = 3;
   */
  redeemedAt?: Timestamp;

  /**
   * @generated from field: repeated web.public.FundWithdrawal fund_withdrawals = 4;
   */
  fundWithdrawals: FundWithdrawal[] = [];

  constructor(data?: PartialMessage<SavingsVoucher>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.SavingsVoucher";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "redeemed_at", kind: "message", T: Timestamp },
    { no: 4, name: "fund_withdrawals", kind: "message", T: FundWithdrawal, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SavingsVoucher {
    return new SavingsVoucher().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SavingsVoucher {
    return new SavingsVoucher().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SavingsVoucher {
    return new SavingsVoucher().fromJsonString(jsonString, options);
  }

  static equals(a: SavingsVoucher | PlainMessage<SavingsVoucher> | undefined, b: SavingsVoucher | PlainMessage<SavingsVoucher> | undefined): boolean {
    return proto3.util.equals(SavingsVoucher, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.UnsecuredLoanStatement
 */
export class UnsecuredLoanStatement extends Message<UnsecuredLoanStatement> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: google.protobuf.Timestamp installment_due_at = 2;
   */
  installmentDueAt?: Timestamp;

  /**
   * @generated from field: bool installment_paid = 3;
   */
  installmentPaid = false;

  /**
   * @generated from field: google.protobuf.Timestamp installment_paid_at = 4;
   */
  installmentPaidAt?: Timestamp;

  /**
   * @generated from field: uint32 installment_paid_cents = 5;
   */
  installmentPaidCents = 0;

  /**
   * @generated from field: uint32 installment_payment_amount_cents = 6;
   */
  installmentPaymentAmountCents = 0;

  /**
   * @generated from field: uint32 installment_principal_payment_cents = 7;
   */
  installmentPrincipalPaymentCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp installment_start_at = 8;
   */
  installmentStartAt?: Timestamp;

  /**
   * @generated from field: uint32 loan_amount_past_due_cents = 9;
   */
  loanAmountPastDueCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp loan_maturity_date = 10;
   */
  loanMaturityDate?: Timestamp;

  /**
   * @generated from field: uint32 loan_principal_remaining_cents = 11;
   */
  loanPrincipalRemainingCents = 0;

  /**
   * @generated from field: uint32 loan_total_principal_cents = 12;
   */
  loanTotalPrincipalCents = 0;

  /**
   * @generated from field: float apr = 13;
   */
  apr = 0;

  constructor(data?: PartialMessage<UnsecuredLoanStatement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UnsecuredLoanStatement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "installment_due_at", kind: "message", T: Timestamp },
    { no: 3, name: "installment_paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "installment_paid_at", kind: "message", T: Timestamp },
    { no: 5, name: "installment_paid_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "installment_payment_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "installment_principal_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "installment_start_at", kind: "message", T: Timestamp },
    { no: 9, name: "loan_amount_past_due_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 10, name: "loan_maturity_date", kind: "message", T: Timestamp },
    { no: 11, name: "loan_principal_remaining_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 12, name: "loan_total_principal_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 13, name: "apr", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnsecuredLoanStatement {
    return new UnsecuredLoanStatement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnsecuredLoanStatement {
    return new UnsecuredLoanStatement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnsecuredLoanStatement {
    return new UnsecuredLoanStatement().fromJsonString(jsonString, options);
  }

  static equals(a: UnsecuredLoanStatement | PlainMessage<UnsecuredLoanStatement> | undefined, b: UnsecuredLoanStatement | PlainMessage<UnsecuredLoanStatement> | undefined): boolean {
    return proto3.util.equals(UnsecuredLoanStatement, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetEligibleLoansResponse
 */
export class GetEligibleLoansResponse extends Message<GetEligibleLoansResponse> {
  /**
   * @generated from field: repeated web.public.LoanOffer loan_offers = 1;
   */
  loanOffers: LoanOffer[] = [];

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<GetEligibleLoansResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetEligibleLoansResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loan_offers", kind: "message", T: LoanOffer, repeated: true },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEligibleLoansResponse {
    return new GetEligibleLoansResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEligibleLoansResponse {
    return new GetEligibleLoansResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEligibleLoansResponse {
    return new GetEligibleLoansResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEligibleLoansResponse | PlainMessage<GetEligibleLoansResponse> | undefined, b: GetEligibleLoansResponse | PlainMessage<GetEligibleLoansResponse> | undefined): boolean {
    return proto3.util.equals(GetEligibleLoansResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AcceptLoanRequest
 */
export class AcceptLoanRequest extends Message<AcceptLoanRequest> {
  /**
   * @generated from field: string identifier = 1;
   */
  identifier = "";

  /**
   * @generated from field: uint32 program_id = 2;
   */
  programId = 0;

  /**
   * @generated from field: uint32 requested_monthly_payment_cents = 3;
   */
  requestedMonthlyPaymentCents = 0;

  constructor(data?: PartialMessage<AcceptLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AcceptLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "program_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "requested_monthly_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptLoanRequest {
    return new AcceptLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptLoanRequest {
    return new AcceptLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptLoanRequest {
    return new AcceptLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptLoanRequest | PlainMessage<AcceptLoanRequest> | undefined, b: AcceptLoanRequest | PlainMessage<AcceptLoanRequest> | undefined): boolean {
    return proto3.util.equals(AcceptLoanRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AcceptLoanResponse
 */
export class AcceptLoanResponse extends Message<AcceptLoanResponse> {
  /**
   * @generated from field: web.public.AcceptLoanResponse.Status status = 1;
   */
  status = AcceptLoanResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.Loan loan = 3;
   */
  loan?: Loan;

  constructor(data?: PartialMessage<AcceptLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AcceptLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(AcceptLoanResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "loan", kind: "message", T: Loan },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptLoanResponse {
    return new AcceptLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptLoanResponse {
    return new AcceptLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptLoanResponse {
    return new AcceptLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptLoanResponse | PlainMessage<AcceptLoanResponse> | undefined, b: AcceptLoanResponse | PlainMessage<AcceptLoanResponse> | undefined): boolean {
    return proto3.util.equals(AcceptLoanResponse, a, b);
  }

  static _enums: {
    Status: typeof AcceptLoanResponse.Status,
  };
}

export namespace AcceptLoanResponse {
  /**
   * @generated from enum web.public.AcceptLoanResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(AcceptLoanResponse.Status)
  proto3.util.setEnumType(AcceptLoanResponse.Status, "web.public.AcceptLoanResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.PayInstallmentViaWalletRequest
 */
export class PayInstallmentViaWalletRequest extends Message<PayInstallmentViaWalletRequest> {
  /**
   * @generated from field: uint32 payment_amount_cents = 1;
   */
  paymentAmountCents = 0;

  /**
   * @generated from field: string loan_token = 2;
   */
  loanToken = "";

  constructor(data?: PartialMessage<PayInstallmentViaWalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PayInstallmentViaWalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PayInstallmentViaWalletRequest {
    return new PayInstallmentViaWalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PayInstallmentViaWalletRequest {
    return new PayInstallmentViaWalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PayInstallmentViaWalletRequest {
    return new PayInstallmentViaWalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PayInstallmentViaWalletRequest | PlainMessage<PayInstallmentViaWalletRequest> | undefined, b: PayInstallmentViaWalletRequest | PlainMessage<PayInstallmentViaWalletRequest> | undefined): boolean {
    return proto3.util.equals(PayInstallmentViaWalletRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PayInstallmentViaWalletResponse
 */
export class PayInstallmentViaWalletResponse extends Message<PayInstallmentViaWalletResponse> {
  /**
   * @generated from field: web.public.PayInstallmentViaWalletResponse.Status status = 1;
   */
  status = PayInstallmentViaWalletResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Loan loan = 2;
   */
  loan?: Loan;

  /**
   * @generated from field: web.public.Transaction payment = 3;
   */
  payment?: Transaction;

  /**
   * @generated from field: web.public.Error error = 4;
   */
  error?: Error;

  constructor(data?: PartialMessage<PayInstallmentViaWalletResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PayInstallmentViaWalletResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(PayInstallmentViaWalletResponse.Status) },
    { no: 2, name: "loan", kind: "message", T: Loan },
    { no: 3, name: "payment", kind: "message", T: Transaction },
    { no: 4, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PayInstallmentViaWalletResponse {
    return new PayInstallmentViaWalletResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PayInstallmentViaWalletResponse {
    return new PayInstallmentViaWalletResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PayInstallmentViaWalletResponse {
    return new PayInstallmentViaWalletResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PayInstallmentViaWalletResponse | PlainMessage<PayInstallmentViaWalletResponse> | undefined, b: PayInstallmentViaWalletResponse | PlainMessage<PayInstallmentViaWalletResponse> | undefined): boolean {
    return proto3.util.equals(PayInstallmentViaWalletResponse, a, b);
  }

  static _enums: {
    Status: typeof PayInstallmentViaWalletResponse.Status,
  };
}

export namespace PayInstallmentViaWalletResponse {
  /**
   * @generated from enum web.public.PayInstallmentViaWalletResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PayInstallmentViaWalletResponse.Status)
  proto3.util.setEnumType(PayInstallmentViaWalletResponse.Status, "web.public.PayInstallmentViaWalletResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.MakeAchPaymentRequest
 */
export class MakeAchPaymentRequest extends Message<MakeAchPaymentRequest> {
  /**
   * @generated from field: string installment_token = 1;
   */
  installmentToken = "";

  /**
   * @generated from field: string loan_token = 2;
   */
  loanToken = "";

  /**
   * @generated from field: string external_bank_account_token = 3;
   */
  externalBankAccountToken = "";

  constructor(data?: PartialMessage<MakeAchPaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.MakeAchPaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installment_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MakeAchPaymentRequest {
    return new MakeAchPaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MakeAchPaymentRequest {
    return new MakeAchPaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MakeAchPaymentRequest {
    return new MakeAchPaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MakeAchPaymentRequest | PlainMessage<MakeAchPaymentRequest> | undefined, b: MakeAchPaymentRequest | PlainMessage<MakeAchPaymentRequest> | undefined): boolean {
    return proto3.util.equals(MakeAchPaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.MakeAchPaymentResponse
 */
export class MakeAchPaymentResponse extends Message<MakeAchPaymentResponse> {
  /**
   * @generated from field: web.public.MakeAchPaymentResponse.Status status = 1;
   */
  status = MakeAchPaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<MakeAchPaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.MakeAchPaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(MakeAchPaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MakeAchPaymentResponse {
    return new MakeAchPaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MakeAchPaymentResponse {
    return new MakeAchPaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MakeAchPaymentResponse {
    return new MakeAchPaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MakeAchPaymentResponse | PlainMessage<MakeAchPaymentResponse> | undefined, b: MakeAchPaymentResponse | PlainMessage<MakeAchPaymentResponse> | undefined): boolean {
    return proto3.util.equals(MakeAchPaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof MakeAchPaymentResponse.Status,
  };
}

export namespace MakeAchPaymentResponse {
  /**
   * @generated from enum web.public.MakeAchPaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(MakeAchPaymentResponse.Status)
  proto3.util.setEnumType(MakeAchPaymentResponse.Status, "web.public.MakeAchPaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.MakePaymentRequest
 */
export class MakePaymentRequest extends Message<MakePaymentRequest> {
  /**
   * @generated from field: string installment_token = 1;
   */
  installmentToken = "";

  /**
   * @generated from field: string loan_token = 2;
   */
  loanToken = "";

  /**
   * @generated from field: string payment_method_token = 3;
   */
  paymentMethodToken = "";

  constructor(data?: PartialMessage<MakePaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.MakePaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installment_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MakePaymentRequest {
    return new MakePaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MakePaymentRequest {
    return new MakePaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MakePaymentRequest {
    return new MakePaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MakePaymentRequest | PlainMessage<MakePaymentRequest> | undefined, b: MakePaymentRequest | PlainMessage<MakePaymentRequest> | undefined): boolean {
    return proto3.util.equals(MakePaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.MakePaymentResponse
 */
export class MakePaymentResponse extends Message<MakePaymentResponse> {
  /**
   * @generated from field: web.public.MakePaymentResponse.Status status = 1;
   */
  status = MakePaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<MakePaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.MakePaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(MakePaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MakePaymentResponse {
    return new MakePaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MakePaymentResponse {
    return new MakePaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MakePaymentResponse {
    return new MakePaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MakePaymentResponse | PlainMessage<MakePaymentResponse> | undefined, b: MakePaymentResponse | PlainMessage<MakePaymentResponse> | undefined): boolean {
    return proto3.util.equals(MakePaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof MakePaymentResponse.Status,
  };
}

export namespace MakePaymentResponse {
  /**
   * @generated from enum web.public.MakePaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(MakePaymentResponse.Status)
  proto3.util.setEnumType(MakePaymentResponse.Status, "web.public.MakePaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.InitiateInstallmentCardPaymentRequest
 */
export class InitiateInstallmentCardPaymentRequest extends Message<InitiateInstallmentCardPaymentRequest> {
  /**
   * @generated from field: string installment_token = 1;
   */
  installmentToken = "";

  /**
   * @generated from field: string loan_token = 2;
   */
  loanToken = "";

  constructor(data?: PartialMessage<InitiateInstallmentCardPaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InitiateInstallmentCardPaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installment_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateInstallmentCardPaymentRequest {
    return new InitiateInstallmentCardPaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateInstallmentCardPaymentRequest {
    return new InitiateInstallmentCardPaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateInstallmentCardPaymentRequest {
    return new InitiateInstallmentCardPaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateInstallmentCardPaymentRequest | PlainMessage<InitiateInstallmentCardPaymentRequest> | undefined, b: InitiateInstallmentCardPaymentRequest | PlainMessage<InitiateInstallmentCardPaymentRequest> | undefined): boolean {
    return proto3.util.equals(InitiateInstallmentCardPaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.InitiateInstallmentCardPaymentResponse
 */
export class InitiateInstallmentCardPaymentResponse extends Message<InitiateInstallmentCardPaymentResponse> {
  /**
   * @generated from field: web.public.InitiateInstallmentCardPaymentResponse.Status status = 1;
   */
  status = InitiateInstallmentCardPaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.CardPayment card_payment = 3;
   */
  cardPayment?: CardPayment;

  constructor(data?: PartialMessage<InitiateInstallmentCardPaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InitiateInstallmentCardPaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(InitiateInstallmentCardPaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "card_payment", kind: "message", T: CardPayment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateInstallmentCardPaymentResponse {
    return new InitiateInstallmentCardPaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateInstallmentCardPaymentResponse {
    return new InitiateInstallmentCardPaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateInstallmentCardPaymentResponse {
    return new InitiateInstallmentCardPaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateInstallmentCardPaymentResponse | PlainMessage<InitiateInstallmentCardPaymentResponse> | undefined, b: InitiateInstallmentCardPaymentResponse | PlainMessage<InitiateInstallmentCardPaymentResponse> | undefined): boolean {
    return proto3.util.equals(InitiateInstallmentCardPaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof InitiateInstallmentCardPaymentResponse.Status,
  };
}

export namespace InitiateInstallmentCardPaymentResponse {
  /**
   * @generated from enum web.public.InitiateInstallmentCardPaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(InitiateInstallmentCardPaymentResponse.Status)
  proto3.util.setEnumType(InitiateInstallmentCardPaymentResponse.Status, "web.public.InitiateInstallmentCardPaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.ConfirmInstallmentCardPaymentRequest
 */
export class ConfirmInstallmentCardPaymentRequest extends Message<ConfirmInstallmentCardPaymentRequest> {
  /**
   * @generated from field: string payment_intent_id = 1;
   */
  paymentIntentId = "";

  /**
   * @generated from field: string loan_token = 2;
   */
  loanToken = "";

  constructor(data?: PartialMessage<ConfirmInstallmentCardPaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ConfirmInstallmentCardPaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmInstallmentCardPaymentRequest {
    return new ConfirmInstallmentCardPaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmInstallmentCardPaymentRequest {
    return new ConfirmInstallmentCardPaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmInstallmentCardPaymentRequest {
    return new ConfirmInstallmentCardPaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmInstallmentCardPaymentRequest | PlainMessage<ConfirmInstallmentCardPaymentRequest> | undefined, b: ConfirmInstallmentCardPaymentRequest | PlainMessage<ConfirmInstallmentCardPaymentRequest> | undefined): boolean {
    return proto3.util.equals(ConfirmInstallmentCardPaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ConfirmInstallmentCardPaymentResponse
 */
export class ConfirmInstallmentCardPaymentResponse extends Message<ConfirmInstallmentCardPaymentResponse> {
  /**
   * @generated from field: web.public.ConfirmInstallmentCardPaymentResponse.Status status = 1;
   */
  status = ConfirmInstallmentCardPaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<ConfirmInstallmentCardPaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ConfirmInstallmentCardPaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ConfirmInstallmentCardPaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmInstallmentCardPaymentResponse {
    return new ConfirmInstallmentCardPaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmInstallmentCardPaymentResponse {
    return new ConfirmInstallmentCardPaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmInstallmentCardPaymentResponse {
    return new ConfirmInstallmentCardPaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmInstallmentCardPaymentResponse | PlainMessage<ConfirmInstallmentCardPaymentResponse> | undefined, b: ConfirmInstallmentCardPaymentResponse | PlainMessage<ConfirmInstallmentCardPaymentResponse> | undefined): boolean {
    return proto3.util.equals(ConfirmInstallmentCardPaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof ConfirmInstallmentCardPaymentResponse.Status,
  };
}

export namespace ConfirmInstallmentCardPaymentResponse {
  /**
   * @generated from enum web.public.ConfirmInstallmentCardPaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ConfirmInstallmentCardPaymentResponse.Status)
  proto3.util.setEnumType(ConfirmInstallmentCardPaymentResponse.Status, "web.public.ConfirmInstallmentCardPaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.UpdateLoanSettingsRequest
 */
export class UpdateLoanSettingsRequest extends Message<UpdateLoanSettingsRequest> {
  /**
   * @generated from field: string loan_token = 1;
   */
  loanToken = "";

  /**
   * @generated from field: web.public.UpdateLoanSettingsRequest.AutoPay auto_pay_legacy = 2 [deprecated = true];
   * @deprecated
   */
  autoPayLegacy?: UpdateLoanSettingsRequest.AutoPay;

  /**
   * @generated from field: web.public.AutoPay.Settings auto_pay = 3;
   */
  autoPay?: AutoPay$1.Settings;

  constructor(data?: PartialMessage<UpdateLoanSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateLoanSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "auto_pay_legacy", kind: "message", T: UpdateLoanSettingsRequest.AutoPay },
    { no: 3, name: "auto_pay", kind: "message", T: AutoPay$1.Settings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLoanSettingsRequest {
    return new UpdateLoanSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLoanSettingsRequest {
    return new UpdateLoanSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLoanSettingsRequest {
    return new UpdateLoanSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLoanSettingsRequest | PlainMessage<UpdateLoanSettingsRequest> | undefined, b: UpdateLoanSettingsRequest | PlainMessage<UpdateLoanSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateLoanSettingsRequest, a, b);
  }

  static _enums: {
  };
}

export namespace UpdateLoanSettingsRequest {
  /**
   * @generated from message web.public.UpdateLoanSettingsRequest.AutoPay
   */
  export class AutoPay extends Message<UpdateLoanSettingsRequest.AutoPay> {
    /**
     * @generated from field: bool enabled = 1;
     */
    enabled = false;

    /**
     * Deprecated
     *
     * @generated from field: string external_bank_account_token = 2;
     */
    externalBankAccountToken = "";

    /**
     * @generated from field: string payment_method_token = 3;
     */
    paymentMethodToken = "";

    constructor(data?: PartialMessage<UpdateLoanSettingsRequest.AutoPay>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.UpdateLoanSettingsRequest.AutoPay";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 2, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLoanSettingsRequest.AutoPay {
      return new UpdateLoanSettingsRequest.AutoPay().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLoanSettingsRequest.AutoPay {
      return new UpdateLoanSettingsRequest.AutoPay().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLoanSettingsRequest.AutoPay {
      return new UpdateLoanSettingsRequest.AutoPay().fromJsonString(jsonString, options);
    }

    static equals(a: UpdateLoanSettingsRequest.AutoPay | PlainMessage<UpdateLoanSettingsRequest.AutoPay> | undefined, b: UpdateLoanSettingsRequest.AutoPay | PlainMessage<UpdateLoanSettingsRequest.AutoPay> | undefined): boolean {
      return proto3.util.equals(UpdateLoanSettingsRequest.AutoPay, a, b);
    }

    static _enums: {
    };
  }
}

/**
 * @generated from message web.public.UpdateLoanSettingsResponse
 */
export class UpdateLoanSettingsResponse extends Message<UpdateLoanSettingsResponse> {
  /**
   * @generated from field: web.public.UpdateLoanSettingsResponse.Status status = 1;
   */
  status = UpdateLoanSettingsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateLoanSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateLoanSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateLoanSettingsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLoanSettingsResponse {
    return new UpdateLoanSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLoanSettingsResponse {
    return new UpdateLoanSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLoanSettingsResponse {
    return new UpdateLoanSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLoanSettingsResponse | PlainMessage<UpdateLoanSettingsResponse> | undefined, b: UpdateLoanSettingsResponse | PlainMessage<UpdateLoanSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateLoanSettingsResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateLoanSettingsResponse.Status,
  };
}

export namespace UpdateLoanSettingsResponse {
  /**
   * @generated from enum web.public.UpdateLoanSettingsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateLoanSettingsResponse.Status)
  proto3.util.setEnumType(UpdateLoanSettingsResponse.Status, "web.public.UpdateLoanSettingsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.CloseLoanRequest
 */
export class CloseLoanRequest extends Message<CloseLoanRequest> {
  /**
   * @generated from field: string closure_reason = 1;
   */
  closureReason = "";

  /**
   * @generated from field: string loan_token = 2;
   */
  loanToken = "";

  constructor(data?: PartialMessage<CloseLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CloseLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "closure_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloseLoanRequest {
    return new CloseLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloseLoanRequest {
    return new CloseLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloseLoanRequest {
    return new CloseLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CloseLoanRequest | PlainMessage<CloseLoanRequest> | undefined, b: CloseLoanRequest | PlainMessage<CloseLoanRequest> | undefined): boolean {
    return proto3.util.equals(CloseLoanRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CloseLoanResponse
 */
export class CloseLoanResponse extends Message<CloseLoanResponse> {
  /**
   * @generated from field: web.public.CloseLoanResponse.Status status = 1;
   */
  status = CloseLoanResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<CloseLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CloseLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CloseLoanResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloseLoanResponse {
    return new CloseLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloseLoanResponse {
    return new CloseLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloseLoanResponse {
    return new CloseLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CloseLoanResponse | PlainMessage<CloseLoanResponse> | undefined, b: CloseLoanResponse | PlainMessage<CloseLoanResponse> | undefined): boolean {
    return proto3.util.equals(CloseLoanResponse, a, b);
  }

  static _enums: {
    Status: typeof CloseLoanResponse.Status,
  };
}

export namespace CloseLoanResponse {
  /**
   * @generated from enum web.public.CloseLoanResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CloseLoanResponse.Status)
  proto3.util.setEnumType(CloseLoanResponse.Status, "web.public.CloseLoanResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

