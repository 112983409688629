import { ManualIdvAttempt as ManualIdvAttemptPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/pii/manual_idv_attempt_pb";
import Protobuf from "@repo/protobuf/utils";
import Address from "@repo/utils/Address";
import Attachment from "@repo/utils/Attachment";
import FullName from "@repo/utils/FullName";
import UString from "@repo/utils/UString";

import { FullNameCanada } from "./FullName.js";
import User from "./User.js";

type ManualIdvAttempt = {
  userId: User.Id;
  name: FullName;
  address: Address;
  birthDate: string;
  selfie: Attachment;
  photoId: Attachment;
  uploadedAt: Date;
  pending: boolean;
  id: ManualIdvAttempt.Id;
};

namespace ManualIdvAttempt {
  export type Id = string;
  export const mimeTypes = [`image/*`, "application/pdf"] as const;
  export type MimeType = UString.ResolveGlob<(typeof mimeTypes)[number]>;
}

export default ManualIdvAttempt;

export namespace ManualIdvAttemptCanada {
  export function normalize(
    manualIdvAttempt: ManualIdvAttemptPb,
  ): ManualIdvAttempt {
    return {
      ...manualIdvAttempt,
      name: FullNameCanada.normalize(manualIdvAttempt.fullName!),
      address: manualIdvAttempt.address!,
      selfie: {
        url: manualIdvAttempt.selfie!.url,
        mimeType: manualIdvAttempt.selfie!.mimeType as Attachment.MimeType,
      },
      photoId: {
        url: manualIdvAttempt.photoId!.url,
        mimeType: manualIdvAttempt.photoId!.mimeType as Attachment.MimeType,
      },
      uploadedAt: Protobuf.Timestamp.toDate(manualIdvAttempt.uploadedAt!),
      pending: manualIdvAttempt.pending,
      id: manualIdvAttempt.id,
    };
  }
}
